//main
import { useParams } from "react-router-dom";

import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import DocumentsNewInfoModule from "../content/documents/documents-new-info";

export default function SmartAddNewFile() {

  const { id } = useParams();
  
  return (
  <>
    <ContentFormatXX 
      head='Add Documents'
      link={`/${UserForm()}/smart/view/${id}`}
      form= 'small'
      data= {
        <>
          {/* <SmartsNewFileModule /> */}
          <DocumentsNewInfoModule />
        </>
      } 
    />
  </>
  )
}