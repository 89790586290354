//main
import { useParams } from 'react-router-dom';

import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import ContactsEditAccountsModule from '../content/contacts/contacts-edit-acnt';

export default function ContactsEditAccounts() {

  const { id } = useParams();
  
  return (
  <>
    <ContentFormatXX 
      head='Contact Accounts'
      link={`/${UserForm()}/contacts/view/${id}`}
      form= 'small'
      data= {
        <>
          <ContactsEditAccountsModule />
        </>
      } 
    />
  </>
  )
}