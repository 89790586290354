// search
import { useEffect, useState } from "react";

import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";

export default function SmartSearchModule(props) {
  
  const [search, setSearch] = useState({
    name: '',
    stts: (new Date()).toISOString().substring(0,10),
    ents: (new Date()).toISOString().substring(0,10),
  });

  const handleChange = async(key, val) => {
    setSearch({ ...search, [key]: val });
  }

  const handleCallback = (srcx) => {
    // console.log (srcx)
    props.search(srcx);
  }

  return (
  <>
    <div className="">

      <div className="form-group px-0 border-bottom mb-3">
        <label className="text-small text-color-tone m-0">Name</label>
        <input type="text" 
          className="form-control border-none px-0 bg-transparent"
          style={{fontSize:'0.9rem'}} 
          value={search.name}
          onChange={({ target }) => {
            handleChange("name", target.value); 
          }}
        ></input>
      </div>

      <div className="form-group px-0 border-bottom mb-3">
      <label className="text-small text-color-tone m-0">Date From</label>
        <input type="date" 
          className="form-control border-none px-0 bg-transparent"
          style={{fontSize:'0.9rem'}} 
          value={search.stts}
          onChange={({ target }) => {
            handleChange("stts", target.value); 
          }}
        ></input>
      </div>

      <div className="form-group px-0 border-bottom mb-3">
        <label className="text-small text-color-tone m-0">Date To</label>
        <input type="date" 
          className="form-control border-none px-0 bg-transparent"
          style={{fontSize:'0.9rem'}}  
          value={search.ents}
          onChange={({ target }) => {
            handleChange("ents", target.value); 
          }}
        ></input>
      </div>


      <WebbDividerSmall />

      <div className="mb-3">
        <div className="">
          <button 
            type="button"
            className="btn btn-sm btn-secondary back-color-tone border-none rounded-md px-3"
            onClick={()=>handleCallback(search)}
          >
          <small>Search</small></button>
        </div>
      </div>

    </div>

    <WebbDividerMedium/>
  </>
  )
}