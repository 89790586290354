//main
import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import ContactsNewModule from "../content/contacts/contacts-new";

export default function ContactsNew() {

  return (
  <>
    <ContentFormatXX 
      head='Add Contact'
      link={`/${UserForm()}/contacts`}
      form= 'small'
      data= {
        <>
          <ContactsNewModule />
        </>
      } 
    />
  </>
  )
}