// site logo
import {Link} from 'react-router-dom';

import { UserForm } from "../../services/srvc-utilities";

export default function WebbIcon() {

  return (
  <>
    <div className="">
      <Link to={`/${UserForm()}/home`}>
        <i 
          className='bx bxs-chevron-up-circle text-color-main' 
          style={{fontSize:'1.9rem'}}
        ></i>    
      </Link>
    </div>

  </>
  )
}