// documents
import { Link, useHistory, useParams } from 'react-router-dom';

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

const list = require('../../data/list-smrt-actions.json').data.filter(item=>item.actv)

export default function SmartActions(props) {
  
  const asset = UserForm() === 'in' ? GetLocalUser() : GetLocalBusiness();
  
  const {id} = useParams();
  const history = useHistory();
  
  const data = props.data.docx;
  const lstx = list.filter(item => item.user.includes(asset.form))
  // var idlist = props.data.idlist

  //  console.log(data)

  var admin = data.user.filter(item=>{
        return item.role=="admin"
  }) 

  // console.log(admin)

  const handleEsin = async() => {
    if (props.data.mmbr ===0) {
      alert('Please add Signing Parties');
    }
    if (props.data.file === 0) {
      alert('Please add Files');
      // history.push(`/${asset.form.substring(0,2)}/documents/esin/location/${id}`);
    }
    if (props.data.mmbr >0 && props.data.file >0) {
      history.push(`/${asset.form.substring(0,2)}/smart/esin/location/${id}`);
    }
  }
 
  const handleMembers = async() => {
    history.push(`/${asset.form.substring(0,2)}/smart/edit/mmbr/${id}`)
  }

  const handleEstm = async() => {
    history.push(`/${asset.form.substring(0,2)}/smart/add/stamp/${id}`)
  }
  
  const handleStampList= async() => {
    history.push(`/${asset.form.substring(0,2)}/smart/add/stamp/${id}`)
  }

  const handleFiles = async() => {
    history.push(`/${asset.form.substring(0,2)}/smart/add/file/${id}`)
  }

  const handleTime = async() => {
    history.push(`/${asset.form.substring(0,2)}/smart/v/track/${id}`)
  }

  const handleRoom = async() => {
    console.log ('discuss')
    history.push(`/${asset.form.substring(0,2)}/rooms/${id}`)
  }

  const handleFeatures = async() => {
    // history.push(`/${asset.form.substring(0,2)}/documents/edit/feat/${id}`)
  }

  const handleArchive = async() => {
    console.log ('archive')
  }
  
  const handleButton = async(code) => {

    switch(code) {
      case 'esin':
        handleEsin();
        break;
      case 'estm':
        handleEstm();
        break;
      case 'file':
        handleFiles();
        break;
      case 'members':
        handleMembers();
        break;
      case 'estmlist':
        handleStampList();
        break;
      case 'file':
        // handleFiles();
        break;        
      case 'time':
        handleTime();
        break;        
      case 'room':
        handleRoom();
        break;
      case 'feat':
        handleFeatures();
        break;        
      case 'arch':
        handleArchive();
        break;
      default:
        // code block
    }

  }


  return (
    <>
      <div className="back-color-wite rounded-wd">

        <div className={`row row-cols-5 g-0`} >
        {lstx && lstx.length>0 && lstx.map ((item, i) => 
          <div className="col d-grid "   key={i}  
            style={{pointerEvents: item.stat.includes(data.status) ? "all" : "none"}} >
            <div
              className={`${admin[0].usid == asset.user ? "":"d-none"} rounded-wd text-center m-2 py-2 hilite text-color-${item.stat.includes(data.status)?"main":"lite"}`}
              onClick={()=> handleButton(item.code)} 
              style={{cursor:item.stat.includes(data.status) ? 'pointer': ''}}
              >
                <i className={item.icon}
                  style={{fontSize:'2rem'}}></i>
                <p className="m-0 p-0 text-color-tone text-sm"><small>{item.name}</small></p>
            </div>
          </div>

        )}
        </div>
  
      </div>
    </>
    )
  }