// next

export default function NextDivider() {

  return (
  <>
    <div className="mb-3">
    <div className="border-top"></div>
    </div>

  </>
  )
}