// transfers list
import { numc } from '../../services/srvc-utilities';

const code = require('../../data/code-transfers.json').data;

export default function TransferListCard(props) {

  const data = props.data;

  return (
  <>
    <div className="d-flex hilite p-2 px-3 border rounded-none">

      <div className="me-auto">
        <p className="fw-bold text-color-main m-0 p-0 mb-1">
          {data.name}
        </p>
        <p className="small m-0 p-0 text-dark">
          {(new Date(data.crts)).toLocaleString()}
        </p>
        <p className="small m-0 p-0 text-dark">
          {data.resn}{', '}{data.cmnt}
        </p>
        <p className={`small m-0 p-0 text-dark d-none ${data.stat==0 ? 'd-none' :''}`}>
          {'UTRN'}{': '}{data.utrn|| '***'}
        </p>
      </div>

      <div className="text-end">
        <p className="m-0 p-0">
          <span  className="text-dark">{data.crdb==="C" ? '+' : '-'}</span>
          <span>{' '}</span>
          <span  className="fw-bold text-color-main">{numc(data.amnt)}</span>
        </p>
        <p className="m-0 p-0 text-white">{'-'}</p>
        <p className="m-0 p-0 text-dark small">
          <span className="text-uppercase">
            <small>{code.find(item=>parseInt(item.code) === data.stat).text}</small>
          </span>{' '}
          <span className="small" >
            <i className={`bi-circle-fill small text-${code.find(item=>parseInt(item.code) === data.stat).colr}`}></i>
          </span>
        </p>
      </div>

    </div>

    <div className="my-3"></div>
  </>
  )
}