// contact detail view
import { Link, useParams } from 'react-router-dom';

import ListNoData from '../webb/list-nodata';

import { UserForm } from "../../services/srvc-utilities";


export default function ContactAccounts(props) {

  const data = props.data;
  const { id } = useParams();
  console.log (data )
  if (data.length===0) 
  return (
  <> 
    <p className="text-tone m-0">No Accounts Linked.</p>
    <span>Please </span>
    <Link to={`/${UserForm()}/contacts/accounts/${id}`}>Add Account</Link>
    <span> to enable fund transfers to this contact.</span>
  </>
  )

  const RemoveAccount = async() => {

  }


  return (
  <>
    {data && data.length >0 && data.map ((item, i) => (
    <div className="border bg-white rounded p-3 mb-3" key={i}>
      
      <div className="d-flex" >

        <div className="">
          <p className="m-0 p-0 fw-bold text-sm">{item.nmbr}</p>
          <p className="m-0 p-0 small">{item.name}</p>
        </div>
        
        <div className="ms-auto text-end py-1 d-none">
          {/* remove button */}
          <button className="btn btn-sm btn-light text-danger rounded-wd"
            onClick= {() => RemoveAccount()}
          ><small>Remove</small>
          </button>
        </div>
        
      </div>
      
      
    </div>
    ))}
  </>
  )
}