//date-time
export default function WebbModuleInfo(props) {
 
  const data = props.data;

  return (
  <>
    <p className="text-tone">{data.info || ''}</p>
  </>
  )
}