import React from 'react'
import {auth, provider } from '../../services/firebase'
import { signInWithPopup } from 'firebase/auth'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { SetAuthUser } from '../../services/srvc-auth-user'
import googlelogo from '../../media/google-icon.png'

const GoogleAuth = () => {

    const [mail,setMail] = useState()

    const history=useHistory()

    const handleClick = async()=>{
        signInWithPopup(auth, provider).then((data)=>{
            console.log(data)
            setMail(data.user.email)
            // window.localStorage.setItem('authmail', data.user.email  );
            SetAuthUser({user: data.user.email})
            history.push('/auth/next')
        })
        
    }



  return (
    <div>
        <div className={`d-flex p-3 py-0 back-color-wite rounded-wd hitone mt-2`}
              style={{cursor:'pointer'}}
             onClick={()=>handleClick()}
            >
              <div className="py-2 mt-2">
                <img style={{width:'24px', height:'24px'}} src={googlelogo} />
               
              </div>
              <div className="ps-2 py-2 mt-1">
                <p className="m-0 mt-1">Login with Google</p>
              </div>
              
              <div className="ms-auto text-end py-2 mt-1">
                <i className="bx bx-chevron-right text-color-tone m-0 text-icon-sm" ></i>
              </div>
            </div>

    </div>
  )
}

export default GoogleAuth