//  estm
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbSpinText from "../webb/webb-spintext";
import ListNoData from "../webb/list-nodata";
import NavsButtonBack from "../webb/navs-button-back";
import NavsButtonNext from "../webb/navs-button-next";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import {  StampList } from "../../services/srvc-estm-realm";


export default function StampListModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();

  const [loading,setLoading]=useState(true);
  const history = useHistory();

  const [data,setData]=useState([]);

  const [index, setIndex] = useState(1)
  const [items, setItems] = useState(10)

  const [curr, setCurrentIndex] = useState(1)
  const [next, setNextIndex] = useState()
  const [last, setLastIndex] = useState()

  const [count, setCount] = useState()
  const [total, setTotal] = useState()

  const [text, setText] = useState('')

  useEffect(()=>{
    // setLoading(true)

    const fetchdata =async()=>{
      
      var result = (await StampList(JSON.stringify({
        data:{user:asset.user, pageNo: index, perPage: items}
      })))

      // console.log(result)
      setData(result.data.list)

      setCount(result.data.count)
      setTotal(Math.ceil(result.data.count/ items))
      setText(`${(index-1)*items+1} - ${index*items < result.data.count ? index*items : result.data.count} of ${result.data.count}`)

      setLoading(false)
    }
    fetchdata()
  },[asset.user, index, items])


  const NextIndex = async() =>{
    if(total == index) {}
    else {
      setNextIndex(curr+1)
      setIndex(curr+1)
      setCurrentIndex(curr+1)
    }
  }

  const LastIndex = async()=>{
    if(index == 1) {}
    else{
      setLastIndex(curr-1)
      setIndex(curr-1)
      setCurrentIndex(curr-1)
    }
  }

  
  const handleClick = (item) => {
    history.push(`/${asset.form.substring(0,2)}/stamp/view/${item}`)
  }

  // if (loading){ return ( <> <WebbSpinText /> </> ) }
  if (data.length===0 || data==false) {return (<> <ListNoData /> </>)}

  return (
  <>
    {/* data */}
    <div className="back-color-wite border rounded-wd shadow-sm">
    {data && data.length > 0 && data.map((item, i) => (

      <div className={i < data.length-1 ? 'border-bottom' : ''} key={i}>
        <div className="p-3 hilite" onClick={() => handleClick(item.enid)}>
          <div className="d-flex cursor">

            <div className="">
              <p className="text-normal text-bold m-0 text-primary">
                <span className={item.actv ? item.stat == 6 ? 'text-color-success' : 'text-color-warning' : 'text-color-tint'}><i className="bx bxs-circle text-small"></i></span>
                <span className="ms-1">{item.nmbr === "" ? "****** In Process" : item.nmbr}</span>
              </p>
              <p className="m-0 text-sm">{item.memo || '******'}</p>
              <p className="m-0 text-sm">State: {item.lost}</p>
            </div>

            <div className="ms-auto text-end">
              <p className="text-normal text-bold m-0 text-primary">{item.amts}</p>
              <p className="m-0 text-sm"><br></br></p>
              <p className="m-0 text-sm">Status: {item.actv ? item.stat == 6 ? 'Ready' : 'Pending' : '***'}</p>
            </div>

          </div>

        </div>
      </div>

    ))}
    </div>

    {/* navs */}
    <WebbDividerSmall />
    <div className="">
      <div className="d-flex justify-content-between">

        <div className="" onClick={()=>LastIndex()}>
          <NavsButtonBack />
        </div>

        <div className="">
          <p className="my-2 text-color-tone">{text}</p>
        </div>

        <div className="" onClick={()=>NextIndex()}>
          <NavsButtonNext />
        </div>

      </div>
    </div>

  </>
  )
}