// user
import { useEffect, useState } from "react";

import WebbDivider from "../webb/webb-divider";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import WebbLoader from "../webb/webb-loader";
import WebbSpinText from "../webb/webb-spintext";
import HeaderSectionDark from "../webb/head-section-dark";

import UserDocumentsCard from "../webb/usrx-documents-card";

import { toBase64, UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";
import { GetUserKYCDocuments, UserUploadKYCDocuments } from "../../services/srvc-users-realm";

// import { GetUserDocs } from "../../services/srvc-user-realm";

export default function UserDocs () {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  
  const [loading, setLoading]=useState(true);
  const [info, setInfo] = useState([])
  const [type,setType] = useState('')
  const [file,setFile] = useState()
  const [refresh, setRefresh] = useState(false)

  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        setLoading(true);
        var datx ={
            data: {user: asset.user}, 
            user: asset.user,
            webb: process.env.REACT_APP_WEBB_SITE_NMBR
          }
        var docx = (await GetUserKYCDocuments(datx)).data
        setInfo(docx)
        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset.user, refresh]);

  const SubmitDocuments = async()=>{
    setLoading(true)
    var str1=await toBase64(file);
    var datx ={
      data: {user: asset.user, base64:str1, type:type, mail:asset.mail }, 
      user: asset.user,
      webb: process.env.REACT_APP_WEBB_SITE_NMBR
    }
    console.log(datx)
    var result = await UserUploadKYCDocuments(datx)
    console.log(result)
    if(result.data!= false)
    {
      setRefresh(!refresh)
      setType('')
    }

    setLoading(false)


  }

  if (loading){ return ( <> <WebbSpinText /> </> ) }


  return (
    <>
      <HeaderSectionDark data={{name: 'KYC Documents'}}/>
      
      {/* <WebbDividerMedium /> */}
      <div  className="">
        <div>
          <h6 className="">Upload Document</h6>
          <div className="">
            <div className="">
              <select onChange={(e)=>setType(e.target.value)} className="form-select rounded-none">
              <option value={''} className="">Select</option>
              <option value={'panc'} className="">PAN Card</option>
              <option value={'adhr'} className="">Aadhaar Card</option>
              </select>
            </div>
            <WebbDividerSmall />
            <div className={type!=''?'':'d-none'}>
              <input className="form-control-sm form-control rounded-none" type={'file'} onChange={(e)=>setFile(e.target.files[0])} />
              <WebbDividerSmall />
              <div className="">
                <div className="d-grid">
                  <button className={`btn height-md btn-primary back-color-main rounded-none`}
                    onClick={()=>SubmitDocuments()}
                    disabled={!file}
                  ><small>Save & Continue</small>
                  </button>
                </div>
              </div>

            </div>

          </div> 
        </div>
      </div>

      <hr />
      <div className="d-flex">
        <p className="me-auto">Total Documents Uploaded</p>
        <p className="text-end">{info && info.docs.length > 0? info.docs.length :'0'}</p>
      </div>
      <div className="border">
        {info && info.docs.length > 0 && info.docs.map((item, i) => ( 
          <UserDocumentsCard data={item} key={i}/>
        ))}
      </div>

      <WebbDividerMedium />
      <WebbDividerMedium />
    </>
  )
}