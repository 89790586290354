//init
import CryptoJS from "crypto-js";

export const AuthClearStore = async() => {
  // logout current user
  // reset local storage
  localStorage.clear();
  localStorage.setItem("auth",null);
  localStorage.setItem("user",null);
  localStorage.setItem("bsnx",null);
  localStorage.setItem("usne",null);
  localStorage.setItem("bsne",null);
  //console.log('user', app.currentUser)
  //redirect user to homepage
  //window.location.href='/';
}


const secretPass = "XkhZG4fW2t2W";

const EncryptData = (data)=>{
  var encrypt=  CryptoJS.AES.encrypt(JSON.stringify(data), secretPass,{ keySize: 128 / 8, iv: secretPass,mode: CryptoJS.mode.CBC,padding: CryptoJS.pad.Pkcs7}).toString();
  return encrypt
}

const DecryptData = (data)=>{
  var decryptData= JSON.parse(CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(data,  secretPass, { keySize: 128 / 8,iv: secretPass,mode: CryptoJS.mode.CBC,padding: CryptoJS.pad.Pkcs7 })));
  return decryptData
}




export const SetAuthUser=(asset)=>{
  var encrypt = EncryptData(asset)
  localStorage.setItem('auth', encrypt);
}

export const GetAuthUser=()=>{
  const decryptData = DecryptData(localStorage.getItem("auth"))
  return decryptData
}

export const SetLocalUser=(asset)=>{
  var encrypt = EncryptData(asset)
  localStorage.setItem('usrx', encrypt);
}
  
export const GetLocalUser=()=>{
  const decryptData = DecryptData(localStorage.getItem("usrx"))
  return decryptData
}

export const SetLocalBusiness=(asset)=>{
  var encrypt = EncryptData(asset)
  localStorage.setItem('bsnx', encrypt);
}

export const GetLocalBusiness=()=>{
  const decryptData = DecryptData(localStorage.getItem("bsnx"))
  return decryptData
}


export const SetNewUser=(asset)=>{
  localStorage.setItem('usne', JSON.stringify(asset));
}

export const GetNewUser=()=>{
  return JSON.parse(localStorage.getItem("usne"));
}

export const SetNewBusiness=(asset)=>{
  localStorage.setItem('bzne', JSON.stringify(asset));
}

export const GetNewBusiness=()=>{
  return JSON.parse(localStorage.getItem("bzne"));
}