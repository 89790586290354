//main
import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import ContactsNewIndividualModule from "../content/contacts/contacts-new-indx";

export default function ContactsNewIndividual() {

  return (
  <>
    <ContentFormatXX 
      head='New Contact - Individual'
      link={`/${UserForm()}/contacts`}
      form= 'small'
      data= {
        <>
        <ContactsNewIndividualModule/>
        
        </>
      } 
    />
  </>
  )
}