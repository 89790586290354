import { useHistory } from "react-router-dom";

import ContentFormatXZ from "../content/webc/content-format-xz";
import WebbDividerMedium from "../content/webb/webb-divider-md";
import WebbDividerSmall from "../content/webb/webb-divider-sm";

import WebbIcon from "../content/webb/webb-icon";
import GoogleAuth from "../content/auth/auth-google-firebase";


export default function Main () {
  
  const history = useHistory();

  return(
    <>
      <ContentFormatXZ 
        head='Main'
        link={``}
        form= 'mini'
        data= {
          <>
            <WebbDividerMedium />
            <div className="back-color-wite text-center rounded-wd">
              <WebbDividerSmall />
              <WebbDividerMedium />
                <i 
                  className='bx bxs-chevron-up-circle text-color-main' 
                  style={{fontSize:'3.3rem'}}
                ></i> 
              <WebbDividerMedium />

              <h1 className="text-bold text-color-main text-uppercase" style={{fontSize:'1.9rem'}}>
                {process.env.REACT_APP_WEBB_SITE_NAME}
              </h1>
              <p className="text-lead text-color-tone text-uppercase">
                {process.env.REACT_APP_WEBB_SITE_LINE}
              </p>
              <div className="" style={{height:'15vh'}}></div>
              
              
            </div>

            <WebbDividerMedium />
            <p className="text-color-tone text-center">Access Your Account</p>

            {/* login with google account */}
            <GoogleAuth />

            <WebbDividerSmall />

            <div className={`d-flex p-3 py-0 back-color-wite rounded-wd hitone`}
              style={{cursor:'pointer'}}
              onClick={() => history.push('/auth/mail')}
            >
              <div className="py-2 mt-1">
                <i className="bx bx-envelope text-color-main m-0 text-icon-sm"></i>
              </div>
              <div className="ps-2 py-2 mt-1">
                <p className="m-0 mt-1">Login with Email</p>
              </div>
              
              <div className="ms-auto text-end py-2 mt-1">
                <i className="bx bx-chevron-right text-color-tone m-0 text-icon-sm" ></i>
              </div>
            </div>

         
          
         

          </>
        } 
      />
    </>
  )
}