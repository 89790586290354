// new docs
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";

import { toBase64, UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { GetUserContactsList } from "../../services/srvc-contacts-realm";
// import { CreateNewInvoices } from "../../services/srvc-invoices-realm";
import FileSaveFirebase from "../../services/srvc-filesave-fbsx";

export default function OrdersNewModule() {

  // const asset = GetAuthUser();
  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState(false);
  const [text, setText] = useState('');

  const [file, setFile]=useState()
  const [link, setLink]=useState()
  const [list, setList] = useState([]);

  const [data, setData] = useState ({
    cred: '',
    debt: '',
    nmbr: '24'+(new Date()).getTime().toString(),
    amnt: '',
    memo: '',
    file: '',
    link: '',
    stts: (new Date()).toISOString().substring(0,10),
    ents: (new Date()).toISOString().substring(0,10),
  })

  useEffect( () => {
    if (asset){
      const fetchData = async() => {

        // contact list
        const conx = (await GetUserContactsList({ 
          data: { sort: 'allx', actv: 'actv', usid: asset.usid },
          user: asset.usid
        })).data
        console.log (conx)
        setList (conx)
        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset.usid]);

  // form validation
  useEffect( () => {
    setForm(false);
    if (data.amnt !=='' && !isNaN(data.amnt) && parseFloat(data.amnt) !==0)
      setForm(true)

  });


  const SetFileLink = async(link) => {
    // console.log (link);
    setLink(link)
    // handleChange("link", link);
  }

  const SetFileData = async(data) => {
    var str1=await toBase64(data);
    var str2=str1.split('base64')
    var b64=str2[1].split(",")
    //  console.log(b64[1])
    setFile(b64[1])
    //handleChange("file", base64);
  }

  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    if (form) {

      var datx = {
        cred: asset.usid,
        debt: data.cust,
        nmbr: data.nmbr,
        amnt: parseFloat(data.amnt),
        memo: data.memo,
        scid: '',
        file: file || '',
        link: link || 'https://google.com',
        stts: data.stts+'T00:00:00.000Z',
        ents: data.ents+'T23:59:59.999Z'
      }
      console.log(datx);

      // const result = await CreateNewInvoices ({
      //   data: datx, 
      //   user: asset.usid
      // })
      // console.log (result)
      setLoading(false);
      // if (res.data) history.push(`/${asset.form}/invoices`)
    }
    else {
      setText('Please enter PO information');
      setLoading(false);
    }
    
  }

  if (loading) return (<> <WebbLoader/> </>)

  return (
  <>
    <WebbDividerSmall />
    {/* info */}
    <div className="mb-3">
      <p>Enter PO Data and Submit</p>
    </div>
    <WebbDividerSmall />
    
    {/* form */}    
    <form onSubmit={handleSubmit}>
      <div className="">

        <div className="mb-3">
          <div className="form-group">
            <label className="form-label small">Select User <FormNeeded/></label>
            <select 
              className="form-select height-md bg-wite rounded-none"
              style ={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.cust}
              onChange={({ target }) => {
                handleChange("cust", target.value);
                  
              }}
            >
            <option value=''>Select Contact</option>
              {list.map((item,i) => (
                <option key={i} value={item.cnid}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>

      
        <div className="mb-3">
          <label className="form-label small">Number <FormNeeded/></label>
          <input type="text" 
            className="form-control height-md bg-wite rounded-none" 
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.nmbr}
            onChange={({ target }) => {handleChange("nmbr", target.value); setText('');}}
            placeholder="123456">
          </input>
        </div>

        <div className="mb-3">
          <label className="form-label small">Attachment <span className="text-danger">*</span></label>
          <FileSaveFirebase name={data.nmbr} link = {SetFileLink} file={SetFileData}/>    
        </div>

        <div className="mb-3">
          <label className="form-label small">Amount <FormNeeded/></label>
          <input type="text" 
            className="form-control height-md bg-wite rounded-none" 
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.amnt}
            onChange={({ target }) => {handleChange("amnt", target.value); setText('');}}
            placeholder="123456">
          </input>
        </div>

        <div className="mb-3">
          <label className="form-label small">Start Date <FormNeeded/></label>
          <input type="date" 
            className="form-control height-md bg-wite rounded-none" 
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.stts}
            onChange={({ target }) => {handleChange("stts", target.value); setText('');}}
            placeholder="123456">
          </input>
        </div>

        <div className="mb-3">
          <label className="form-label small">End Date <FormNeeded/></label>
          <input type="date" 
            className="form-control height-md bg-wite rounded-none" 
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.ents}
            onChange={({ target }) => {handleChange("ents", target.value); setText('');}}
            placeholder="123456">
          </input>
        </div>

        <div className="mb-3">
          <label className="form-label small">Comments</label>
          <textarea 
              className="form-control rounded-none" 
              style={{fontSize:'0.9rem'}}
              rows="2"
              value={data.memo}
              onChange={({ target }) => {handleChange("memo", target.value);}}
              placeholder="Description">  
            </textarea>
        </div>
      
      </div>

      {/* text */}
      <div className="mb-3">
        <p className="small text-danger">{text}<span className="text-white">{'.'}</span></p>
      </div>
      
      {/* actn */}
      <WebbDividerMedium />
      <div className="">
        <div className="d-grid">
          <button className={`btn height-md btn-primary rounded-none`}
            disabled={!form}
          ><small>Submit</small>
          </button>
        </div>
      </div>
      

    </form> 

    <WebbDividerMedium />
    <WebbDividerMedium />
  </>
  )
}