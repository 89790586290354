//main
import ContentFormatXX from "../content/webc/content-format-xx";

import WebbProgressBar from "../content/webb/webb-progressbar";
import WebbDividerSmall from "../content/webb/webb-divider-sm";

import OnboardBusinessLocationModule from "../content/onboard/bsnx-location";

export default function OnboardBusinessLocs() {

  return (
  <>
    <ContentFormatXX 
      head='New Business Account'
      link={`/account/next`}
      form= 'small'
      data= {
        <>
          <WebbDividerSmall/>
          <WebbProgressBar stat={42} />
          <OnboardBusinessLocationModule />
        </>
      } 
    />
  </>
  )
}