//main
import { useParams } from "react-router-dom";

import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import DocumentsNewFormDataModule from "../content/documents/documents-new-formdata";

export default function DocumentsNewFormData() {

  const {smid, id, frmx } = useParams();
  
  return (
  <>
    <ContentFormatXX 
      head='New Document (Template)'
      link={`/${UserForm()}/documents/view/${smid}/${id}`}
      form= 'small'
      data= {
        <>
          <DocumentsNewFormDataModule />
        </>
      } 
    />
  </>
  )
}