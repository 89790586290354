//main
import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import ContactsNewBusinessModule from "../content/contacts/contacts-new-bznx";


export default function ContactsNewBusiness() {

  return (
  <>
    <ContentFormatXX 
      head='New Contact - Business'
      link={`/${UserForm()}/contacts`}
      form= 'small'
      data= {
        <>
        <ContactsNewBusinessModule />
        </>
      } 
    />
  </>
  )
}