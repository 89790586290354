// init
import * as Realm from 'realm-web';
const app = new Realm.App({ id: process.env.REACT_APP_MNDB_RELM_USER });

// Function Calls

// send and verify passcode
export const SendPasscode = async (item) => {
  const result = await app.currentUser.functions.utilSendPasscode(item);
  return result;
}

export const CheckPasscode = async (item) => {
  const result = await app.currentUser.functions.utilCheckPasscode(item);
  return result;
}

// get states list
export const GetStatesList = async () => {
  const result = app.currentUser.functions.dataGetStatesList(
    JSON.stringify({data:'states', user:'userxx', form:'xx'})
  );
  return result;
}

// get user domain list
export const GetUserDomainList = async (item) => {
  const result = app.currentUser.functions.dataGetUserDomainList(    
    JSON.stringify({data:'user-domain', user:'userxx', form:'xx'})
  );
  return result;
}

// get business format
export const GetBusinessFormatList = async () => {
  const result = app.currentUser.functions.dataGetBusinessFormatList(
    JSON.stringify({data:'business-form', user:'userxx', form:'xx'})
  );
  return result;
}

// get business domain list
export const GetBusinessDomainList = async (item) => {
  const result = app.currentUser.functions.dataGetUserDomainList(    
    JSON.stringify({data:'business-domain', user:'userxx', form:'xx'})
  );
  return result;
}

// solutions
export const GetSolutionsList = async () => {
  const result = await app.currentUser.functions.dataGetSolutionsList(JSON.stringify({
    body: { webb: process.env.REACT_APP_WEBB_SITE_NMBR }, user: {usid: '', form:''}
  }));
  return (result);
}

export const GetSolutionsItem = async (item) => {
  const result = await app.currentUser.functions.dataGetSolutionsItem(JSON.stringify({
    body:{webb: process.env.REACT_APP_WEBB_SITE_NMBR, sort: item}, user:{usid:'', form:''}
  }) )
  return (result);
}

// resources
export const GetResourcesList = async () => {
  const result = await app.currentUser.functions.dataGetResourcesList(JSON.stringify({
    body: { webb: process.env.REACT_APP_WEBB_SITE_NMBR }, user: {usid: '', form:''}
  }));
  return (result);
}

export const GetResourcesItem = async (item) => {
  const result = await app.currentUser.functions.dataGetResourcesItem(JSON.stringify({
    body:{webb: process.env.REACT_APP_WEBB_SITE_NMBR, sort: item}, user:{usid:'', form:''}
  }) )
  return (result);
}

export const SubmitFeedback = async (item) => {
  const result = await app.currentUser.functions.acntGetBaseAccount(item)
  return (result);
}

export const SubmitTicket = async (item) => {
  const result = await app.currentUser.functions.acntGetBaseAccount(item)
  return (result);
}


