import React from 'react'
import templatelogo from '../../media/download.png'
import ListNoData from '../webb/list-nodata'

const MyTemplatesView = (props) => {
  
var data = props.data
// console.log(data)

if (data.length==0){ return ( <div className="mt-5"> <ListNoData /> </div> ) }
  return (
    <div className='row p-2'>  
       {
        data && data.length>0 && data.map((item)=>{
            return(
                <>
                <div class="card mx-1" style={{width: "15rem"}}>
                    <img class="card-img-top" src={templatelogo} alt="Card image cap"  style={{width:'105px', height:'105px'}}/>
                    <div class="card-body">
                        <h5 style={{fontSize:'14px', whiteSpace:'nowrap', overflow:'hidden',  textOverflow:'ellipsis'}} class="card-title fw-bold" >{item.name}</h5>
                        <p style={{fontSize:'12px', textOverflow:'ellipsis', overflow:'hidden', display:'-webkit-box', WebkitLineClamp:2, WebkitBoxOrient:'vertical', msTextOverflow:'ellipsis' }} class="card-text">{item.memo}.</p>
                        <p className="m-0 text-small text-color-tone ">Active: {item.actv?'True':'False'}</p> 
                        <p className="m-0 text-small text-color-tone ">Expired At: {new Date(item.date.ents).toDateString()}</p> 
                        <button   class="btn btn-outline-primary w-100 rounded-none mt-2">View Details</button>
                    </div>
                </div>
                </>
            )
        })
       }
       </div>
  )
}

export default MyTemplatesView