// action
import ActionCardSmall from "../webb/ctas-actioncard-sm";
import ActionCardMedium from "../webb/ctas-actioncard-md";

import { UserForm } from "../../services/srvc-utilities";

export default function TransfersFileModule() {
 
  const data = {
    name: 'Download Statement',
    text: 'Download Excel Statement',
    link: `/${UserForm()}/transfers/statement`,
    actv: true
  }
 
  return (
  <>
    <ActionCardMedium data={data}/>
  </>
  )
}