// new order options
import IconActionCard from "../webb/ctas-iconactioncard";

import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import WebbModuleInfo from "../webb/webb-module-info";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

const list = (require("../../data/optn-contacts.json")).data.filter(item=> item.actv);

export default function ContactsNewModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  
  const data = (list.filter(item => item.user.includes(asset.form)));
  const sort = [...new Set(data.map(item=>item.sort))];

  return (
  <>
    {/* info */}
    <WebbDividerSmall />
    <WebbModuleInfo data={{info: 'Please select an option'}}/>

    {/* options */}
    <WebbDividerSmall />
    <div className="">
    {sort && sort.map((srtx, x) => (
      <div className="row mb-3 pb-3" key={x}>
        <p className="fw-bold text-color-main mb-2">{srtx}</p>
        
        <div className="">
          {data && data.map((item, i) => ( item.sort === srtx ?
            <IconActionCard data={item} key={i} />
          
          :''))}
        </div>
      </div>
    ))}
    </div>

    <WebbDividerMedium/>
  </>
  )
}