// contacts
import { useHistory, useParams } from 'react-router-dom';

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

const list = require('../../data/list-contact-actions.json').data
  
export default function ContactActions(props) {

  const asset = UserForm() === 'in' ? GetLocalUser() : GetLocalBusiness();
  
  const {id} = useParams();
  const history = useHistory();
  
  const data = props.data;
  const lstx = list.filter(item => item.user.includes(asset.form))

  const handleTransfer = async() => {
    history.push(`/${asset.form}/contacts/tranfers/${id}`)
  }

  const handleRoom = async() => {
    history.push(`/${asset.form}/contacts/room/${id}`)
  }
  
  const handleAccount = async() => {
    history.push(`/${asset.form}/contacts/accounts/${id}`)
  }

  const handleEdit = async() => {
    history.push(`/${asset.form}/contacts/edit/${id}`)
  }

  const handleArchive = async() => {
    history.push(`/${asset.form}/contacts/archive/${id}`)
  }
  

  const handleButton = async(code) => {

    switch(code) {
      case 'trxn':
        handleTransfer();
        break;
      case 'room':
        handleRoom();
        break;
      case 'acnt':
        handleAccount();
        break;
      case 'edit':
        handleEdit();
        break;        
      case 'arch':
        handleArchive();
        break;
      default:
        // code block
    }

  }

  return (
    <>
      <div className="rounded bg-white border">
  
        <div className={`row row-cols-5 g-0`} >
        {lstx && lstx.length>0 && lstx.map ((item, i) => 
          <div className="col d-grid" key={i}  
             >
            <div
              className={`text-center rounded py-3 hilite text-color-main`}
              type="button"
              onClick={()=> handleButton(item.code)} 
              style={{}}
              >
                <i className={item.icon}
                  style={{fontSize:'1.5rem', lineHeight:'1em'}}></i>
                <p className="m-0 p-0 mt-1 small text-sm"><small>{item.name}</small></p>
            </div>
          </div>

        )}
        </div>
  
      </div>
    </>
    )
  }