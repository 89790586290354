// contacts
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import HeaderSectionDark from "../webb/head-section-dark";

import ContactOverview from "../webb/cont-overview";
import ContactActions from "../webb/cont-actions";
import ContactInfo from "../webb/cont-contactinfo";
import ContactAccounts from "../webb/cont-accounts";
// import ContactContracts from "../webb/cont-contracts";
// import ContactDocuments from "../webb/cont-documents";
import ContactTransfers from "../webb/cont-transfers";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { GetContactDetail, RemoveContact, BusinessTeamDetails, BusinessTeamRemove } from "../../services/srvc-contacts-realm";
import { GetContactAccounts } from "../../services/srvc-accounts-realm";
// import { GetContactContractsList, GetContactDocumentsList } from "../../services/srvc-smart-realm";
import { GetContactTransactions } from "../../services/srvc-transfers-realm";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function TeamViewModule() {
  
  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const { id } = useParams();
  const history = useHistory()
  const [loading,setLoading]=useState(true);


  const [data,setData]=useState([]);
  const [acnt,setAccounts]=useState([]);
  const [smrt,setContracts]=useState([]);
  const [docx,setDocuments]=useState([]);
  const [trxn,setTransfers]=useState([]);

  useEffect( () => {
    if (asset){
      setLoading(true)
      const fetchData = async() => {
        var datx = {
          data: { item: id },
          user: asset.user,
          webb: process.env.REACT_APP_WEBB_SITE_NMBR
      }
      // console.log(datx)
      var res = (await BusinessTeamDetails(datx)).data
      // console.log(res)
        setData(res)
        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset.user]);

  // console.log(data)

  const RemoveTeamMember = async() =>{
    setLoading(true)
    var datx = {
      data: { item:id },
      user: asset.user,
      webb: process.env.REACT_APP_WEBB_SITE_NMBR
    }
    var res = (await BusinessTeamRemove(datx)).data
    // console.log(res)
    history.push(`/${asset.form.substring(0,2)}/contacts`)
    setLoading(false)

  }

  const EditContact = async()=>{
    history.push(`/${asset.form.substring(0,2)}/team/edit/${id}`)

  }

  if (loading){ return ( <> <WebbLoader /> </> ) }

  return (
  <>
    
    <WebbDividerSmall />
    <ContactOverview data={data} />
    <WebbDividerSmall />

    <ContactInfo data={data}/>
    <WebbDividerSmall />

    <div className="row mt-5">
      <div className="col-6 text-center">
        <button onClick={()=>EditContact()}  type="button" className="text-center btn btn-secondary small w-50" ><p className="small my-auto">Edit</p></button>
      </div>
      <div className="col-6 text-center">
        <button onClick={()=>RemoveTeamMember()} className="text-center btn btn-secondary small w-50"><p className="small my-auto">Remove</p></button>
      </div>
    </div>

    {/* <ContactActions  /> */}
    <WebbDividerMedium />



    <WebbDividerMedium />
    <WebbDividerMedium />
  </>
  )
}