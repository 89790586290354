import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import HeaderSectionDark from "../webb/head-section-dark";
import HeaderSectionLite from "../webb/head-section-lite";

import SmartOverview from "../webb/smart-overview";
import SmartActions from "../webb/smart-actions";
import SmartMembers from "../webb/smart-members";
import SmartFiles from "../webb/smart-files";
import SmartKycFiles from "../webb/smrt-kycfile";


import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { SmartGetInfo } from "../../services/srvc-smart-realm";
import { GetDocumentFiles, SmartGetDocumentList } from "../../services/srvc-smart-realm";


export default function SmartViewFileListModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const { id } = useParams();

  const history = useHistory()

  const [loading, setLoading] = useState(true);

  const [info, setInfo] = useState({});
  const [mmbr, setMembers] = useState([]);
  const [file, setFiles] = useState([]);
  const [Pendingfiles, setPendingFiles] = useState([])
  const [canceledfiles, setCanceledfiles] = useState([])
  const [kycfiles,setKycfiles] = useState([])
  const [draftfiles,setDraftfiles] = useState([])

  const [draft,setDraft] = useState(false)
  const [completed, setCompleted] =useState(true)
  const [cancelled,setCancelled] = useState(false)
  const [processing, setProcessing] = useState(false)
 
  useEffect( () => {
    if (asset){
      const fetchData = async() => {

        const datx = {
          data: { smart: id },
          user: asset.user,
        }

        // info
        var res = (await SmartGetInfo(JSON.stringify(datx))).data
        // console.log(res)
        setInfo(res);

        // files
        const documents =(await SmartGetDocumentList(JSON.stringify(datx))).data

                       
        const completed_docs = documents.filter(function(item){ return item.stat=="6"})
        setFiles(completed_docs);

        const pending_files = documents.filter(function(item){ return item.stat=="2" && item.actv==true})
        // console.log(pending_files)
        setPendingFiles(pending_files)

        const draft_files = documents.filter(function(item){ return item.stat=="0" && item.actv==true})
        // console.log(pending_files)
        setDraftfiles(draft_files)

        var cancelled_files = documents.filter(function(item){ return item.stat=="11" && item.actv==false})
        setCanceledfiles(cancelled_files)

        const datax = {
          data: { document: id },
          user: asset.user,
          webb: process.env.REACT_APP_WEBB_SITE_NMBR
        }

        const kycfiles =(await GetDocumentFiles(JSON.stringify(datax))).data
        setKycfiles(kycfiles)
       
        // members
        var mmbx =res.members
        //  (await GetDocumentMembers(JSON.stringify(datx))).data;
        setMembers(mmbx)

        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset.user]);

  const HandleDocumentChange= async(item)=>{
    
    if(item=="draft"){setDraft(true);setCompleted(false);setCancelled(false);setProcessing(false)}
    if(item=="completed"){setDraft(false);setCompleted(true);setCancelled(false);setProcessing(false)}
    if(item=="cancelled"){setDraft(false);setCompleted(false);setCancelled(true);setProcessing(false)}
    if(item=="processing"){setDraft(false);setCompleted(false);setCancelled(false);setProcessing(true)}

  }

  if (loading){ return ( <> <WebbLoader /> </> ) }

  return (
  <>
    <WebbDividerSmall />
    <SmartOverview data={info}/>
    <WebbDividerSmall />

    <SmartActions data={{docx: info, mmbr: mmbr.length, file: draftfiles.length}}/>
    <WebbDividerMedium />


    {/* members */}
    <HeaderSectionDark data={{name: 'Users'}}/>
    <p className="text-small text-color-tone">
      Please use the Members Option to add, remove and change the Document Signing Order
    </p>
    <SmartMembers data={mmbr} admin= {info.user}/>
    

    {/* files */}
    <WebbDividerMedium />
    <HeaderSectionDark data={{name: 'Documents'}}/>
    <p className="text-small text-color-tone">
      Please use files option above to add files
    </p>

    <div className="back-color-wite rounded-wd">

      <div className="p-3 border-bottom">
        <p className="">
          <i className="bi bi-circle-fill text-color-success small"></i>
          <span className="ms-1 text-bold">Completed</span>
        </p>
        <SmartFiles smartid= {id} data={file}/>  
      </div>
      
      <div className="p-3 border-bottom">
        <p className="">
          <i className="bi bi-circle-fill text-color-next small"></i>
          <span className="ms-1 text-bold">In Process</span>
        </p>
        <SmartFiles smartid= {id} data={Pendingfiles}/>
      </div>


      <div className="p-3 border-bottom">
        <p className="">
          <i className="bi bi-circle-fill text-color-warning small"></i>
          <span className="ms-1 text-bold">In Review</span>
        </p>
        <p className="text-color-tone">No Documents</p>
        <WebbDividerSmall/>
      </div>


      <div className="p-3 border-bottom">
        <p className="">
          <i className="bi bi-circle-fill text-color-tint small"></i>
          <span className="ms-1 text-bold">Draft</span>
        </p>
        <SmartFiles smartid= {id} data={draftfiles}/>
      </div>


      <div className="p-3">
        <p className="">
          <i className="bi bi-circle-fill text-color-danger small"></i>
          <span className="ms-1 text-bold">Cancelled</span>
        </p>
        <SmartFiles smartid= {id} data={canceledfiles}/>
      </div>


    </div>

    <WebbDividerMedium />
    <WebbDividerMedium />
    <WebbDividerMedium />
    <WebbDividerMedium />
    <WebbDividerMedium />
    <WebbDividerMedium />
  </>
  )
}