// transfers
import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

export default function TransferParties(props) {

  // const asset = GetAuthUser();
  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();

  const data = props.data

  return (
  <>
    <div className={`border bg-white ${(data.form === 6 || data.form === 7) ? 'd-none' : '' } `}>

      <div className="d-flex p-3">
        <div className="me-auto">
          <p className="fw-bold m-0 p-0">
            <span>{data.cred !==undefined ? data.cred.name : ''}</span>
            <span className="ps-2"></span>
            <span className={`badge small bg-tone ${data.cred.usid === asset.usid ? '' : 'd-none'}`}>
              {'YOU'}
            </span>
          </p>
          <p className="small m-0 p-0">
            {data.cred.emid}
            {/* {data.cred.form === 'indx' ? 'Individual' : 'Business'} */}
          </p>
        </div>
        <div className="text-end">
          <p className="m-0 p-0 small">
            <i className="bi-circle-fill text-success small"></i>
          </p>
        </div>
      </div>
      <div className="border-bottom"></div>
      <div className="d-flex p-3">
        <div className="me-auto">
          <p className="fw-bold m-0 p-0">
            {data.debt !==undefined ? data.debt.name : ''}
            <span className="ps-2"></span>
            <span className={`badge small bg-tone ${data.debt.usid === asset.usid ? '' : 'd-none'}`}>
              {'YOU'}
            </span>
          </p>
          <p className="small m-0 p-0">
            {data.debt.emid}
            {/* {data.debt.form === 'us' ? 'Individual' : 'Business'} */}
          </p>
        </div>
        <div className="text-end">
          <p className="m-0 p-0 small">
          <i className="bi-circle-fill text-danger small"></i>
          </p>
        </div>
      </div>

    </div>
  </>
  )
}