// create new contact
import { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

// import { GetContactRooms } from "../../services/srvc-contacts-realm";

export default function ContactsRoomsModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const history = useHistory();
  const { id } = useParams();

  useEffect( () => {
    if (asset){
      const fetchData = async() => {

        // var romx = ((await GetContactRooms(JSON.stringify({
        //   data: { cont: id },
        //   user: asset.usid,
        //   webb: process.env.REACT_APP_WEBB_SITE_NMBR
        // }))).data)
        //console.log (romx)

        // if (acnt.length!==0) setAccount (acnt)

      }
      fetchData()
    } else {}
  },[]);

  return (
  <>
    <WebbLoader />
  </>
  )
}