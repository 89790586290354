// accounts
import * as Realm from 'realm-web';

import axios from "axios";

const acnt = new Realm.App({ id: process.env.REACT_APP_MNDB_RELM_TRXN });

var base = "https://ap-south-1.aws.data.mongodb-api.com/app/contrato-docx-jgqgo/endpoint"


export const SendEsinReminderAlert = async (item) => {
  
    const basx = base + '/document/esin/reminder';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      console.log (error);
      return {data: false}
    }
  }
