//main
import { useParams } from "react-router-dom";

import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import DocumentsNewFormModule from "../content/documents/documents-new-form";

export default function DocumentsNewForm() {

  const {smid, id } = useParams();
  
  return (
  <>
    <ContentFormatXX 
      head='New Document'
      link={`/${UserForm()}/documents/view/${smid}/${id}`}
      form= 'small'
      data= {
        <>
          <DocumentsNewFormModule />
        </>
      } 
    />
  </>
  )
}