// transfers
import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import WebbModuleInfo from "../webb/webb-module-info";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";


import { TransfersCreate } from "../../services/srvc-transfers-realm";
import { AccountBalance, BusinessAccountBalance } from "../../services/srvc-accounts-realm";


export default function TransfersAccountCreditModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const history = useHistory();
  
  const [form, setForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);
  
  const [text, setText] = useState('');
  const [acnt,setAcnt] = useState()

  const [data, setData] = useState ({
    acid: '',
    amnt:'',
  })

  useEffect( () => {
    setLoading(true)
    if (asset){
      const fetchData = async() => {

        const result = (await BusinessAccountBalance({ 
          data: { user: asset.user, type: "transfer-account" },
          user: asset.user
        })).data;
        console.log(result)
        setAcnt(result)
        setLoading(false)
      }
      fetchData()
    } else {}
  },[]);

  // form validation
  useEffect( () => {
    setForm(false)
    if (data.amnt !=='' && !isNaN(data.amnt) && parseFloat(data.amnt) !==0) 
      setForm(true);
  },[data]);

  const handleChange = async(key, val) => {
    setForm(false);
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (form) {
      setLoading (true);
      setSubmit(true);

      var datx = {
        debt:{
          name: asset.name,
          mail: asset.mail,
          user: asset.user,
        },
        cred:{
          name: asset.name,
          mail: asset.mail,
          user: asset.user,
        },
        amount: {number: (data.amnt*1000000).toString(), ticker: 'xxxx'},
        memo: 'account credit',
        time: Date.now(),
        format: 'transfer',
        sort: 6
      }
      // api call
      const result = await TransfersCreate ({
        data: datx, user: asset.user,
      });
      if (result.data) {
        console.log (result)
        setDone(true)
        history.push(`/${asset.form.substring(0,2)}/transfers/submit`);
      }
    }
    else {
      setText ('Please Enter all details')
    }

    setLoading (false);
  }

  if (loading){ return ( <> <WebbLoader /> </> ) }
  // return ( <> </> )

  return (
  <>
    {/* info */}
    <WebbDividerSmall/>
    {/* <WebbModuleInfo data={{info: 'Enter Amount and Submit'}}/> */}
    
    {/* form */}
    <form onSubmit={handleSubmit}>
      <div className="">

        <div className="mb-3">
          <label className="form-label small">Enter Amount <FormNeeded /></label>
          <input type="text" 
            className="form-control height-md rounded-none bg-wite" 
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.amnt}
            onChange={({ target }) => {handleChange("amnt", target.value); setText('');}}
            placeholder="">
          </input>
        </div>
        
        {/* <p className="text-small small">Following is Your Account Number and IFSC code you can recharge</p>
        <div className="mb-3">
          <label className="form-label small">Account Number <FormNeeded /></label>
          <input disabled type="text" 
            className="form-control height-md rounded-none bg-wite" 
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={acnt.account.number}
            
            placeholder="">
          </input>
        </div>

        <div className="mb-3">
          <label className="form-label small">IFSC Code <FormNeeded /></label>
          <input disabled type="text" 
            className="form-control height-md rounded-none bg-wite" 
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value="YESB0CMSNOC"
            
            placeholder="">
          </input>
        </div>
        <WebbDividerMedium />
        <FormNeeded /> Transfer Mode - NEFT, IMPS and RTGS Allowed */}

      </div>

      {/* text */}
      <div className="mb-3">
        <p className="small text-danger">{text}<span className="text-white">{'.'}</span></p>
      </div>
      
      {/* actn */}
      <WebbDividerMedium />
      <div className="">
        <div className="d-grid">
          <button 
            className={`btn height-md rounded-none ${ form ? 'btn-primary back-color-main' : 'btn-primary back-color-main'}`}
            disabled={!form}
          ><small>Submit</small>
          </button>
        </div>
      </div>

    </form>
    
    <WebbDividerMedium />
    <WebbDividerMedium />

  </>
  )
}