// onboard
import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";

import { GetAuthUser, SetNewUser, GetNewUser } from "../../services/srvc-auth-user";
import { NewUserAccount, SetUserName } from "../../services/srvc-users-realm";

export default function OnboardUserNameModule() {

  const asset = GetAuthUser();
  const usrx = GetNewUser();
  const history = useHistory();
  // console.log(asset, usrx)
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(false);
  const [text, setText] = useState('');

  const [data, setData] = useState({
    name: '',
    mmid: '',
    mmvr: false,
    emid: asset.user,
    emvr: true,
    form: 'indx',
    self: true,
    actv: true,
    base: true,
    usid: usrx.usid ??= ''
  });

  // form validation
  useEffect( () => {
    setForm(false)
    if (data.name !=='' && data.emid !=='' && data.mmid !==''  && data.mmid.length ===10) 
      setForm(true);
  },[data]);

  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form) 
    {
      setLoading(true);

      const datx = {
        data: { 
          name: data.name,
          mail: data.emid,
          mobile: data.mmid,
          active: true, 
          self: true
        },
        user: (usrx.usid!=='') ? usrx.usid : (process.env.REACT_APP_WEBB_SITE_NMBR)
      }
      console.log( JSON.stringify(datx) );
      
      var result = (usrx.usid === '') 
        ? await NewUserAccount(datx)
        : await SetUserName(datx)

      console.log(result)
      setLoading(false);
      
      if (result.data) {
        //console.log (res);
        SetNewUser({user:result.data.user})
        setLoading(false);
        history.push(`/auth/next`);
      }
      
      setLoading(false);
      //resetForm();
    }
    else {
      setText('Please enter Account Details')
    }
  }

  if (loading){ return ( <> <WebbLoader /> </> ) }
  
  return (
  <>
    {/* info */}
    <WebbDividerSmall />
    <p className="text-tone">Please Enter Your Account Info</p>

    {/* form */}
    <form onSubmit={handleSubmit}>
      <div className="">

        <div className="form-group mb-3">
          <label className="form-label small">Name  <FormNeeded /></label>
          <input type="text" 
            className="form-control height-md rounded-none" 
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.name}
            onChange={({ target }) => {handleChange("name", target.value); setText('');}}
            placeholder="">
          </input>
        </div>

        <div className="form-group mb-3">
          <label className="form-label small">Email <FormNeeded /></label>
          <input type="text" 
            className="form-control height-md rounded-none" 
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            disabled
            value={data.emid}
            onChange={({ target }) => {handleChange("emid", target.value); setText('');}}
            placeholder="">
          </input>
        </div>

        <div className="form-group mb-3">
          <label className="form-label small">10-digit Mobile <FormNeeded /></label>
          <input type="text" 
            className="form-control height-md rounded-none" 
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.mmid}
            onChange={({ target }) => {handleChange("mmid", target.value); setText('');}}
            placeholder="">
          </input>
        </div>

      </div>

      {/* text */}
      <div className="mb-3">
        <p className="small text-danger">{text}<span className="text-white">{'.'}</span></p>
      </div>
      
      {/* actn */}
      <WebbDividerMedium />
      <div className="">
        <div className="d-grid">
          <button className={`btn height-md btn-primary back-color-main rounded-none`}
            disabled={!form}
          ><small>Save & Continue</small>
          </button>
        </div>
      </div>

    </form>
    
    <WebbDividerMedium />
    <WebbDividerMedium />

  </>
  )
}