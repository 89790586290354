// user
import { useEffect, useState } from "react";
import WebbLoader from "../webb/webb-loader";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

const list = require('../../data/list-user-settings.json').data.filter(item=>item.actv)

export default function UserListModule(props) {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [sort, setSort] = useState({item:'Profile'})

  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        setLoading(true);
        setData(list.filter(item => item.user.includes(asset.form)))
        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset.usid]);
  
  const handleChange = async(key, val) => {
    setSort({ ...sort, [key]: val });
  }

  const handleSelect = async(sort) => {
    // console.log('item: ', sort);
    handleCallback(sort);
  }

  const handleCallback = (srtx) => {
    props.item(srtx);
  }

  if (loading){ return ( <> </> ) }

  return (
  <>
    <div className="rounded-wd back-color-wite">
    {data && data.length > 0 && data.map((item, i) => (
      <div className={`d-flex cursor p-3 ${ i < data.length-1 ? 'border-bottom border-light ': ''}`} key={i}
        onClick={()=>{handleChange('item',item.name); handleCallback(item.name)}}
      >
          <div className="">
            <span className="text-small">
              <i className={`m-0 p-0 small bx bxs-circle text-${sort.item===item.name? 'color-next': 'color-lite'}`}></i>
            </span>
          </div>
          
          <div className="ms-2">
            <p className={`m-0 p-0 text-${sort.item===item.name? 'color-next text-bold': 'color-tone'}`}>
              {item.name}
            </p>
          </div>

          <div className="ms-auto">
            <i className={`m-0 p-0 text-color-tint bi-chevron-right`}></i>
          </div>
        
      </div>
    ))}
    </div>
  </>
  )
}