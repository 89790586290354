// create new contact
import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import WebbModuleInfo from "../webb/webb-module-info";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { CreateNewContactUser } from "../../services/srvc-contacts-realm";

const list = require('../../data/data-states-list.json').data;

export default function ContactsNewIndividualModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const history = useHistory();

  const [loading, setLoading]=useState(false)
  const [form, setForm]=useState(false);
  const [text, setText] = useState("");
  
  const [data, setData] = useState({
    name: '',
    mmid: '',
    emid: '',
    loct: '',
    lost: '',
    locn: 'India',
  });

  // useEffect for form validation
  useEffect( () => {
    setForm(false);
    if (data.name!=='' && data.mmid!=='' && data.emid!=='' ) 
      setForm(true);
  },[data]);

  const handleChange = async(key, val) => {
    setForm(false);
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
 
    if (form) {
      setLoading (true);
      
      var datx = {
        user: { name:data.name, mail:data.emid, mobile: '91'+data.mmid },
        team: asset.user
      }
      // console.log (JSON.stringify(datx))
      
      const result = await CreateNewContactUser ({data: datx, user: asset.user});

      console.log (result)
      if (!result.data) {
        window.alert('Error: Contact with Mobile or Email already exist')
      }
      if (result.data) {
        history.push(`/${asset.form.substring(0,2)}/contacts`);
      }
      setLoading(false)
    }
    else {
      setText ('Please Enter all details')
    }
  }

  if (loading) { return ( <> <WebbLoader /> </> ) }

  return (
  <>

    {/* info */}
    <WebbDividerSmall/>
    <WebbModuleInfo data={{info: 'Enter Contact Details and Save'}}/>
      
    {/* input */}
    <form onSubmit={handleSubmit}>
      <div className="">

        <div className="form-group mb-3">
          <label className="form-label small">Full Name <FormNeeded/></label>
          <input type="text" 
            className="form-control height-md rounded-none bg-wite"
            style ={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.name}
            onChange={({ target }) => {handleChange("name", target.value); setText('');}}
            placeholder="Enter Full Name">
          </input>
        </div>

        <div className="form-group mb-3">
          <label className="form-label small">Email Address <FormNeeded/></label>
          <input type="text" 
            className="form-control height-md rounded-none bg-wite"
            style ={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.emid}
            onChange={({ target }) => {handleChange("emid", target.value); setText('');}}
            placeholder="user@mail.com">
          </input>
        </div>

        <div className="form-group mb-3">
          <label className="form-label small">Mobile Number <FormNeeded/></label>
          <input type="text" 
            className="form-control height-md rounded-none bg-wite"
            style ={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.mmid}
            onChange={({ target }) => {handleChange("mmid", target.value); setText('');}}
            placeholder="9876540123">
          </input>
        </div>

        {/* <div className="form-group mb-3">
          <label className="form-label small">City <FormNeeded/></label>
          <input type="text" 
            className="form-control height-md rounded-none bg-wite"
            style ={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.loct}
            onChange={({ target }) => {handleChange("loct", target.value); setText('');}}
            placeholder="City">
          </input>
        </div>

        <div className="form-group mb-3">
          <label className="form-label small">State <FormNeeded/></label>
          <select 
            className="form-select height-md rounded-none bg-wite"
            style ={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.lost}
            onChange={({ target }) => handleChange("lost", target.value)}
          >
            <option value=''>Select State</option> 
            {list.map((item,i) => (
              <option key={i} value={item.name}>{item.name}</option>
            ))}
          </select>
        </div>

        <div className="form-group mb-3">
          <label className="form-label small">Country <FormNeeded/></label>
          <input type="text" 
            className="form-control height-md rounded-none bg-wite"
            disabled
            style ={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.locn}
            onChange={({ target }) => {handleChange("locn", target.value); setText('');}}
            placeholder="Country">
          </input>
        </div> */}


      </div>

      {/* text */}
      <div className="mb-3">
        <p className="small text-danger">{text}<span className="text-white">{'.'}</span></p>
      </div>

      {/* actn */}
      <WebbDividerMedium />
      <div className="">
        <div className="d-grid">
          <button className={`btn height-md rounded-none btn-primary back-color-main`}
            // disabled={!form}
          ><small>Save & Continue</small>
          </button>
        </div>
      </div>


    </form>

    <WebbDividerMedium/>
    <WebbDividerMedium/>
  </>
  )
}