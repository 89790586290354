// init transfers
import axios from "axios";
import CryptoJS from "crypto-js";
const base = "https://ap-south-1.aws.data.mongodb-api.com/app/contrato-invc-tyowr/endpoint"

var baseApi = "https://srvx.contrato.cc/xx"

// Function Calls


const secretPass = "603082712271C525E087BD999A4E0738";

const EncryptData = (data)=>{
  var encrypt=  CryptoJS.AES.encrypt(JSON.stringify(data), secretPass,).toString();
  return encrypt
}

// read functions
export const InvoicesStatus = async (item) => {


  const basx = baseApi + '/invoices.status';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}

// create new invoice
export const InvoicesCreate = async (item) => {

  const basx = baseApi + '/invoices.create';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}

export const CreateNewOrders = async (item) => {

}


export const GetInvoicesList = async (item) => {

  

  const basx = baseApi + '/invoices.list';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  // var encrypt = await EncryptData(datx)
  // console.log(encrypt)

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}
export const InvoicesListWait = async (item) => {

}

export const InvoicesDetail = async (item) => {

  const basx = baseApi + '/invoices.details';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }


}

export const GetInvoiceFiles = async (item) => {

}

// actions
export const SetInvoicesCancel = async (item) => {

  const basx = baseApi + '/invoices.cancel';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}

export const SetInvoicesDecline = async (item) => {

  const basx = baseApi + '/invoices.reject';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}

export const SetInvoicesConfirm = async (item) => {

  const basx = baseApi + '/invoices.approve';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}


