//main
import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import TransfersAccountCreditModule from "../content/transfers/account-credit";


export default function TransfersCreditAccount() {

  return (
  <>
    <ContentFormatXX 
      head='Account Credit'
      link={`/${UserForm()}/home`}
      form= 'small'
      data= {
        <>
        <TransfersAccountCreditModule />
        </>
      } 
    />
  </>
  )
}