
import WebbDivider from "../webb/webb-divider";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import HeaderSectionLite from "../webb/head-section-lite";

export default function UserReferrals () {
  return (
    <>
      <HeaderSectionLite data={{name: 'Referrals and Rewards'}}/>
      <p>Coming Soon</p>
      <WebbDividerMedium />

    </>
  )
}