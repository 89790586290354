import React, { useEffect, useRef, useState } from 'react'
import { UserForm } from '../../services/srvc-utilities';
import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-user';
import hi from '../../media/helo.gif'
import { AddMessageToRoom, GetLocalMessages, GetRoomDetails, GetRoomLive, ReadRoomMessage } from '../../services/srvc-chat-realm';
import * as Realm from 'realm-web';

import CryptoJS from 'crypto-js';


var secretPass = "contratotextencr"
const  app = new  Realm.App({ id:"contrato-room-khdno"});

const ChatListModule = (props) => {
const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
// console.log(asset)
var data = (props.data)
// console.log(data)
const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" })
  }

const [messages, setMessages] = useState([])
const [input, setInput] = useState()
const [roomid, setRoomid] = useState()
const [loading, setLoading] = useState(true)
const [refresh, setRefresh] = useState(false)

var arr =[]

const addMessage=async()=>{
    // var msgs=[]
    // setMessages([...messages, {text:input, id:asset.user, time:'12:67'}])
    var user2 = data.user.find(item=> item.usid!= asset.user)
   // console.log(user2)
    var datx = {
        usr1: asset.user,
        usr2: user2.usid ,
        sndr: asset.name,
        recv: user2.name,
        text: input
    }
    // console.log(datx)
    var res = await AddMessageToRoom({data:datx})
    // setRefresh(!refresh)
    props.handleRefresh()
}

useEffect(() => {
    
    if(data.name!= undefined)
    {
        setLoading(true)
        const fetchdata = async()=>{

            var roomdetails = await GetRoomDetails({data:{usr1:data.user[0].usid, usr2:data.user[1].usid, user : asset.user }})
            // console.log(roomdetails)
            if(roomdetails.data!= false){ setRoomid(roomdetails.data.item) }
            

            var res = await ReadRoomMessage({data:{usr1:data.user[0].usid, usr2:data.user[1].usid, items:20, index:1 }})
            setMessages(res.data.list)
            // console.log(res)
            setLoading(false)
            scrollToBottom()
        }
        fetchdata()
        
    }
    
  }, [data]);


const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
        // console.log(event.target.value)
      setInput(event.target.value);
      addMessage()
      setInput('')
    }
}

useEffect( () => {
    if (asset){
      const fetchData = async() => {
        // setLoading(true);
        // console.log("called")
        var textmsgs = (await GetRoomLive(roomid))
        // var localmessage = await GetLocalMessages()
        // console.log(textmsgs)
        var exists = messages.filter(el=> {return el.item=== textmsgs.item })
        // console.log(exists)
        if(exists.length>0)
        {}
        else{
            setRefresh(!refresh)
            setMessages(messages => [...messages, textmsgs])
            scrollToBottom()
            
        }      
      }
      fetchData()
    } else {}
  },[roomid, refresh]);



if(loading && data.name!= undefined) return(<><p className='text-center gw-bold text-secondary'>Loading....</p></>)

      
  return (
    <div className=''>
    
    <div className={`${data.name == undefined ?'':'d-none'}`}>
        <p className='text-center text-secondary fw-normal mt-5' style={{fontSize:'36px'}}>Welcome!</p>
        <p className='text-center' style={{marginTop:'-27px'}}>Welcome to contrato chat facility</p>
        <div className='text-center'>
        <img className='' src={hi} style={{width:'120px', height:'120px'}} />
        </div>

        <div className='text-center' style={{marginTop:'250px'}}>
            <button className='btn btn-success rounded-none w-25 disabled'>Explore</button>

        </div>
    </div>

    <div className={`${data.name == undefined ?' d-none':'  p-2'}`} style={{display:'flex', flexDirection:'column'}} >
        
        {messages && messages.length > 0 && messages.map((item, i) => {
            if(item.room==roomid){
                return(
                    <div className="mb-3" key={i}>
                    <p className=" m-0 p-0 text-tone">
                        <img src="https://img.lovepik.com/free-png/20220125/lovepik-boy-avatar-png-image_401704863_wh300.png"  style={{width:'20px', height:'20px', borderRadius:'50%'}} />
                        <small className="fw-bold mx-2 " >{item.sndr.name}{', '}</small> 
                        <small className='text-small' style={{fontSize:'11px'}}>{new Date(item.crts).toLocaleDateString()}{', '}{new Date(item.crts).toLocaleTimeString()}</small>
                    </p>
                    <div className='d-flex'>
                        <div className='me-auto w-75 mx-4'>
                            <p className="m-0 p-0 mt-1 mx-1">{item.text}</p>  
                        </div>

                        <div className='text-end'>
                            {/* <p className='text-small'>seen</p> */}
                        </div>    

                    </div>
                    
                    </div>
                )
                }
        }  
        )}
      
      <div ref={messagesEndRef} style={{marginBottom:'6em'}}></div>

        <div className='d-flex' style={{position:'absolute', width:'48%', height:'40px', marginTop:'520px'}}>
            <div className='me-auto w-100' style={{marginTop:'50px'}}>
                <input  onKeyDown={(e)=>handleKeyDown(e)} value={input} onChange={(e)=>setInput(e.target.value)} style={{marginTop:"-10px", height:'55px'}} className='form-control ' type='text' placeholder='Say Something' />
            </div>
            <div className='text-end' style={{marginTop:'50px'}}>
                <button onClick={()=>{addMessage();setInput('')}} style={{height:'55px', marginTop:'-10px', marginLeft:'5px'}} className='btn btn-success'>Send</button>

            </div>

        </div>
    </div>

  
    

    </div>
  )
}

export default ChatListModule