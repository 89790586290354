//main
import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import TransfersNewModule from "../content/transfers/transfers-new";

export default function TransfersNew() {

  return (
  <>
    <ContentFormatXX 
      head='New Transfer'
      link={`/${UserForm()}/transfers`}
      form= 'small'
      data= {
        <>
          <TransfersNewModule />
        </>
      } 
    />
  </>
  )
}