//main
import ContentFormatXA from "../content/webc/content-format-xa";

import WebbDivider from "../content/webb/webb-divider";
import WebbDividerSmall from "../content/webb/webb-divider-sm";
import WebbDividerMedium from "../content/webb/webb-divider-md";
import HeaderSectionLite from "../content/webb/head-section-lite";

import AccountsListModule from "../content/accounts/accounts-list";
import NewAccountsModule from "../content/accounts/accounts-create";


export default function Accounts() {

  return (
  <>
    <ContentFormatXA 

    sidebar=
    {{
      head: <> <strong>Accounts</strong> </>,
      data: 
        <> 
        <HeaderSectionLite data={{name: 'Primary Account'}}/>
        
        </>,
      foot: <> Today: </>,
    }}
    main=   
      {{      
        head: 'Account Transfers',
        data: 
          <> 
            <AccountsListModule />

          </>,
        foot: <> </>,
      }}
    actionbar=
      {{      
        head: <> Activity </>,
        data: 
          <>
          <NewAccountsModule />

          </>,
        foot: <> </>,
      }}    
    
    />
  </>
  )
}