//main
import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import SmartViewFileListModule from "../content/smart/smarts-view";

export default function SmartsView() {

  return (
  <>
    <ContentFormatXX 
      head='View Document'
      link={`/${UserForm()}/smart`}
      form= 'medium'
      data= {
        <>
          <SmartViewFileListModule />
        </>
      } 
    />
  </>
  )
}