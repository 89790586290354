//main
import { useParams } from "react-router-dom";

import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import DocumentsNewXXXXModule from "../content/documents/documents-new-xxxx";

export default function DocumentsNewXXXX() {

  const { id,smid } = useParams();
  console.log(smid)
  
  return (
  <>
    <ContentFormatXX 
      head='New Document'
      link={`/${UserForm()}/documents/view/${smid}/${id}`}
      form= 'small'
      data= {
        <>
          <DocumentsNewXXXXModule />
        </>
      } 
    />
  </>
  )
}