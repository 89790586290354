//loader
export default function WebbSpinner() {
 
  return (
  <>
    {/* spinner */}
    <div className="spinner-border spinner-border-sm text-color-tint" role="status" style={{borderWidth:'0.06rem'}}>
      <span className="visually-hidden">Loading...</span>
    </div>
  </>
  )
}