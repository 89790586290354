//main
import { useParams } from "react-router-dom";

import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import DocumentsTracksModule from "../content/documents/documents-tracks";

export default function DocumentsTracks() {

  const { id } = useParams();

  return (
  <>
    <ContentFormatXX 
      head='View Document History'
      link={`/${UserForm()}/documents/view/${id}`}
      form= 'medium'
      data= {
        <>
          <DocumentsTracksModule />
        </>
      } 
    />
  </>
  )
}