// init documents
// formats - ready


import axios from "axios";

var base ="https://ap-south-1.aws.data.mongodb-api.com/app/contrato-docx-jgqgo/endpoint"

var baseApi = "https://srvx.contrato.cc/xx"
// Function Calls




export const GetFormatData = async (item) => {
  const basx = baseApi + '/format.data';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = item

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    console.log (error);
    return {data: false}
  }
  }

  export const CreateTask = async (item) => {
  const basx = baseApi + '/document.create.task';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = item

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    console.log (error);
    return {data: false}
  }
  }


  export const CollectKYC = async (item) => {
    const basx = baseApi + '/collect.kyc';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      console.log (error);
      return {data: false}
    }
    }