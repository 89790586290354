// user
const code = require('../../data/code-contracts.json').data;

export default function UserDocumentsCard(props) {

  const data = props.data;
  // console.log(data)
  
  return (
  <>
    <div className="d-flex bg-white p-3 border-bottom">

      <div className="">
        <p className="m-0 p-0 text-color-main fw-bold">{data.name}</p>
        <p className="m-0 p-0 small text-dark">{data.nmbr || '******'}</p>
        <p className={`m-0 small text-${data.stat=="6" ? 'success' : 'danger'}`}>
        {data.stat=="6" ? 'Verified' : 'Not Verified'}
        </p>
        <p>{new Date(data.crts).toLocaleTimeString()},{new Date(data.crts).toLocaleDateString()}</p>
      </div>
        
      {/* status */}
      <div className="ms-auto text-end ">    
        <button 
          onClick={()=>window.open(data.file)}
          className={`btn rounded-circle m-0 p-0 ${'bg-lite'}`}
          style={{width:'2.4rem', height:'2.4rem', pointerEvents:'all'}}
          ><i className={ 'bi bi-arrow-down' }></i>
        </button>
        
      </div>
        
    </div>

  </>
  )
}