//loader
export default function WebbLoader(props) {
 
  return (
  <>
      {/* web */}
      <div className="container-fluid py-3" style={{minHeight:"96vh"}}>
        <div className="row">
          <div className="col text-center">
          <div className="" style={{height:"9vh"}} ></div>
          <div className="spinner-border text-color-tone" role="status" style={{borderWidth:'0.06rem'}}>
            <span className="visually-hidden">Loading...</span>
          </div>
            <p className="small text-tone m-0 pt-3 pb-0"
            >{props.text ? props.text : 'Please wait...'}</p>
          </div>
        </div>
      </div>
  </>
  )
}