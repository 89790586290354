// accounts list
import { crnc } from "../../services/srvc-utilities";

import avtx from '../../media/user.png';
const bank = 'https://firebasestorage.googleapis.com/v0/b/moneyorder-ws.appspot.com/o/bank%2Fbank-bank.png?alt=media&token=435097e8-6799-42d3-9a24-5802c79d0610'

export default function AccountListCard(props) {

  const data = props.data;

  return (
  <>

    <div className="d-flex border rounded p-3 mb-3 hilite">

      <div className="bg-lite p-1 text-center" style={{height:'42px', width:'42px'}}>
        <img src={bank} className="d-none" alt="..." 
          style={{height:'42px', width:'42px'}}>
        </img>
        <i className="bi-bank2 text-tint" style={{fontSize:'1.5rem'}}></i>
      </div>

      <div className="ps-3">
        <p className="fw-bold m-0 p-0 mb-1">{data.acnr.toUpperCase()}</p>
        <p className="small m-0 p-0 text-tone">{data.acnm.toUpperCase()}</p>
      </div>

      <div className="ms-auto text-end">
        <p className="fw-bold m-0 p-0 mb-1">{crnc(data.acbl)}</p>
      </div>

    </div>

    <div className="border-bottom my-2 d-none"></div>
  </>
  )
}