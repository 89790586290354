//main
import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import AccountsLinkUPIModule from "../content/accounts/accounts-link-upi";

export default function AccountsLinkUPI() {

  return (
  <>
    <ContentFormatXX 
      head='Link UPI Account'
      link={`/${UserForm()}/accounts/link`}
      form= 'small'
      data= {
        <>
          <AccountsLinkUPIModule />
        </>
      } 
    />
  </>
  )
}