//main
import { useState } from "react";

import ContentFormatXA from "../content/webc/content-format-xa";


import WebbDivider from "../content/webb/webb-divider";
import WebbDividerSmall from "../content/webb/webb-divider-sm";
import WebbDividerMedium from "../content/webb/webb-divider-md";
import HeaderSectionLite from "../content/webb/head-section-lite";
import HeaderSectionDark from "../content/webb/head-section-dark";
import WebbDateTime from "../content/webb/webb-datetime";

import { useEffect } from "react";

import { UserForm } from "../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../services/srvc-auth-user";
import { useHistory } from "react-router-dom";
import { GetUserContactsList } from "../services/srvc-contacts-realm";
import ChatListModule from "../content/chats/chat-list";
import { GetMyRooms, GetRoomNotification, RoomCreateForContact } from "../services/srvc-chat-realm";
import doubletick from '../media/dtick2.png'




export default function Rooms() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();

  const [sort, setSort] = useState('contracts');
//const [search, setSearch] = useState();
  const [data, setData] = useState([])
  const [selected, setSelected] = useState({})
  const [srch, setSearch] = useState('')
  const [room ,setRoom] = useState()
  const [contact, setContact] = useState([])

  const [loading, setLoading] = useState(true)
  const [refresh, setRefresh] = useState(false)

  const history = useHistory()


  useEffect( () => {
    setLoading(true)
    if (asset){
      const fetchData = async() => {
        const result = await GetUserContactsList({ 
            data: {
              user: asset.user,
              index: 1, items: 50,
              filters: {name:''}
              },
            user: asset.user
            })
            //console.log (result.data)
            if(result.data==false){setData([])}
            else{setData(result.data.list)}   
        setLoading(false)
        // setRefresh(!refresh)
      }
      fetchData()
    } else {}
  },[]);


  useEffect( ()=>{
    if(asset)
    {
      const fetchdata = async()=>{

        var res = await GetMyRooms({ data: {user: asset.user,},user: asset.user})
        // console.log(res.data)
        setRoom(res.data)
      }
      fetchdata()
    }

  },[refresh])

  useEffect( () => {
    if (srch === '') setContact([])
    else setContact(data.filter(item => item.name.toLowerCase().includes(srch.toLowerCase())))
  },[srch]);


  const RoomCreate = async(item)=>{

    // console.log(item)
    var datx = {
      usr1: asset.user,
      usr2: item.user ,
      sndr: asset.name,
      recv: item.name,
  }
  //console.log(datx)
  var res = await RoomCreateForContact({data:datx})
  // console.log(res)
  setRefresh(!refresh)
  setSearch('')

  }


  const handleSearch = async(item) => {
    // console.log('srch: ', sort)
   
    setSearch(item);
  }

 
  useEffect(()=>{

    const fetchupdate = async()=>{
      var res = await GetRoomNotification()
      // console.log(res) 
      setRefresh(!refresh)    
    }
    fetchupdate()

  },[refresh])

  const handleRefresh = async()=>{
    setRefresh(!refresh)
  }



  return (
  <>
    <ContentFormatXA 

    sidebar=
    {{
      head: <> <strong className="text-tone">Contacts</strong> </>,
      data: 
        <> 

          <HeaderSectionLite data={{name: 'Search Contacts'}}/>
          {/* <SmartSearchModule search={handleSearch}/> */}
          <div className="form-group px-0 border-bottom mb-4">
            {/* <label className="text-small text-color-tone m-0">Name</label> */}
            <input type="text" 
            className="form-control border-none px-0 bg-transparent"
            style={{fontSize:'0.9rem'}} 
            value={srch}
            placeholder="search contact"
            onChange={(e) => {setSearch(e.target.value); }}
            ></input>
            <div>
              {
                contact.map((item,i)=>{
                  return(
                    <div key={i} className="d-flex p-2">
                      <div className="me-auto text-dark fw-bold"> <p>{item.name}</p> </div>
                      <div onClick={()=>RoomCreate(item)} className="text-end"><button className="btn btn-success rounded">+</button></div>
                    </div>
                  )
                })
              }
            </div>


          </div>
          <div>
            {/* {
                data && data.length>0 && data.map((item,i)=>{
                    return(
                        <div key={i} onClick={()=>setSelected(item)} className={`d-flex  p-1 ${selected.item==item.item ? 'bg-white border text-primary rounded ':'' }` } style={{cursor:'pointer' }}>
                            <div className="">
                                <img src="https://img.lovepik.com/free-png/20220125/lovepik-boy-avatar-png-image_401704863_wh300.png" style={{borderRadius:'50%' , width:'30px', height:'30px'}} />
                            </div>
                            <div className="mt-1">
                                <p className=" mx-2">{item.name}</p>
                            </div>
                        
                        </div>
                    )
                })
            } */}
            {/* {room[i].user[1].name.substring(0, 1)} */}

            {
                room && room.length>0 && room.map((item,i)=>{
                    return(
                      
                        <div key={i} onClick={()=>setSelected(item)} className={`d-flex border-bottom p-1 ${selected.item==item.item ? 'bg-white border text-primary rounded mt-2  ':'' }` } style={{cursor:'pointer' }}>
                            <div className="bg-warning " style={{borderRadius:'50%' , width:'40px', height:'40px', }}>
                                {/* <img src="https://img.lovepik.com/free-png/20220125/lovepik-boy-avatar-png-image_401704863_wh300.png" style={{borderRadius:'50%' , width:'40px', height:'40px'}} /> */}
                                {/* <p className="fw-bold text-dark mx-2" style={{fontSize:'12px', marginTop:'13px'}}>{room[i].user[i].usid === asset.user? room[i].user[1].name.match(/\b(\w)/g) :room[i].user[0].name.match(/\b(\w)/g) }</p>     */}
                            </div>
                            <div className="mt-1">
                              {
                                item.user.map((user)=>{
                                  // console.log(user)
                                  return <p style={{whiteSpace:'nowrap', overflow:'hidden',  textOverflow:'ellipsis', width:'150px' }} className={` ${user.usid === asset.user?'d-none':'mx-2  my-auto fw-bold'} `}>{user.name }</p>
                                })
                              }
                              <br/>
                              <div className="d-flex">
                                <div><p style={{whiteSpace:'nowrap', overflow:'hidden',  textOverflow:'ellipsis', marginTop:'-20px', width:'160px' }}  className=" mx-2 text-small text-secondary">{item.lastMessage}</p></div>
                                <div style={{marginLeft:'0px', marginTop:'-22px'}} className={item.seenPendingBy != asset.user && parseInt(item.notf)==0 ? 'text-success':'d-none' }>
                                  <div className=""  > <i className="bi bi-check2-all text-primary" style={{fontSize:'1.2rem'}}  ></i> </div>
                                </div> 

                              </div>    
                            </div>
                            
                            <div style={{marginLeft:'0px'}} className={item.seenPendingBy === asset.user && parseInt(item.notf)>0? 'text-success':'d-none' }>
                               <div className="bg-success " style={{borderRadius:'50%', width:'30px'}} > <p className="mt-1 text-light mx-2">{item.notf}</p> </div>
                            </div>

                        </div>
                    )
                })
            }


          </div>
          
        </>,
      foot: <> <WebbDateTime /> </>,
    }}
    main=   
      {{      
        // head:   selected ? selected.user[0].usid != asset.user? selected.user[0].name : selected.user[1].name :'' || ''     || 'Chat List',
        data: 
          <> 
            {/* navs */}
            <div className="d-flex justify-content-start">
             
            </div>
            
            {/* content */}
            <WebbDividerMedium />
            {/* <HeaderSectionDark data={{name: selected.name}}/>  */}

            <ChatListModule handleRefresh={handleRefresh} data={selected} />
            <WebbDividerMedium />
            <WebbDividerMedium />
          </>,
        foot: <> </>,
      }}
    actionbar=
      {{      
        head: 'Activity',
        data: 
        <div >
          <div >
            {/* <SmartActionsModule/>
            <StampsActionsModule /> */}
            <WebbDividerMedium/> 
          </div>
        </div>,
        foot: <> </>,
      }}  
    />
  </>
    
  )
}







