//main
import ContentFormatXX from "../content/webc/content-format-xx";
import { UserForm } from "../services/srvc-utilities";

import TransfersDebitModule from "../content/transfers/transfer-debit";

export default function TransfersDebit() {

  return (
  <>
    <ContentFormatXX 
      head='New Transfer'
      link={`/${UserForm()}/home`}
      form= 'small'
      data= {
        <>
        <TransfersDebitModule />
        </>
      } 
    />
  </>
  )
}