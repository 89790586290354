//main
import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import InvoiceRulesNewModule from "../content/orders/rules-new";

export default function InvoiceRulesNew() {

  return (
  <>
    <ContentFormatXX 
      head='Authorization Rules'
      link={`/${UserForm()}/invoices`}
      form= 'small'
      data= {
        <>
          <InvoiceRulesNewModule />
        </>
      } 
    />
  </>
  )
}