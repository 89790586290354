//main
import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import InvoicesSubmitModule from "../content/invoices/invoices-submit";

export default function InvoicesSubmit() {

  return (
  <>
    <ContentFormatXX 
      head='Invoices'
      link={`/${UserForm()}/invoices`}
      form= 'small'
      data= {
        <>
          <InvoicesSubmitModule />
        </>
      } 
    />
  </>
  )
}