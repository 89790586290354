// new docs
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";

import { toBase64, UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { GetUserTeamList } from "../../services/srvc-contacts-realm";
// import { CreateInvoiceRules } from "../../services/srvc-invoices-realm";


export default function InvoiceRulesNewModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState(false);
  const [text, setText] = useState('');

  const [list, setList] = useState();

  const [data, setData] = useState ([
    {"min": 0, "max": 25000, "auth":1, "user":""},
    {"min": 25001, "max": 50000, "auth":1, "user":""},
    {"min": 50001, "max": 9999999999, "auth":1, "user":""}
  ])

  useEffect( () => {
    if (asset){
      const fetchData = async() => {

        // contact list
        // const result = await GetUserTeamList({ 
        //   data: { sort: 'allx', actv: 'actv', usid: asset.usid },
        //   user: asset.usid
        // })
        // console.log (result.data)
        // setList (result.data)

        setLoading(false);
      }
      fetchData()
    } else {}
  },[]);

  // form validation
  useEffect( () => {
    setForm(false);
    if (data[0].min ===0)
      setForm(true)

  });


  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  const handleAuthorizer = async(name, vals, indx) => {
    console.log (name, vals, indx)
    setData(data => data.map((item, i) => (indx === i ? { ...item, ["user"]: vals } : item)));
  }

  const handleTiers = (name, vals, indx) => {
    // edit array-object value
    setData(data => data.map((item, i) => (indx === i ? { ...item, [name]: vals } : item)));
    setData(data => data.map((item, i) => (indx === i+1 ? { ...item, ['max']: vals-1 } : item)));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    if (form) {

      var datx = {
        usid: asset.usid,
        rule: data.map(item=> {return {
          ...item, 
          min: (item.min*1000000), 
          max: (item.max*1000000),
          user: {
            name: list.find(x => x.cnid ===item.user).name,
            emid: list.find(x => x.cnid ===item.user).emid,
            usid: item.user
          }
        }})
      } 
      console.log(datx);

      // const result = await CreateInvoiceRules ({
      //   data: datx, 
      //   user: asset.usid
      // })
      // console.log (result)
      // setLoading(false);
      // if (result.data) history.push(`/${asset.form.substring(0,2)}/invoices`)
    }
    else {
      setText('Please enter invoice information');
      setLoading(false);
    }
    
  }

  if (loading) return (<> <WebbLoader/> </>)

  return (
  <>
    <WebbDividerSmall />
    {/* info */}
    <div className="text-color-tone mb-3">
      <p>Create Authorization Rules</p>
    </div>
    <WebbDividerSmall />
    
    {/* form */}    
    <form onSubmit={handleSubmit}>
      <div className="border-bottom mb-3">

        <div className="mb-3">
          <p className="lead text-color-main mb-2">Tier 1</p>
          <div className="d-flex">
            <div className="me-auto">
              <label className="form-label small">Amount (Min) <FormNeeded/></label>
            </div>
            <div className="text-end">
              <label className="form-label small">Amount (Max) <FormNeeded/></label>
            </div>
          </div>
          
          <div className="input-group height-md bg-wite rounded-none">
            <input className="form-control height-md rounded-none" 
              value={data[0].min}
              disabled={true}
              placeholder="123456">
            </input>
            <input className="form-control height-md rounded-none text-end"
              value={data[0].max}
              disabled={true}
              placeholder="123456">
            </input>
          </div>
        </div>

        <div className="mb-3">
          <div className="form-group">
            <label className="form-label small">Select Team Member <FormNeeded/></label>
            <select 
              className="form-select height-md bg-wite rounded-none"
              style ={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data[0].user}
              onChange={({ target }) => {
                handleAuthorizer("user", target.value, 0);
                  
              }}
            >
            <option value=''>Select Team Member</option>
              {list.map((item,i) => (
                <option key={i} value={item.cnid}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      
      </div>


      <div className="border-bottom mb-3">

        <div className="mb-3">
          <p className="lead text-color-main mb-2">Tier 2</p>
          <div className="d-flex">
            <div className="me-auto">
              <label className="form-label small">Amount (Min) <FormNeeded/></label>
            </div>
            <div className="text-end">
              <label className="form-label small">Amount (Max) <FormNeeded/></label>
            </div>
          </div>
          
          <div className="input-group height-md bg-wite rounded-none">
            <input className="form-control height-md rounded-none" 
              value={data[1].min}
              onChange={({ target }) =>  handleTiers("min", target.value, 1)}
              placeholder="123456">
            </input>
            <input className="form-control height-md rounded-none text-end"
              value={data[1].max}
              disabled={true}
              placeholder="123456">
            </input>
          </div>
        </div>

        <div className="mb-3">
          <div className="form-group">
            <label className="form-label small">Select Team Member <FormNeeded/></label>
            <select 
              className="form-select height-md bg-wite rounded-none"
              style ={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data[1].user}
              onChange={({ target }) => {
                handleAuthorizer("user", target.value, 1);
                  
              }}
            >
            <option value=''>Select Team Member</option>
              {list.map((item,i) => (
                <option key={i} value={item.cnid}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      
      </div>

      <div className="border-bottom mb-3">

        <div className="mb-3">
          <p className="lead text-color-main mb-2">Tier 3</p>
          <div className="d-flex">
            <div className="me-auto">
              <label className="form-label small">Amount (Min) <FormNeeded/></label>
            </div>
            <div className="text-end">
              <label className="form-label small">Amount (Max) <FormNeeded/></label>
            </div>
          </div>
          
          <div className="input-group height-md bg-wite rounded-none">
            <input className="form-control height-md rounded-none" 
              value={data[2].min}
              onChange={({ target }) =>  handleTiers("min", target.value, 2)}
              placeholder="123456">
            </input>
            <input className="form-control height-md rounded-none text-end"
              value={data[2].max}
              disabled={true}
              placeholder="123456">
            </input>
          </div>
        </div>

        <div className="mb-3">
          <div className="form-group">
            <label className="form-label small">Select Team Member <FormNeeded/></label>
            <select 
              className="form-select height-md bg-wite rounded-none"
              style ={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data[2].user}
              onChange={({ target }) => {
                handleAuthorizer("user", target.value, 2);
              }}
            >
            <option value=''>Select Team Member</option>
              {list.map((item,i) => (
                <option key={i} value={item.cnid}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      
      </div>


      {/* text */}
      <div className="mb-3">
        <p className="small text-danger">{text}<span className="text-white">{'.'}</span></p>
      </div>
      
      {/* actn */}
      <WebbDividerMedium />
      <div className="">
        <div className="d-grid">
          <button className={`btn height-md btn-primary rounded-none`}
            // disabled={!form}
          ><small>Submit</small>
          </button>
        </div>
      </div>
      

    </form> 

    <WebbDividerMedium />
    <WebbDividerMedium />
  </>
  )
}