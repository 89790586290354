//account
import { useEffect, useState } from "react";
import { useHistory  } from "react-router-dom";

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import WebbModuleInfo from "../webb/webb-module-info";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";
import ifsc from 'ifsc-finder';
import {BankAccountLink, CheckBankAccount, LinkBankAccounts} from '../../services/srvc-accounts-realm';

export default function AccountsLinkBankModule() {

  const asset = UserForm() === 'in' ? GetLocalUser() : GetLocalBusiness();
  const history = useHistory();
  
  const [loading,setLoading]=useState(false);
  const [form, setForm] = useState(false);

  const [data, setData] = useState({
    acfr:'bank',
    acnr:'',
    acbr:'',
    actv:false,
  });
  
  
  const [txtf, setTextForm] = useState('');
  const [chck, setCheck] = useState(false);
  const [errr, setError] = useState(true);
  const [bank,setBank] = useState()

  // form validation
  useEffect( () => {
    setForm(false)
    if (data.acnr !=='') 
      setForm(true);
  },[data]);

  const CheckAccount = async() => {
    setLoading(true);
    setTextForm('Please wait...');
    if (data.nmbr === '' || data.brnc === '') {
      // alert
      setError(true);
      setTextForm('Please enter account details');

    } else {

      // call API
      var res = await ifsc.get(data.acbr)
      // console.log(res)
      if(res=="Not Found!")
      { 
        setError(true);
        setTextForm("Invalid Account or IFSC")
      }
      else{
        setError(false);
        setTextForm(`Bank : ${res.BANK}, Branch : ${res.BRANCH}`)
        setBank(res.BANK)
        setCheck(true)
      }
      
      
      // if (res.data) {
      //   setError(false); setCheck(true); 
      //   setTextForm('Bank Account Verified: '+res.data.name);
      // } else {
      //   setError(true); setCheck(false); 
      //   setTextForm('Bank Account Not Verified');
      // }
    }
    setLoading(false);
  }

  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true)
    if (form==='' || data.nmbr==='' ) {
      setError(true);
      setTextForm ('Please Enter Account Details');
    } else {
      var datax ={
        data:{
          name:asset.name,
          user: asset.user,
          mail:asset.mail,
          number: data.acnr,
          ifsc: data.acbr,
          address: 'mumbai',
          mobile: asset.mobile,
          bank:bank
        },
        user: asset.user,
        webb:''
      }

      // console.log(datax)
      // api call
      const response =await BankAccountLink(datax);
      // console.log (response);
      
      if (response.status=="success") {
        history.push(`/${asset.form.substring(0,2)}/home`);
      }
      if(response.status=="failed")
      {
        setError(true);
        setTextForm(response.memo)
      }
      setLoading (false);
    }

  }

  if (loading){
    return ( <> <WebbLoader/> </> )
  }


  return (
  <>
    {/* info */}
    <WebbDividerSmall/>
    <WebbModuleInfo data={{info: 'Verify Bank Account and Link'}}/>
    <WebbDividerSmall />


    <form onSubmit={handleSubmit}>
      {/* content */}
      <div className="">

        

        <div className="form-group mb-3">  
          <label className="form-label small">Enter Bank Account Number <FormNeeded /></label>
          <input type="text" 
            className="form-control height-md bg-soft" 
            value={data.acnr}
            onChange={({ target }) => {
              handleChange("acnr", target.value);
              setError(true); setCheck(false);
              setTextForm('');
            }}
            placeholder='0123456789'>   
          </input>
        </div>

        <div className="form-group mb-3">  
          <label className="form-label small">Enter IFSC <FormNeeded /></label>
          <input type="text" 
            className="form-control height-md bg-soft" 
            value={data.acbr}
            onChange={({ target }) => {
              handleChange("acbr", target.value);
              setError(true); setCheck(false);
              setTextForm('');
            }}
            placeholder='IFSC0123456'>   
          </input>
        </div>

      </div>

      {/* text */}
      <div className="">
        <div className="mb-3">
          <small className={errr? 'text-danger' : 'text-success'}>{txtf}</small>
        </div>

      </div>
      
      {/* action */}
      <WebbDividerMedium/>
      <div className="d-grid">

        <button 
          className={`btn btn-outline-primary height-md ${chck ? 'd-none' : ''}`}
          type="button"
          disabled={!form}
          onClick={ () => CheckAccount() }
        ><small>Check</small></button>
            
        <button 
          className={`btn btn-primary back-color-main height-md ${chck ? '' : 'd-none'}`}
          type="submit"
        ><small>Link</small></button>

      </div>
    
    </form>

  </>
  )
}


