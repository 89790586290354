//main
import { useParams } from "react-router-dom";

import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import StampViewModule from "../content/stamps/stamp-view";

export default function StampView() {

  const { id } = useParams();
  
  return (
  <>
    <ContentFormatXX 
      head='Stamp Details'
      link={`/${UserForm()}/smart`}
      form= 'small'
      data= {
        <>
          
          <StampViewModule />
        </>
      } 
    />
  </>
  )
}