// action
import ActionCardSmall from "../webb/ctas-actioncard-sm";
import ActionCardMedium from "../webb/ctas-actioncard-md";

import { UserForm } from "../../services/srvc-utilities";

export default function NewContactModule() {
 
  const data = {
    name: 'Add Contact',
    text: 'Add Single Contact',
    link: `/${UserForm()}/contacts/new`,
    actv: true
  }
 
  return (
  <>
    <ActionCardMedium data={data}/>
  </>
  )
}