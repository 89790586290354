//main
import ContentFormatXX from "../content/webc/content-format-xx";

import WebbProgressBar from "../content/webb/webb-progressbar";
import WebbDividerSmall from "../content/webb/webb-divider-sm";

import OnboardBusinessDocsModule from "../content/onboard/bsnx-docs";

export default function OnboardBusinessDocs() {

  return (
  <>
    <ContentFormatXX 
      head='New Business Account'
      link={`/account/next`}
      form= 'small'
      data= {
        <>
          <WebbDividerSmall/>
          <WebbProgressBar stat={33} />
          <OnboardBusinessDocsModule />
        </>
      } 
    />
  </>
  )
}