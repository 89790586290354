//main
import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import ContactsNewTeamModule from "../content/contacts/contacts-new-tmbx";


export default function ContactsNewTeam() {

  return (
  <>
    <ContentFormatXX 
      head='New Contact - Team'
      link={`/${UserForm()}/contacts`}
      form= 'small'
      data= {
        <>
        <ContactsNewTeamModule />
        </>
      } 
    />
  </>
  )
}