//account
import { useEffect, useState } from "react";
import { useHistory  } from "react-router-dom";

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import WebbModuleInfo from "../webb/webb-module-info";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { CreateBusinessAccount } from "../../services/srvc-accounts-realm";

export default function BusinessAccountCreateModule() {

  const asset = UserForm() === 'in' ? GetLocalUser() : GetLocalBusiness();
  const history = useHistory();
  
  const [loading,setLoading]=useState(false);
  const [form, setForm] = useState(false);

  const [data, setData] = useState({
    name: asset.name,
    mail: asset.mail,
    mobile:'',
    customer_id: asset.user,
    city:'Mumbai',
    state:'Maharashtra'
  });
  
  
  const [txtf, setTextForm] = useState('');
  const [chck, setCheck] = useState(false);
  const [errr, setError] = useState(true);
  const [text,setText] = useState('')

  // form validation
  useEffect( () => {
    setForm(false)
    if (data.name !=='' && data.usid !== '') 
      setForm(true);
  },[data]);


  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async e => {
    e.preventDefault();
    if (form) {
      setLoading(true)
          //  if base account successfully created then we will hit same API for creating business
        data.type='transfer-account'
        var datx ={
          data: data,
          user: asset.user,
          webb: process.env.REACT_APP_WEBB_SITE_NMBR
        }
        console.log(datx)
        var res = await CreateBusinessAccount(datx);
        // console.log(res)
        if(res.data==false){setText(res.memo)}
        if(res.data!= false){ history.push(`/${asset.form.substring(0,2)}/home`)}

        setLoading (false);
    } 
    else { }

  }

  // console.log(data)

  if (loading){
    return ( <> <WebbLoader/> </> )
  }


  return (
  <>
    {/* info */}
    <WebbDividerSmall/>
    <WebbModuleInfo data={{info: 'Create and Link Online Account'}}/>
    <WebbDividerSmall />


    <form onSubmit={handleSubmit}>
      {/* content */}
      <div className="">

        <div className="form-group mb-3">  
          <label className="form-label small">Account Owner Name <FormNeeded /></label>
          <input disabled value={data.name} className="form-control" />
        </div>

        <div className="form-group mb-3">  
          <label className="form-label small">Account Owner Mail <FormNeeded /></label>
          <input disabled value={data.mail} className="form-control" />
        </div>

        <div className="form-group mb-3">  
          <label className="form-label small">Account Owner Id <FormNeeded /></label>
          <input disabled value={data.customer_id} className="form-control" />
        </div>

        <div className="form-group mb-3">  
          <label className="form-label small">Account Owner Mobile <FormNeeded /></label>
          <input value={data.mobile} onChange={(e)=>handleChange('mobile',e.target.value)}  className="form-control" />
        </div>

        <div className="form-group mb-3">  
          <label className="form-label small">City <FormNeeded /></label>
          <input value={data.city} onChange={(e)=>handleChange('city',e.target.value)}  className="form-control" />
        </div>

        <div className="form-group mb-3">  
          <label className="form-label small">State <FormNeeded /></label>
          <input value={data.state} onChange={(e)=>handleChange('state',e.target.value)}  className="form-control" />
        </div>

      </div>

      {/* text */}
      <div className="">
        <div className="mb-3">
          <small className={errr? 'text-danger' : 'text-success'}>{txtf}</small>
        </div>

      </div>

      <div className="">
        <div className="mb-3">
          <small className={'text-danger' }>{text}</small>
        </div>

      </div>
      
      {/* action */}
      <WebbDividerMedium/>
      <div className="d-grid">
            
        <button 
          className={`btn btn-primary back-color-main height-md`}
          type="submit"
          disabled={data.mobile.length!=10}
        ><small>Create & Link</small></button>

      </div>
    
    </form>

  </>
  )
}


