// transfers
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import WebbModuleInfo from "../webb/webb-module-info";
import HeaderSectionDark from "../webb/head-section-dark";

import InvoiceOverview from "../webb/invc-overview";
import InvoiceActionx from "../webb/invx-actions";
import InvoiceMembers from "../webb/invc-members";
import InvoiceCharges from "../webb/invc-charges";
import InvoiceAuthorizers from "../webb/invc-authorizers";
import InvoiceActions from "../webb/invc-actions";

import InvoiceFiles from "../webb/invc-files";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { InvoicesDetail, GetInvoiceFiles } from "../../services/srvc-invoices-realm";

import { AccountBalance, BusinessAccountBalance } from "../../services/srvc-accounts-realm";

export default function InvoicesViewModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  
  const [acbl, setBalance] = useState(0);

  const [data, setData] = useState({});
  const [file, setFile] = useState([]);

  const [cred, setCred] = useState({});
  const [debt, setDebt] = useState({});

  useEffect( () => {
    if (asset){
      const fetchData = async() => {

        // info
        const result = await InvoicesDetail({
          data: {item: id, user: asset.user}, 
          user: asset.user
        })
        // console.log (result.data)
        setData(result.data);
        // members
        setCred(result.data.cred)
        setDebt(result.data.debt)
        setFile([{link:result.data.file, name:result.data.name, created:result.data.created,number:result.data.number}])

        // const files = await GetInvoiceFiles({
        //   data: {item: id, user: asset.user}, 
        //   user: asset.user
        // })
        // setFile(files.data);

        const balance = (await BusinessAccountBalance({ 
          data: { user: asset.user, type: "base-account" },
          user: asset.user
        }))
        // console.log(balance)
        setBalance(balance.data.balance.number)
        // console.log({chck: parseFloat(balance.data.balance.number) > parseFloat(result.data.amount.number)})
        
        setData(data => {return {...data, chck: parseFloat(balance.data.balance.number) >= parseFloat(result.data.amount.number) }})

        setLoading(false);
      }
      fetchData()
    } else {}
  },[]);

  if (loading){ return ( <> <WebbLoader /> </> ) }

  return (
  <>

    <InvoiceOverview data={data} />
    <WebbDividerSmall />

    <InvoiceActionx data={data}/>
    <WebbDividerMedium />

    <HeaderSectionDark data={{name: 'Parties'}}/>
    <InvoiceMembers data={{cred: cred, debt: debt}} />
    <WebbDividerMedium />

    {/* <div className={`${data.mmbr.length===0} ? 'd-none' : ''`}>
      <HeaderSectionDark data={{name: 'Authorization'}}/>
      <InvoiceAuthorizers data={data}/>
      <WebbDividerMedium />
    </div> */}

    <HeaderSectionDark data={{name: 'Rates'}}/>
    <InvoiceCharges data={data} />
    <WebbDividerMedium />

    <HeaderSectionDark data={{name: 'Files'}}/>
    <InvoiceFiles data={file} />

    <WebbDividerMedium />
    <WebbDividerMedium />
  </>
  )
}