// action
import ActionCardSmall from "../webb/ctas-actioncard-sm";

import { UserForm } from "../../services/srvc-utilities";

export default function InvoicesDownloadModule() {

  const data = {
    name: 'Download Invoices',
    link: `/${UserForm()}/invoices/statement`
  }

  return (
  <>
    <ActionCardSmall data={data}/>
  </>
  )
}