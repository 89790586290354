// init
import axios from "axios";
const base = "https://ap-south-1.aws.data.mongodb-api.com/app/contrato-market-obaek/endpoint"

var baseApi = "https://srvx.contrato.cc/xx"

// Function Calls

  export const TemplateLists = async (item) => {

    const basx = baseApi + '/template.all';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }
  
  }

  export const TemplateBuy = async (item) => {

    const basx = baseApi + '/subscription.template.buy';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }
  
  }

  export const UserTemplate = async (item) => {

    const basx = baseApi + '/subscription.template.get';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }
  
  }

  export const PackageLists = async (item) => {

    const basx = baseApi + '/package.all';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }
  
  }



  export const PackageBuy = async (item) => {

    const basx = baseApi + '/subscription.package.buy';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }
  
  }

  export const UserPackages = async (item) => {

    const basx = baseApi + '/subscription.package.get';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }
  
  }

  export const GetTemplateFields = async (item) => {

    const basx = baseApi + '/get.template.fields';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }
  
  }


