//main
import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import TransfersAccountDebitModule from "../content/transfers/account-debit";

export default function OrdersAccountDebit() {

  return (
  <>
    <ContentFormatXX 
      head='Account Debit'
      link={`/${UserForm()}/home`}
      form= 'small'
      data= {
        <>
        <TransfersAccountDebitModule />
        </>
      } 
    />
  </>
  )
}