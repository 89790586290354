//main
import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import DocumentsViewModule from "../content/documents/documents-view";
import { useParams } from "react-router-dom";

export default function DocumentsView() {

  const smid = useParams().smid
  // console.log(smid)

  return (
  <>
    <ContentFormatXX 
      head='View Document'
      link={`/${UserForm()}/smart/view/${smid}`}
      form= 'medium'
      data= {
        <>
          <DocumentsViewModule />
        </>
      } 
    />
  </>
  )
}