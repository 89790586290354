//main
import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import ContactsViewModule from "../content/contacts/contacts-view";

export default function ContactsView() {

  return (
  <>
    <ContentFormatXX 
      head='Contact Details'
      link={`/${UserForm()}/contacts`}
      form= 'medium'
      data= {
        <>
          <ContactsViewModule />
        </>
      } 
    />
  </>
  )
}