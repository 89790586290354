//main
import { useParams } from "react-router-dom";

import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import SmartsSendModule from "../content/smart/smart-send";

export default function SmartEsinSend() {

  const { id } = useParams();

  return (
  <>
    <ContentFormatXX 
      head='Document Signatures'
      link={`/${UserForm()}/smart/view/${id}`}
      form= 'small'
      data= {
        <>
          <SmartsSendModule />
        </>
      } 
    />
  </>
  )
}