//main
import { useEffect, useState } from "react";
import ContentFormatXX from "../content/webc/content-format-xx";
import { UserTemplate } from "../services/srvc-template-realm";
import { useHistory } from "react-router-dom";
import { UserForm } from "../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../services/srvc-auth-user";
import WebbLoader from "../content/webb/webb-loader";
import ListNoData from "../content/webb/list-nodata";
import MyTemplatesView from "../content/marketplaces/my-templates";


export default function MyTemplates() {

const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
const history = useHistory();

const [loading, setLoading] = useState(true)
const [data, setData] = useState([])

useEffect(()=>{

    const fetchdata = async()=>{
        var res = (await UserTemplate({data:{user:asset.user}, user: asset.user  })).data
        console.log(res)
        if(res!= false){setData(res)}
        else setData([])
        setLoading(false)
    }
    fetchdata()

},[])

  return (
  <>
    <ContentFormatXX 
      head='My Templates'
      link={`/in/marketplace`}
      form= 'medium'
      data= {
        <>
            <MyTemplatesView data={data} />
        </>
      } 
    />
  </>
  )
}