// documents
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import HeaderSectionDark from "../webb/head-section-dark";

import DocumentOverview from "../webb/docx-overview";
import DocumentDates from "../webb/docx-dates";
import DocumentActions from "../webb/docx-actions";
import DocumentMembers from "../webb/docx-members";
import DocumentFiles from "../webb/docx-files";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { GetDocumentInfo, GetDocumentFiles } from "../../services/srvc-smart-realm";
import {DocumentCancel} from "../../services/srvc-smart-realm";
import { SendEsinReminderAlert } from "../../services/srvc-alerts-realm";


export default function DocumentsViewModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const {smid, id } = useParams();
  const history = useHistory()

  const [loading, setLoading] = useState(true);

  const [info, setInfo] = useState({});
  const [mmbr, setMembers] = useState([]);
  const [file, setFiles] = useState([]);
  const [active,setActive] =useState(true)
  const [admin,setAdmin] = useState()

  const [reminder, setReminder] = useState(false)


  useEffect( () => {
    if (asset){
      const fetchData = async() => {

        const datx = {
          data: { document: id },
          user: asset.user,
          webb: process.env.REACT_APP_WEBB_SITE_NMBR
        }

        // info + files
        var res = (await GetDocumentInfo(JSON.stringify(datx))).data
        // console.log(res)
        var admin = res.user.filter(item=>{
          return item.role=="admin"
        }) 
        // console.log(admin)
        setAdmin(admin)
        setInfo(res);

        // files
        const files =(await GetDocumentFiles(JSON.stringify(datx))).data
        setFiles(files);
        
        // members
        var mmbx = res.members
        setMembers(mmbx)

        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset.usid, active]);

  const DoCancelDocument = async() => {
    var res=(await DocumentCancel(JSON.stringify({data:{document:id}}))).data
    // console.log(res)
    setActive(false)
  }

const SendReminderAlert=async()=>{
  setReminder(true)
  const datx = {
    data: { document: id },
    user: asset.user,
    webb: process.env.REACT_APP_WEBB_SITE_NMBR
  }
  
  var res = (await SendEsinReminderAlert(JSON.stringify(datx))).data
  // console.log(res)
  if(res!= false)
  {
    setReminder(false)
    window.alert(`E-signature reminder has been sent to ${res.name} on email ${res.mail}`)
  }
  
}

  if (loading){ return ( <> <WebbLoader /> </> ) }

  return (
  <>
    <WebbDividerSmall />
    <DocumentOverview data={info}/>

    {/* action */}
    <WebbDividerMedium />
    <div className="d-flex" >

    <div className={info.status=="2" && admin[0].usid== asset.user ? 'w-25': 'd-none'}>
        <button onClick={()=>SendReminderAlert()} className="btn btn-primary back-color-wite text-color-tone border-none"><small>Reminder</small></button>
      </div>

      <div className={info.status=="6" && admin[0].usid== asset.user  || info.status=="11" && admin[0].usid== asset.user ? 'd-none': 'w-25'}>
        <button onClick={()=>DoCancelDocument()} className="btn btn-primary back-color-wite text-color-tone border-none"><small>Cancel</small></button>
        
      </div>

      <div className={` ${reminder?'':'d-none'} spinner-border text-primary" role="status"`}>
        <span className="visually-hidden">Loading...</span>
      </div>


    </div>

    {/* members */}
    <WebbDividerMedium />
    <HeaderSectionDark data={{name: 'Parties'}}/>
    <DocumentMembers docx = {info} data={mmbr}/>

    {/* files */}
    <WebbDividerMedium />
    <HeaderSectionDark data={{name: 'Files'}}/>
    <DocumentFiles status = {info.files.length} data={file}/>
    

    <WebbDividerMedium />
    <WebbDividerMedium />
    <WebbDividerMedium />

  </>
  )
}