// contacts

export default function ContactOverview(props) {

  const data = props.data;
  //console.log(data)
  
  return (
  <>
    <div className="border bg-white rounded">

      <div className="d-flex p-3">
        <div className="me-auto">
          <h1 className="lead fw-bold text-color-main">{data.info.name}</h1>
          <p className="m-0 p-0 d-none">{data.info.mmid}</p>
          {/* <p className="m-0 p-0">{data.locs.loct}{', '}{data.locs.locn}</p> */}
        </div>
        
        <div className="text-end">
          <div className="text-tint bg-lite rounded-circle text-center"  style={{fontSize:'2rem', width:'3.3rem', height:'3.3rem' }}>
            <p className="m-0 p-0">
              {data.sort === "bsnx" 
                ? <i className="bi-briefcase"></i> 
                : <i className="bi-person"></i>  
              }
            </p>
          </div>

        </div>
      </div>

    </div>

    
  </>
  )
}