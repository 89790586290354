//main
import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import InvoicesNewModule from "../content/invoices/invoices-new";

export default function InvoicesNew() {

  return (
  <>
    <ContentFormatXX 
      head='New Invoice'
      link={`/${UserForm()}/invoices`}
      form= 'small'
      data= {
        <>
          <InvoicesNewModule />
        </>
      } 
    />
  </>
  )
}