// documents
import { Link, useHistory, useParams } from "react-router-dom";

import WebbDividerMedium from "./webb-divider-md";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

export default function SmartFiles(props) {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const { id } = useParams();
  
  const data = props.data
  const status = props.status
  const smartid = props.smartid

  var base64=data.file

  const history = useHistory()

  function DownloadDocx(data) {
    const linkSource = `data:application/pdf;base64,${data.file}`;
    const downloadLink = document.createElement("a");
    const fileName = data.nmbr;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const handleClick = (item) => {
    history.push(`/${asset.form.substring(0,2)}/documents/view/${smartid}/${item}`)
  }

  if (data.length==0) 
  return (
  <> 
    <p className="text-color-tone m-0">No Documents</p>
    <WebbDividerMedium/>
  </>
  )

  return (
  <>
    <div className={`rounded border border-light back-color-wite mb-3 cursor`} >
    {data && data.length > 0 && data.map((item, i) => (
      <div key={i}>
      <div className="d-flex p-3 hilite" onClick={()=>handleClick(item.enid)}>
        <div className="me-auto">
          <p className="fw-bold m-0 p-0 text-sm">{item.meta.name}</p>
          <p className="small m-0 p-0 text-tone text-sm">{'ID: '}{item.enid}</p>
          <p className="small m-0 p-0 text-tone text-sm">{'Created: '}{(new Date(item.crts)).toLocaleString()}</p>
        </div>

        <div className={`text-end py-3`}>
          <i className="bi bi-chevron-right text-color-tone"></i>
        </div>
      </div>
      
      <div className={`border-bottom border-light ${i < data.length-1 ? '' :'d-none'}`}></div>
      </div>
    ))}
    </div>

  </>
  )
}