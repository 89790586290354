// invoices
import { numc } from "../../services/srvc-utilities";


export default function InvoiceCharges(props) {
  const data = props.data;

  return (
  <>
    <div className={`rounded-wd back-color-wite`}>

      <div className="d-flex p-3">
        <div className="me-auto">
          <p className="">Amount</p>
          <p className="">Discounts</p>
          <p className="m-0 p-0">Tax</p>
        </div>
        <div className="text-end">
          <p className="">{numc(data.amount.number, 'ww')}</p>
          <p className="">{numc(0, 'ww')}</p>
          <p className="m-0 p-0">{numc(0, 'ww')}</p>
        </div>
      </div>

      <div className="border-bottom"></div>
      
      <div className="d-flex p-3">
        <div className="me-auto">
          <p className="fw-bold m-0 p-0">Total</p>
        </div>
        <div className="text-end">
          <p className="fw-bold m-0 p-0">
            {numc(data.amount.number, 'ww')}
          </p>
        </div>
      </div>

      </div>

  </>
  )
}