// documents
import { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";

import DocumentBasicInfo from "../webb/docx-basicinfo";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { GetDocumentInfo } from "../../services/srvc-smart-realm";
import { SetDocumentStamp } from "../../services/srvc-smart-realm";
import { RequestStampPaper } from "../../services/srvc-estm-realm";
import { AccountBalance, BaseAccountBalance } from "../../services/srvc-accounts-realm";

// const list = require('../../data/data-states-list.json').data;
const list = [
  { "name": "Delhi", "code":"DL" },
  { "name": "Karnataka", "code":"KA" },
  { "name": "Maharashtra", "code":"MH" },
]
const estmcodes = require('../../data/list-estm-codes.json').data.filter(item=>item.isActive==1)


export default function StampRequestModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const { id } = useParams();
  const history = useHistory();

  const [loading,setLoading]=useState(true);

  const [form, setForm] = useState(false);
  const [text, setText] = useState('');
  
  const [code, setCodes] = useState();

  const [production,setProduction] = useState(false)

  const [balance, setBalance] = useState()
  const [acNumber, setAcNumber] = useState()

  const [info, setInfo] = useState();
  const [data, setData] = useState({    
    name: '',
    code: '',
    lost: '',
    amts: '',
    amtc: '',
    frst: '',
    scnd: '',
    memo: ''
  })

  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        
        // basic info
        // const result = await GetDocumentInfo(JSON.stringify({
        //   data: { dcid: id },
        //   user: asset.usid,
        //   webb: process.env.REACT_APP_WEBB_SITE_NMBR
        // }));

        // setInfo(result.data)
        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset.usid]);

  useEffect( () => {
    if (data.lost!==""){
      // setCodes(estmcodes.filter(x => x.stateCode === data.lost));
      if (data.lost==="DL") handleChange("code", "DL1051");
      if (data.lost==="KA") handleChange("code", "KA1005");
      if (data.lost==="MH") handleChange("code", "MH1001");
    } else {
      setCodes([])
    }
  },[data.lost]);

  // form validation
  useEffect( () => {
    setForm(false);
    if (data.amts !=='' && !isNaN(data.amts) && parseFloat(data.amts) !==0
      && data.amtc !=='' && !isNaN(data.amtc) && parseFloat(data.amtc) !==0
      && data.lost !=='' && data.frst !=="" && data.scnd !=="" && data.frst !== data.scnd
      && data.memo !==""
    ) setForm(true)
  });

  useEffect(()=>{
    const fetchdata= async()=>{
      const result = await BaseAccountBalance({
        data: {user: asset.user, type:"base-account"}, 
        user: asset.user,
      })
      // console.log(result)
      var balance = parseFloat(result.data.balance.number)/1000000
      // console.log(balance)
      setBalance(balance)
      setAcNumber(result.data.account.number)
    }
    fetchdata()
  },[asset.user])

  const handleChange = async(key, val) => {
    setForm(false);
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if(balance>100)
    {
      const datx = { 
        ...data,
        name: data.memo,
        memo: data.memo,
        prod: production ,
        dcid: asset.user,
        
        frst: {"name":data.frst},
        scnd: {"name":data.scnd},
        form: "blank",
        file: "https://mag.wcoomd.org/uploads/2018/05/blank.pdf",
        
        cust: { name: asset.name, mail: asset.mail, usid: asset.user },
        user: asset.user,
        account: acNumber
        // name: estmcodes.find(x=> x.articleCode === data.code).name 
      }
  
      // console.log(datx)
  
      // api call
      const result = await RequestStampPaper (JSON.stringify({
        data: datx,
        user: asset.user
      }))
      // console.log (result);
      
      if (result.data) {
        history.push(`/${asset.form.substring(0,2)}/smart`)
      }
      else setText('Error. Please try again')
    }
    else{
      setText("Error. Insufficient Balance, Please Recharge Your Subscription Account")
    }
    
    setLoading(false);
  }

  if (loading) { return ( <> <WebbLoader /> </> ) }

//   if (info.estm.stat === '1') return (
//     <>
//       <WebbDividerSmall />
//       {/* <DocumentBasicInfo data={info}/> */}
//       <WebbDividerSmall />
//       <div className="p-3 bg-white text-center">
//         <WebbDividerMedium />
//         <i className="bi bi-clock caption-md text-color-tone"></i>
//         <p className="m-0">E-Stamping In Progress</p>
//         <WebbDividerMedium />
//       </div>

//     </>
//   )

//   if (info.estm.stat === '6') return (
//     <>
//       <WebbDividerSmall />
//       {/* <DocumentBasicInfo data={info}/> */}
//       <WebbDividerSmall />
//       <div className="p-3 bg-white">
//         <WebbDividerMedium />
//         <p className="m-0">E-Stamping Completed</p>
//         <p className="m-0">Certificate Number: {info.estm.nmbr || '******'}</p>
//         <p className="">Document Reference: {info.estm.refx || '******'}</p>
//         <a href={info.estm.link} target="_blank">Document Link (Download)</a>
//         <WebbDividerMedium />
//       </div>

//     </>
//   )


  return (
  <>
    <WebbDividerSmall />
      {/* <DocumentBasicInfo data={info}/> */}
    <WebbDividerSmall />
    
    {/* info */}
    <div className="mb-3">
      <p className="text-tone">Please enter E-Stamp details</p>
    </div>
    <WebbDividerSmall />

    {/* data */}
    <form onSubmit={handleSubmit}>

      <div className="">

        <div className='mb-3'>
          <div className="form-group">
            <label className="form-label small">State <FormNeeded/></label>
            <select 
              className="form-select height-md rounded-none"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.lost}
              onChange={({ target }) => handleChange("lost", target.value)}
            >
              <option value=''>Select State</option>
              {list.map((item,i) => (
                <option key={i} value={item.code}>{item.name}</option>
              ))}
            </select>
          </div>
        </div>

        <div className='mb-3'>
          <div className="form-group">
            <label className="form-label small">Stamp Paper Value <FormNeeded/></label>
            <input type="text" className="form-control rounded-none"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.amts}
              onChange={({ target }) => {handleChange("amts", target.value);}}
              placeholder="">
            </input>
          </div>
        </div>

        <div className='mb-3'>
          <div className="form-group">
            <label className="form-label small">Consideration (Agreement) Amount <FormNeeded/></label>
            <input type="text" className="form-control rounded-none"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.amtc}
              onChange={({ target }) => {handleChange("amtc", target.value);}}
              placeholder="">
            </input>
          </div>
        </div>


        <div className='mb-3'>
          <div className="form-group">
            <label className="form-label small">Purpose <FormNeeded/></label>
            <input style={{fontSize:'0.9rem', height:'2.7rem'}} value={data.memo} 
              onChange={({ target }) => handleChange("memo", target.value)} 
              className={`form-control rounded-none`}  />

            {/* <select 
              className="form-select height-md rounded-none"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              disabled = {data.lost === ""}
              value={data.code}
              onChange={({ target }) => handleChange("code", target.value)}
            >
              <option value=''>Select Reason</option>
              {code.map((item,i) => (
                <option key={i} value={item.articleCode}>{item.name}</option>
              ))}
            </select> */}
          </div>
        </div>

        <div className='mb-3'>
          <div className="form-group">
            <label className="form-label small">First Party <FormNeeded/></label>
            <input type="text" className="form-control rounded-none"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.frst}
              onChange={({ target }) => {handleChange("frst", target.value);}}
              placeholder="First Party Name">
            </input>
          </div>
          <div className="mt-2">            
            <span className="text-color-tone back-color-tint border-none rounded p-2 px-3 small" 
              style={{cursor:'pointer'}}
              onClick = { () =>  {handleChange("frst", asset.name);}}
            >
              SET MYSELF
            </span>
          
          </div>
        </div>

        <div className='mb-3'>
          <div className="form-group">
            <label className="form-label small">Second Party <FormNeeded/></label>
            <input type="text" className="form-control rounded-none"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.scnd}
              onChange={({ target }) => {handleChange("scnd", target.value);}}
              placeholder="Second Party Name">
            </input>
          </div>
          <div className="mt-2">            
            <span className="text-color-tone back-color-tint border-none rounded p-2 px-3 small" 
              style={{cursor:'pointer'}}
              onClick = { () =>  {handleChange("scnd", asset.name);}}
            >
              SET MYSELF
            </span>
          
          </div>
        </div>   

        <div className="d-none">
          <label className="small mt-3">Production Stamp Paper</label>
          <input className="mx-3" value={production} 
            onChange={()=>setProduction(!production)} 
            type={'checkbox'}>
          </input>     

        </div>


      </div>


      <div className="mb-3">
        <p className="small text-danger">{text}<span className="text-white">{'.'}</span></p>
      </div>


      <div className="">
        <div className="d-grid">
          <button className={`btn height-md btn-primary back-color-main rounded-none`}
            disabled={!form}
          ><small>Submit</small>
          </button>
        </div>
      </div>

    </form>

    <WebbDividerMedium />
    <WebbDividerMedium />
    {/* info */}
    <div className="text-color-tone">
      <p className="text-bold">
        Notes: E-Stamp Paper Purchase
      </p>
      <p className="">
        1. Once Subitted, Purchase order will be placed with stamp vendor. 
        Cancellations and Refunds are not permissible.
      </p>
      <p className="">
        2. The E-Stamp Paper Number will be updated in your Vault documents section. 
        Incase stamp paper is out of stock, the same will be updated as soon as 
        provided by the stamp vendor.
      </p>
      <p className="">
        3. The cost of the stamp paper and handling charges will be deducted from
        your account balance. Please ensure enough balance before placing the order.
      </p>
    </div>

    <WebbDividerMedium />
    <WebbDividerMedium />
    <WebbDividerMedium />
    
  </>
  )
}