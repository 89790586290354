//account
import { useEffect, useState } from "react";
import { useHistory  } from "react-router-dom";

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import WebbModuleInfo from "../webb/webb-module-info";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import {CheckUPIAccount, LinkBankAccounts} from '../../services/srvc-accounts-realm';


export default function AccountsLinkUPIModule() {

  const asset = UserForm() === 'in' ? GetLocalUser() : GetLocalBusiness();
  const history = useHistory();
  
  const [loading,setLoading]=useState(false);
  const [form, setForm] = useState(false);

  const [data, setData] = useState({
    acfr:'upia',
    acnr:'',
    acbr:'',
    actv:false,
  });
  
  const [txtf, setTextForm] = useState('');
  const [chck, setCheck] = useState(false);
  const [errr, setError] = useState(true);

  // form validation
  useEffect( () => {
    setForm(false)
    if (data.acnr !=='') 
      setForm(true);
  },[data]);


  const CheckAccount = async() => {
    setLoading(true);
    setTextForm('Please wait...');
    if (data.acnr === '') {
      // alert
      setError(true);
      setTextForm('Please enter account details');

    } else {
      // call API
      var res = await CheckUPIAccount(JSON.stringify({
        body: { acnr:data.acnr, acbr:data.acbr },
        user: { usid: asset.usid, form: asset.form }
      }));
      
      if (res.data) {
        setError(false); setCheck(true); 
        setTextForm('UPI Account Verified: '+res.data.name);
      } else {
        setError(true); setCheck(false); 
        setTextForm('UPI Account Not Verified');
      }
    }
    setLoading(false);
  }

  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async e => {
    e.preventDefault();
    if (form) {
      var datx ={
        body: {
          acfr: data.acfr,
          acnr: data.acnr,
          acbr: (data.acnr).split('@')[1] === 'upi' ? 'bhim' : (data.acnr).split('@')[1],
          acsr: (data.acnr).split('@')[1] === 'upi' ? 'bhim' : (data.acnr).split('@')[1],
          acch: data.acfr,
          usid: asset.usid, form: asset.form
        },
        user: { usid: asset.usid, form: asset.form }
      }

      console.log (JSON.stringify(datx));
      // api call
      const response = LinkBankAccounts (JSON.stringify(datx));
      console.log (response);
      
      if (response) {
        //history.push(`/${asset.form}/accounts`);
      }
      setLoading (false);
    }
    else setTextForm ('Please Enter Account Details');

  }

  if (loading) return ( <> <WebbLoader/> </> )


  return (
  <>
    {/* info */}
    <WebbDividerSmall/>
    <WebbModuleInfo data={{info: 'Verify UPI address and Link'}}/>
    <WebbDividerSmall />

    <form onSubmit={handleSubmit}>
      {/* content */}
      <div className="">

        <div className="form-group mb-3">  
          <label className="form-label small">Enter UPI Address <FormNeeded /></label>
          <input type="text" 
            className="form-control height-md bg-soft" 
            value={data.acnr}
            onChange={({ target }) => {
              handleChange("acnr", target.value);
              setError(true); setCheck(false);
              setTextForm('');
            }}
            placeholder='username@upi'>   
          </input>
        </div>
      </div>

      {/* text */}
      <div className="">
        <div className="mb-3">
          <small className={errr? 'text-danger' : 'text-success'}>{txtf}</small>
        </div>

      </div>
      
      {/* action */}
      <WebbDividerMedium/>
      <div className="d-grid">

        <button 
          className={`btn btn-outline-primary height-md ${chck ? 'd-none' : ''}`}
          type="button"
          disabled={!form}
          onClick={ () => CheckAccount() }
        ><small>Check</small></button>
            
        <button 
          className={`btn btn-primary back-color-main height-md ${chck ? '' : 'd-none'}`}
          type="submit"
        ><small>Link</small></button>

      </div>
    
      
    </form>

    <WebbDividerMedium/>
    <WebbDividerMedium/>
  </>
  )
}


