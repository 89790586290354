// transfers
import { useEffect, useState } from "react";
import { Link, useParams,useHistory } from 'react-router-dom';

import IconSelectCard from "../webb/ctas-iconselectcard";
import WebbLoader from "../webb/webb-loader";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import HeaderSectionLite from "../webb/head-section-lite";

import DocumentBasicInfo from "../webb/docx-basicinfo";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { GetDocumentInfo, SmartGetInfo, SmartSendEsin } from "../../services/srvc-smart-realm";
import { SendDocumentEsin } from "../../services/srvc-smart-realm";

const list = require('../../data/optn-esignature.json').data.filter(item=>item.actv);


export default function SmartsSendModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const { id } = useParams();
  const history = useHistory()

  const lstx = (list.filter(item => item.user.includes(asset.form)));
  const sort = [...new Set(lstx.map(item=>item.sort))];

  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState(false);
  
  const [info, setInfo] = useState({});
  const [data, setData] = useState({code:''});

  useEffect( () => {
    if (asset){
      const fetchData = async() => {

        // basic info
        const datx = {
          data: { smart: id },
          user: asset.user,
        }

        var res = (await SmartGetInfo(JSON.stringify(datx))).data
       setInfo(res)

        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset.usid]);


  // form validation
  useEffect( () => {
    setForm(false)
    if (data.code !== '' ) setForm(true);
  },);


  const handleSelect = async(sort) => {
    handleChange('code', sort);
    console.log('123')
    console.log('item: ', sort);
  }


  const handleChange = async(key, val) => {
    setForm(false);
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // console.log (data.code)
    // api call
    if (data.code==='adhr') {
      // aadhaar otp e-signature
      const res = await SmartSendEsin (JSON.stringify({
        data: { smart: id, mode: 'adhr' },
        user: asset.usid,
        webb: process.env.REACT_APP_WEBB_SITE_NMBR
      }))

      if (res) {
        console.log (res);
        history.push(`/${asset.form.substring(0,2)}/smart/view/${id}`)
      }
    }
    
    if (data.code==='mmid') {
      // mobile otp e-signature
      const res = await SmartSendEsin (JSON.stringify({
        body: { smart: id, mode: 'mmid' },
        user: asset.usid,
        webb: process.env.REACT_APP_WEBB_SITE_NMBR
      }))

      if (res) {
        console.log (res);
        history.push(`/${asset.form.substring(0,2)}/smart/view/${id}`)
      }

    }

    if (data.code==='emid') {
      // email
      const res = await SmartSendEsin (JSON.stringify({
        data: { smart: id, mode: 'emid' },
        user: asset.usid,
        webb: process.env.REACT_APP_WEBB_SITE_NMBR
      }))

      if (res) {
        // console.log (res);
        history.push(`/${asset.form.substring(0,2)}/smart/view/${id}`)
      }

    }
   
  }


  if (loading){ return ( <> <WebbLoader /> </> ) }

  return (
  <>
    {/* info */}
    <WebbDividerSmall />
    <p className="text-lead text-bold m-0">{info.name}</p>
    <p className="text-color-tone">{id}</p>
    <WebbDividerSmall />
    
    <p className="">Please select an option</p>
    <WebbDividerSmall />

    {/* data */}
    <div className="">
      {list && list.length > 0 && list.map ((item, i) => (
        <div className={`p-3 back-color-wite hitone ${i<list.length-1 ? 'border-bottom' : ''}`} key={i}>
            <div className="d-flex cursor" onClick={() => {handleChange('code', item.code)}}>
              <div className="me-auto">
                <p className="m-0 fw-bold">{item.name}</p>
                <p className="m-0">{item.text}</p>
              </div>
              <div className="text-end py-1 mt-1">  
                <i className={item.code === data.code 
                  ? 'lead bi-check-circle-fill text-color-success' 
                  : 'lead bi-x-circle-fill text-color-tint'}>
                </i>
              </div> 
          </div>
        </div>
      ))}
    </div>

    
    {/* form */}
    <WebbDividerMedium />
    <form onSubmit={handleSubmit}>

      <div className="">
        <div className="d-grid">
          <button className={`btn height-md btn-primary back-color-main rounded-none`}
            disabled={!form}
          ><small>Save & Continue</small>
          </button>
        </div>
      </div>

    </form>


    <WebbDividerMedium />
    <WebbDividerMedium />

  </>
  )
}