// create new transfer - debit / send
import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import WebbModuleInfo from "../webb/webb-module-info";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { GetUserContactsList } from "../../services/srvc-contacts-realm";
import {  BaseAccountBalance } from "../../services/srvc-accounts-realm";
import { TransfersCredit } from "../../services/srvc-transfers-realm";

const code = require('../../data/data-transfer-reasons.json').data;

export default function TransfersCreditModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const history = useHistory();

  const [loading,setLoading]=useState(true);
  const [list, setList] = useState([]);

  const [form, setForm] = useState(false);
  const [text, setText] = useState('');

  const [data, setData] = useState({
    debt: '', 
    dbac: '', 
    cred: asset.usid, 
    crac: '',
    amnt: '', resn: '', memo: '', 
    schd: (new Date()).toISOString().substr(0,10)
  });
  
  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        // get base account + account balance
        
        const acnt = (await BaseAccountBalance({ 
          data: { usid: asset.usid, type:'base-account' },
          user: asset.usid,
        })).data;
        console.log(acnt)
        handleChange('dbac', acnt.enid)
        

        // contact list
        const conx = (await GetUserContactsList({ 
          data: { sort: 'allx', actv: 'actv', usid: asset.usid },
          user: asset.usid
        })).data
        console.log (conx)
        setList (conx)
        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset.usid]);

  // form validation
  useEffect( () => {
    setForm(false);
    if (data.amnt !=='' && !isNaN(data.amnt) && parseFloat(data.amnt) !==0
        && data.resn !=='' && data.schd !=='' && data.rcvr !==''
      )
      setForm(true)
  });

  const handleChange = async(key, val) => {
    setForm(false);
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form) {
      setLoading (true);
      var datx = {
        cred:{
          acid: data.crac,
          usid: asset.usid,
          name: asset.name,
          emid: asset.emid
        },
        debt:{
          acid: '',
          usid: data.debt, 
          form: list.find(item=>item.cnid === data.debt).form,
          name: list.find(item=>item.cnid === data.debt).name,
          emid: list.find(item => item.cnid === data.cred).emid,
        },
        amnt: (data.amnt*1000000).toString(),
        resn: data.resn,
        cmnt: data.memo,
        schd: data.schd
      }
      console.log(datx);
      const response = await TransfersCredit (JSON.stringify(
        {
          data: datx,
          user: asset.usid,
          webb: process.env.REACT_APP_WEBB_SITE_NMBR
        }));
      //console.log (response)
      setLoading(false);
      if (response) {
        history.push(`/${asset.form.substring(0,2)}/transfers/submit`);
      }
    }
    else {
      setText('Please enter transfer information');
      setLoading(false);
    }
    
  }

  if (loading){ return ( <> <WebbLoader /> </> ) }
  return ( <> </> )
  
  if (list.length===0) return (
    <>
      <WebbDividerMedium />
      <div className="p-3 bg-white text-center">
        <WebbDividerMedium />
        <p className="m-0">Please add Contacts via Dashboard</p>
        <WebbDividerMedium />
      </div>
    
    </>
  )
  
  return (
  <>
    {console.log(list)}
    {/* info */}
    <WebbDividerSmall/>
    <WebbModuleInfo data={{info: 'Enter Transfer Details and Submit'}}/>

    {/* input */}
    <form onSubmit={handleSubmit}>
      <div className="">

        <div className="form-group mb-3">
          <label className="form-label small">Select Contact <FormNeeded/></label>
          <select 
            className="form-select height-md bg-wite rounded-none"
            style ={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.debt}
            onChange={({ target }) => {handleChange("debt", target.value);}}
          >
          <option value=''>Select Contact</option>
          {list.map((item,i) => (
            <option key={i} value={item.cnid}>{item.name}</option>
          ))}
          </select>
        </div>

        <div className={`form-group mb-3`}>
          <label className="form-label small">Transfer Date <FormNeeded/></label>
          <input type="date" 
            className="form-control height-md bg-wite rounded-none"
            style ={{fontSize:'0.9rem', height:'2.7rem'}}
            disabled
            value={data.schd}
            onChange={({ target }) => {handleChange("schd", target.value); setText('');}}
            placeholder="">
          </input>
        </div>         

        <div className={`form-group mb-3`}>
          <label className="form-label small">Enter Amount <FormNeeded/></label>
          <input type="text" 
            className="form-control height-md bg-wite rounded-none"
            style ={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.amnt}
            onChange={({ target }) => {handleChange("amnt", target.value); setText('');}}
            placeholder="">
          </input>
        </div>

        <div className={`form-group mb-3 `}>
          <label className="form-label small">Transfer Reason <FormNeeded/></label>
          <select 
            className="form-select height-md bg-wite  rounded-none"
            style ={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.resn}
            onChange={({ target }) => {
              handleChange("resn", target.value);
              setText('');
            }}
          >
          <option value=''>Select Transfer Reason</option>
          {code.filter(x=>x.user.includes(asset.form)).map((item,i) => (
            <option key={i} value={item.code}>{item.name}</option>
          ))}
          </select>
        </div>

        <div className={`form-group mb-3`}>
          <label className="form-label small">Memo</label>
          <textarea type="text" 
            className="form-control bg-wite rounded-none"
            rows='2'
            style ={{fontSize:'0.9rem'}}
            value={data.memo}
            onChange={({ target }) => {handleChange("memo", target.value); setText('');}}
            placeholder="Enter Memo (Optional)">
          </textarea>
        </div>

      </div>



      <div className="mb-3">
        <p className="small text-danger">{text}<span className="text-white">{'.'}</span></p>
      </div>


      <div className="">
        <div className="d-grid">
          <button className={`btn height-md ${ form ? 'btn-primary back-color-main' : 'btn-primary back-color-main'}`}
            disabled={!form}
          ><small>Submit</small>
          </button>
        </div>
      </div>
      
    </form>

    <WebbDividerMedium />
    <WebbDividerMedium />
  </>
  )
}