//main
import { useParams } from 'react-router-dom';

import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import TeamEditInfoModule from '../content/contacts/team-edit-info';

export default function TeamsEditInfo() {

  const { id } = useParams();
  
  return (
  <>
    <ContentFormatXX 
      head='Team Details'
      link={`/${UserForm()}/team/view/${id}`}
      form= 'small'
      data= {
        <>
          <TeamEditInfoModule />
        </>
      } 
    />
  </>
  )
}