// remove for now

import React from "react";
/* global google */

export default class LocationSearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.autocompleteInput = React.createRef();
    this.autocomplete = null;
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
  }

  componentDidMount() {
    this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
      {
        componentRestrictions: { country: ["in"] },
        fields: ["address_components", "geometry", "utc_offset_minutes", "place_id", "types"],
        "types": ["geocode"]
      }
    );
    this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
  }

  handlePlaceChanged(){
    const place = this.autocomplete.getPlace();
    //this.props.onPlaceLoaded(place);
    // console.log (place)
    this.props.location(place);
  }

  render() {
    return (
      <>
        <div className="mb-3">
          <div className="form-group">
          <label className="small mb-2 d-none">Search Address (Google)</label>
          <input ref={this.autocompleteInput}  
            className="form-control height-md bg-lite"
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            defaultValue={this.props.dataFromParent}
            id="autocomplete" 
            placeholder="Search (Google Maps)"
            type="search">
          </input>
          </div>
        </div>
      </>
    );
  }
}