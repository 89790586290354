// contracts
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import XLSX from 'xlsx';

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import WebbModuleInfo from "../webb/webb-module-info";

import { UserForm } from "../../services/srvc-utilities";
import { toBase64 } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { GetDocumentFormats } from "../../services/srvc-smart-realm";
import { CreateNewDocumentBulk } from "../../services/srvc-smart-realm";
import { ContactCreateBulk } from "../../services/srvc-contacts-realm";

export default function ContactsNewBulkModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const history = useHistory();

  const [loading,setLoading]=useState(false);

  const [form, setForm] = useState(false);
  const [text, setText] = useState('');

  const [file, setFile] = useState();
  const [list, setList] = useState([])

  const [submit,setSubmit] = useState(false)
  const [taskid,setTaskid] = useState('')
  const [count,setCount] = useState('')
  
  const [data, setData] = useState({    
    form: 'indx',
    list: []
  })

  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset.usid]);

  // form validation
  useEffect( () => {
    setForm(false)
    if (data.form !=='' && file) 
      setForm(true);
  },[data, file]);
  

  const handleFile = async(file) => {
    console.log (file)
    // setList([])
    setFile(file)
    const reader = new FileReader();
    
    reader.onload = e => {
      /* Parse data */
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);
      setList(Array.from(json,item => {return {name:item.name,mail:item.mail,mobile:item.mobile}}))
      
      // handleChange('list',json);
    };
    reader.readAsArrayBuffer(file);
  }

  const DeleteContact = async(x) => {
    console.log(x)
    setList([...list.slice(0, x), ...list.slice(x+1)])
  }

  const handleChange = async(key, val) => {
    setForm(false);
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    // e.preventDefault();
    setLoading(true);

    console.log (list)

    // api call
    var datx={
      "team":asset.user,
      "list":list
    }
    var res = (await ContactCreateBulk({data:datx, user:asset.user})).data
    console.log(res)
    setSubmit(true)
    setTaskid(res.taskid)
    setCount(res.count)


// ContactCreateBulk

    setLoading(false);
  }

  if (loading){ return ( <> <WebbLoader /> </> ) }

  if(!loading && submit)
  {
    return(
      <>
    <WebbDividerSmall/>

    <div className="text-center back-color-wite py-3 mb-3">
      
      <p className="lead fw-bold m-0 p-0 text-success">Task Submitted</p>
    </div>

    <div className="row row-cols-2 g-3">

      <Link to={`/${UserForm()}/contacts`} >
        <div className="col flex-fill back-color-wite p-3 small text-center hilite">
          Go To Contacts
        </div>
      </Link>

      <Link to={`/${UserForm()}/home`}>
        <div className="col flex-fill back-color-wite p-3 small text-center hilite">
          Go Home
        </div>
      </Link>

    </div>

    <div className="py-3 border-bottom"></div>
    <WebbDividerMedium/>
    <div className="">
      <p className="small">Contacts will be updated automatically and details are saved in your transactions history.</p>
      <p className="small">Task Id for bulk contact addition is {taskid}.</p>
      <p className="small">Total records found {count}</p>

    </div>

    <WebbDividerMedium/>
    <WebbDividerMedium/>
  </>
    )
  }

  return (
  <>
    {/* info */}
    <WebbDividerSmall/>
    <WebbModuleInfo data={{info: 'Please enter Document Details'}}/>
    <WebbDividerSmall />

    {/* data */}
    <form onSubmit={handleSubmit}>

      <div className="">

        <div className="mb-3 d-none">
          <div className="form-group">
            <label className="form-label small">Contact Format <FormNeeded/></label>
            <select 
              className="form-select"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.form}
              onChange={({ target }) => handleChange("form", target.value)}
            >
              <option value=''>Select Contact Format</option>
              <option value='indx'>Personal</option>
              <option value='bsnx'>Business</option>
              <option value='tmbx'>Team</option>
            </select>
          </div>
        </div>

        <div className="form-group mb-3">
          <label className="form-label small">Upload File (XLS) <FormNeeded /></label>
          <div className="p-1 bg-white rounded border"  style={{fontSize:'0.9rem'}}>
            <div className="input-group" >
              <input 
                type="file" 
                className="form-control border-none" 
                style={{fontSize:'0.9rem'}}
                onChange= { (e) => {handleFile(e.target.files[0]); }} 
                placeholder="..." >  
              </input>
            </div>
          </div>
        </div>

        <p className="m-0">Contacts: {list && list.length || 0}</p>

      </div>

      <div className="mb-3">
        <p className="small text-danger">{text}<span className="text-white">{'.'}</span></p>
      </div>

      <div className="">
        
      </div>

    </form>

    
    {/* actn */}
    <WebbDividerMedium />
      <div className="">
        <div className="d-grid">
          <button className={`btn height-md rounded-none btn-primary back-color-main`}
            // disabled={!form}
            onClick={()=>handleSubmit()}
          ><small>Save & Continue</small>
          </button>
        </div>
      </div>

    <WebbDividerSmall />
    

    <WebbDividerMedium />
    <WebbDividerMedium />
    
  </>
  )
}