//main
import ContentFormatXX from "../content/webc/content-format-xx";


import SubscriptionCheckModule from "../content/accounts/subscription-check";


export default function SubscriptionCheckView() {


  return (
  <>
    <ContentFormatXX 
      head='View Subscription'
      link={`/auth/next`}
      form= 'small'
      data= {
        <>
            <SubscriptionCheckModule />
        </>
      } 
    />
  </>
  )
}