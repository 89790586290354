// transfers
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbSpinText from "../webb/webb-spintext";
import ListNoData from "../webb/list-nodata";
import NavsButtonBack from "../webb/navs-button-back";
import NavsButtonNext from "../webb/navs-button-next";

import { crnc, numc, UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { GetTransfersList } from "../../services/srvc-transfers-realm";

export default function TransferListModule(props) {

  // console.log(props)
  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  // console.log (asset)
  const [loading,setLoading]=useState(false);
  const history = useHistory();

  const [data, setData] = useState([]);
  const search = props.data;
  const type = props.type
  // console.log(type)
 
  const [index, setIndex] = useState(1)
  const [items, setItems] = useState(10)

  const [curr, setCurrentIndex] = useState(1)
  const [next, setNextIndex] = useState()
  const [last, setLastIndex] = useState()

  const [count, setCount] = useState()
  const [total, setTotal] = useState()

  const [text, setText] = useState('')


  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        setLoading(true);
        const datx = {
          data: { user: asset.user, index:index, items: items, filters: {...search, type:type} },
          user: asset.user
        }
        
        //console.log(search)
        const result = await GetTransfersList(datx)
        // console.log (result)

        setData(result.data.list);

        // // index
        setCount(result.data.count);
        setTotal(Math.ceil(result.data.count/items));
        setText(`${(index-1)*items+1} - ${index*items < result.data.count ? index*items : result.data.count} of ${result.data.count}`)


        setLoading(false);
      }
      fetchData()
    } else {}
  },[index, items, search]);


  const NextIndex = async() =>{
    if(total == index) {}
    else {
      setNextIndex(curr+1)
      setIndex(curr+1)
      setCurrentIndex(curr+1)
    }
  }

  const LastIndex = async()=>{
    if(index == 1) {}
    else{
      setLastIndex(curr-1)
      setIndex(curr-1)
      setCurrentIndex(curr-1)
    }
  }

  const handleClick = (item) => {
    // history.push(`/${asset.form.substring(0,2)}/transfers/v/${item}`)
  }


  if (loading){ return ( <> </> ) }
  if (!loading && (!data || data.length === 0)) { return ( <> <ListNoData /> </> ) }

  return (
  <>

  {/* data */}
  <div className="back-color-wite border rounded-wd shadow-sm">
    {data && data.length > 0 && data.map((item, i) => (
      <div className={i < data.length-1 ? 'border-bottom' : ''} key={i}>
        <div className="p-3 hilite" onClick={() => handleClick(item.item)}>
          <div className="d-flex cursor">

            <div className="">
              <p className="m-0">
                <span className={`${item.status == 6 ? 'text-color-success' : `${item.status=="4" ||  item.status=="8" || item.status=="5"? 'text-color-danger': 'text-color-warning'     }` }   }`}>
                  <i className="bx bxs-circle text-mini"></i>
                </span>
                <span className="text-normal text-bold m-0 text-primary ms-1">{item.user.name}</span>
                
              </p>
              
              <p className="m-0">{item.meta.memo || '******'}</p>
              <p className="m-0 text-color-tone">{(new Date(item.created)).toLocaleString()}</p>
            </div>

            {/* <span className={`text-normal text-small m-4  mx-5  ${item.status == 6 ? 'text-color-success' : `${item.status=="4"? 'text-color-danger': `${item.status=="8"? "text-color-danger":"text-warning"}`     }` }   }`}>{`${item.status == 6 ? 'Transaction Successfull' : `${item.status=="4"? 'Transaction Failed': `${item.status=="8"? "Transaction Cancelled": `${item.status=="5"? "Transaction Rejected": "Transaction Pending"}`   }`     }`    }`}</span> */}
            
            <div className="ms-auto text-end">
              <p className="text-normal m-0">
                <span>{item.mode === 'credit' ? '+' : '-'}</span>
                <span>{crnc(item.amount.number/1000000)}</span>
              </p>
              
            </div>

          </div>

        </div>
      </div>

    ))}
    </div>


    {/* index */}
    <WebbDividerSmall />
    <div className="">
      <div className="d-flex justify-content-between">

        <div className="" onClick={()=>LastIndex()}>
          <NavsButtonBack />
        </div>

        <div className="">
          <p className="my-2 text-color-tone">{text}</p>
        </div>

        <div className="" onClick={()=>NextIndex()}>
          <NavsButtonNext />
        </div>

      </div>
    </div>

  </>
  )
}