// invoices
import { useEffect, useState } from "react";

import WebbDividerSmall from "../webb/webb-divider-sm";


import { UserForm, crnc, numc } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { InvoicesStatus } from "../../services/srvc-invoices-realm";


const list = [
  { name: 'Total', color:'primary', icon: '', code:'total', mode: ['b', 's'], number: '0' },
  { name: 'Paid', color:'success', icon: '', code:'done', mode: ['b', 's'], number: '0' },
  { name: 'Pending', color:'warning', icon: '', code:'wait', mode: ['b', 's'], number: '0' },
  { name: 'DSO', color:'danger', icon: '', code:'metric', mode: ['s'], number: '***' },
  { name: 'DPO', color:'danger', icon: '', code:'metric', mode: ['b',], number: '***' },
]

export default function InvoicesStatusModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const [loading, setLoading] = useState(true);
  
  const [inxx, setInward] = useState();
  const [obxx, setOutward] = useState();

  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        setLoading(true);
        
        const stats = await InvoicesStatus({data: {user: asset.user}, user: asset.user})
        // console.log (stats)
        // setData(stats.data)
        setInward(stats.data.inbound);
        setOutward(stats.data.outbound)

        setLoading(false);
      }
      fetchData()
    } else {}
  },[]);


  return (
    <>
    <p>RECEIVABLES</p>
    <div className="row row-cols-2 row-cols-md-4 g-1 mb-3">
      {list && list.map((item, i) => (item.mode.includes('s') ?
        <div className="col" key={i}>
          <div className="rounded-wd  back-color-lite p-2 px-3 border-none" key={i}>
            
            <p className="m-0 p-0 text-small">
              <span><i className={`text-mini bi-circle-fill text-${item.color}`}></i></span>
              <span>{' '}</span>
              <span className="text-uppercase">{item.name}</span>
            </p>
            <p className={`m-0 p-0 caption-sm ${inxx ? '': 'text-color-tone'}`}>
              {inxx && Math.floor(inxx[item.code].number) || item.number}
            </p>
            <p className={`m-0 p-0 text-small ${inxx ? '': 'text-tone'}`}>
              {item.code !== 'metric'
                ? inxx && crnc(inxx[item.code].amount/1000000).split('.')[0] || item.number
                : '***'
              }
            </p>
          </div>
        </div>
      :''))}

    </div>

    <WebbDividerSmall/>
    
    <p>PAYABLES</p>

    <div className="row row-cols-2 row-cols-md-4 g-1 mb-3">
    {list && list.map((item, i) => (item.mode.includes('b') ?
      <div className="col" key={i}>
        <div className="rounded-wd back-color-lite p-2 px-3 border-none" key={i}>
          
          <p className="m-0 p-0 text-small">
            <span><i className={`text-mini bi-circle-fill text-${item.color}`}></i></span>
            <span>{' '}</span>
            <span className="text-uppercase">{item.name}</span>
          </p>
          <p className={`m-0 p-0 caption-sm ${obxx ? '': 'text-tone'}`}>
            {obxx && obxx[item.code].number || item.number}
          </p>
          <p className={`m-0 p-0 text-small ${inxx ? '': 'text-tone'}`}>
            {item.code !== 'metric'
              ? obxx && crnc(obxx[item.code].amount/1000000).split('.')[0] || item.number
              : '***'
            }
          </p>
        </div>
      </div>
    :''))}

    </div>


    <WebbDividerSmall/>
    
    <p className="text-small text-color-tone">DSO, DPO calculated on 60-day moving average</p>

    </>
  )
}




