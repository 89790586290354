// create new contact
import { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import WebbModuleInfo from "../webb/webb-module-info";

import ContactAccounts from "../webb/cont-accounts";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { GetContactAccounts } from "../../services/srvc-accounts-realm";
import { SetContactAccounts, RemoveContactAccounts } from "../../services/srvc-accounts-realm";

export default function ContactsEditAccountsModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const history = useHistory();
  const { id } = useParams();

  const [loading, setLoading]=useState(true)
  const [form, setForm]=useState(false);
  const [text, setText] = useState("");
  
  const [acnt, setAccount] = useState([]);
  const [data, setData] = useState({nmbr:'', brch:''});


  useEffect( () => {
    if (asset){
      const fetchData = async() => {

        setAccount ((await GetContactAccounts(JSON.stringify({
          data: { usid: id },
          user: asset.usid,
          webb: process.env.REACT_APP_WEBB_SITE_NMBR
        }))).data)

        setLoading(false);
      }
      fetchData()
    } else {}
  },[acnt]);

  // useEffect for form validation
  useEffect( () => {
    setForm(false);
    if (data.nmbr!=='' && data.brch!=='') 
      setForm(true);
  },[data]);

  const DeleteAccount = async() => {
    setLoading(true)
    console.log('delete')
    const res = await RemoveContactAccounts (JSON.stringify({
      data: {cont: id, acid: acnt[0].enid},
      user: {usid: asset.usid, form: asset.form}
    }))
    setLoading(false)
  }

  const handleChange = async(key, val) => {
    setForm(false);
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
 
    if (form) {
      setLoading (true);
      
      var datx = {
        data:{
          ...data,
          acfr: 'bank',
          acsr: (data.brch).substr(0,4),
          acch: 'bank',
          name: id,
          usid: id,
        },
        user: asset.usid,
        webb: process.env.REACT_APP_WEBB_SITE_NMBR
      }
      console.log (JSON.stringify(datx))
      // api call
      const res = await SetContactAccounts (JSON.stringify(datx));
      console.log (res)
      if (res) {
        history.push(`/${asset.form}/contacts/view/${id}`);
      }
      else {
        // update failed
      }
      setLoading(false)
    }
    else {
      setText ('Please Enter all details')
    }
  }

  if (loading) { return ( <> <WebbLoader /> </> ) }
  // if (acnt.length===0) { return ( <> No Accounts </> ) }

  return (
  <>
    {console.log (acnt)}
    {/* info */}
    <WebbDividerSmall/>
    
    {/* account on file */}
    <div className={acnt.length > 0 ? '' : 'd-none'}>
      <WebbModuleInfo data={{info: 'Contact Account Details'}}/>
      <ContactAccounts data={acnt}/>

      <WebbDividerSmall/>
      <p className="small text-tone">To edit/change account details, please delete existing account and add new.</p>
      
      <button 
        className="btn btn-link text-decoration-none p-0"
        onClick={()=>DeleteAccount()}
      ><small>Delete Account</small></button>  

    </div>

    {/* edit account on file */}

    {/* no account on file */}
    <div className={acnt.length > 0 ? 'd-none' : ''}>
      <WebbModuleInfo data={{info: 'Please add Account Details and Save'}}/>
    

        {/* input */}
        <form onSubmit={handleSubmit}>
        <div className="">

          <div className="form-group mb-3">
            <label className="form-label small">Account Number <FormNeeded/></label>
            <input type="text" 
              className="form-control height-md bg-wite"
              style ={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.nmbr}
              onChange={({ target }) => {handleChange("nmbr", target.value); setText('');}}
              placeholder="123456789">
            </input>
          </div>

          <div className="form-group mb-3">
            <label className="form-label small">IFSC Branch Code  <FormNeeded/></label>
            <input type="text" 
              className="form-control height-md bg-wite"
              style ={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.brch}
              onChange={({ target }) => {handleChange("brch", target.value); setText('');}}
              placeholder="IFSC1234566">
            </input>
          </div>


        </div>

        {/* text */}
        <div className="mb-3">
          <p className="small text-danger">{text}<span className="text-white">{'.'}</span></p>
        </div>

        <WebbDividerMedium/>
        <div className="small text-tone">
          <p className="m-0">Please ensure account details are correct and verified with your contact.</p>
          <p className="m-0">Incorrect account details will result in failed transactions.</p>
        </div>

        {/* actn */}
        <WebbDividerMedium />
        <div className="">
          <div className="d-grid">
            <button className={`btn height-md btn-primary back-color-main`}
              disabled={!form}
            ><small>Save & Continue</small>
            </button>
          </div>
        </div>


      </form>


    
    </div>

    <WebbDividerMedium/>
    <WebbDividerMedium/>
  </>
  )
}