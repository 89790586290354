//main
import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";

export default function AccountsLinkBase() {

  return (
  <>
    <ContentFormatXX 
      head='Link Bank Account'
      link={`/${UserForm()}/home`}
      form= 'small'
      data= {
        <>
          
        </>
      } 
    />
  </>
  )
}