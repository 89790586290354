// contacts

export default function ContactInfo(props) {

  const data = props.data;

  return (
  <>
    <div className={`border bg-white rounded`}>

      <div className="d-flex p-3">
        <div className="me-auto">
          <p className="">Mobile</p>
          <p className="m-0 p-0">Email</p>
        </div>
        <div className="text-end">
          <p className="">{data.info.mmid}</p>
          <p className="m-0 p-0">{data.info.mail}</p>
        </div>
      </div>

    </div>

  </>
  )
}