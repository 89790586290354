//main
import { useParams } from 'react-router-dom';

import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import ContactsRoomsModule from "../content/contacts/contacts-rooms";

export default function ContactsRooms() {

  const { id } = useParams();

  return (
  <>
    <ContentFormatXX 
      head='Contact Room'
      link={`/${UserForm()}/contacts/view/${id}`}
      form= 'medium'
      data= {
        <>
          <ContactsRoomsModule />
        </>
      } 
    />
  </>
  )
}