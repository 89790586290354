// transaction detail view
import { numc } from '../../services/srvc-utilities'
const code = require('../../data/code-transfers.json').data

export default function TransferOverview(props) {

  const data = props.data;
  
  return (
  <>
    <div className="border bg-white" >

      <div className="d-flex p-3">
        <div className="me-auto">
          <p className="m-0 p-0" style={{fontSize:'1.5rem', }}>
            <span className="text-muted">{''}</span>
            <span className="fw-bold" >{numc(data.amnt,'ww')}</span>
            <span className="text-muted">{' ('}{data.crdb==='D' ? '-' : '+'}{')'}</span>
          </p>
          <p className="mb-2">{data.resn}{', '}{data.cmnt}</p>
          <p className="m-0 small text-sm d-none">TIN: {data.enid.substr(0,24)} {'...'}</p>
        </div>
        
        <div className="text-end">
          <p className="lead m-0 p-0"  style={{fontSize:'2rem', }}>
            {data.crdb === "C" 
              ? <i className={`${data.form === parseInt(6) ? 'bi-plus-circle' :'bi-arrow-down-circle'} text-stat text-muted`}></i> 
              : <i className={`${data.form === parseInt(7) ? 'bi-dash-circle' :'bi-arrow-up-circle'} text-stat text-muted`}></i> 
            }
          </p>
        </div>
      </div>

      <div className="border-bottom"></div>
      
      <div className="d-flex p-3">
        <div className="me-auto">
          <p className="">{'Date:'}</p>
          <p className="m-0 p-0">{'UTR:'}</p>
        </div>
        <div className="text-end">
          <p className="">{(new Date(data.crts)).toLocaleString()}</p>
          <p className="m-0 p-0">{data.utrn || '24'+Date.now().toString()}</p>
        </div>
      </div>

      <p className="px-3 line-clamp-sm d-none">{'Hash: '}{data.hash}</p>
      
      <div className={`py-2 border-top text-center text-white bg-${code.find(item=>parseInt(item.code) === data.stat).colr}`}>
        <p className="fw-bold m-0 p-0">
          {code.find(item=>parseInt(item.code) === data.stat).text.toUpperCase()}
        </p>
      </div>
    </div>


    
  </>
  )
}