// onboard
import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";

import { GetNewUser } from "../../services/srvc-auth-user";
import { GetUserDomainList } from "../../services/srvc-utilities-realm";
// import { SetUserDomain } from "../../services/srvc-user-realm";

export default function OnboardUserDomainModule() {

  const usrx = GetNewUser();
  // console.log (usrx)
  const history = useHistory();

  const [loading,setLoading]=useState(true);
  const [form,setForm]=useState(false);
  const [text, setText] = useState('');
  
  const [list,setList]=useState([]);

  const [data,setData]=useState({
    domn:0
  });

  // get user domain list
  useEffect( () => {
    if (usrx){
      const fetchData = async() => {
        // setList((await GetUserDomainList()).data);
        setLoading(false);
      }
      fetchData()
    } else {}
  },[usrx.usid]);

  // useEffect for form validation
  useEffect( () => {
    setForm(false);
    if (data!=='') setForm(true);
  },[data]);

  const handleChange = async(key, val) => {
    console.log (list[val].code)
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (form) {
      setLoading(true);
      
      const datx = {
        body: {
          domn: list[data.domn].code,
          usid: usrx.usid,
          form: 'us', 
        },
        user: { usid: usrx.usid, form: 'us' }
      }
      //console.log( JSON.stringify(datx) );
      // const res = await SetUserDomain (JSON.stringify (datx));
      setLoading(false);
      // if (res.data) {
      //   history.push(`/onboard/creds`);
      // }
      //resetForm();
    }  
    else {
      setText('Please Select your option')
    }
  }

  if (loading){ return ( <> <WebbLoader />  </> ) }


  return (
  <>
    {/* info */}
    <WebbDividerSmall />
    <p className="text-tone">Please Select Your Profile</p>

    {/* form */}
    <form onSubmit={handleSubmit}>
      <div className="">

        {list.map((item,i) => (
        <div className={`mb-3`} key={i}>
          <div className="form-check p-0">
            <div className="d-flex py-2">
            <div className="me-auto">
                <p className="m-0 p-0">{item.name}</p>
              </div>
              <div className="">
                <input  className="form-check-input" 
                  type="checkbox"
                  style={{fontSize:"1.1em"}}
                  checked={data.domn===i}
                  onChange={({ target }) => handleChange("domn", i)}
                  >
                </input>
              </div> 
            </div>
          </div>
        </div>
        ))}

      </div>
      

      {/* text */}
      <div className="mb-3">
        <p className="small text-danger">{text}<span className="text-white">{'.'}</span></p>
      </div>
      

      {/* actn */}
      <WebbDividerMedium />
      <div className="">
        <div className="d-grid">
          <button className={`btn height-md ${ form ? 'btn-primary back-color-main' : 'btn-light'}`}
            disabled={!form}
          ><small>Save & Continue</small>
          </button>
        </div>
      </div>
      
    </form>
    <WebbDividerMedium />
    <WebbDividerMedium />
  </>
  )
}