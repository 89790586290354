// contracts
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import WebbModuleInfo from "../webb/webb-module-info";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { SmartGetInfo } from "../../services/srvc-smart-realm";
import { SmartAddFiles } from "../../services/srvc-smart-realm";
import { AccountBalance, BaseAccountBalance } from "../../services/srvc-accounts-realm"

export default function DocumentsNewInfoModule() {

  // const usrx = GetLocalUser();
  // const bxnz = GetLocalBusiness();
  const asset = UserForm() === 'in' ? GetLocalUser() : GetLocalBusiness();
  const history = useHistory();

  var {id} = useParams()
  
  // console.log (asset)
  const [loading,setLoading]=useState(true);

  const [form, setForm] = useState(false);
  const [text, setText] = useState('');

  const [info, setInfo] = useState({});
  const [data, setData] = useState({    
    name: '',
    memo: '',
    nmbr: '24'+Date.now().toString(),
    form: '',
    stts: (new Date()).toISOString().substring(0,10),
    ents: (new Date()).toISOString().substring(0,10)
  })

  const [balance,setBalance] = useState()

  useEffect( () => {
    if (asset){
      const fetchData = async() => {

        const datx = {
          data: { smart: id },
          user: asset.user,
        }

        var res = (await SmartGetInfo(JSON.stringify(datx))).data
        // basic info
        setInfo(res);

        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset.user]);


  // form validation
  useEffect( () => {
    setForm(false)
    if (data.name !=='' && data.nmbr !=='') 
      setForm(true);
  },[data]);

  useEffect(()=>{
    const fetchdata= async()=>{
      const result = await BaseAccountBalance({
        data: {user: asset.user, type:"base-account"}, 
        user: asset.user,
      })
      //console.log(result)
      var balance = parseFloat(result.data.balance.number)/1000000
      // console.log(balance)
      setBalance(balance)
    }
    fetchdata()
  },[asset.user])
  

  const handleChange = async(key, val) => {
    setForm(false);
    setData({ ...data, [key]: val });
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if(balance>100)
    {
      var payload = {
        data: {
          name: data.name,
          memo: data.memo,
          form: 'document',
          sort: '',
          nmbr: data.nmbr,
          smrt: id,
          stts: '',
          ents: '',
          user: asset.user ,
        },
        user: asset.user,
        webb: process.env.REACT_APP_WEBB_SITE_NMBR
      }
  
      // console.log(payload)
  
      // api call
      const res = await SmartAddFiles (JSON.stringify(payload))
      
      // if (res) console.log (res)
  
      if (res.data) {
        setLoading(false);
        history.push(`/${asset.form.substring(0,2)}/documents/new/xxxx/${id}/${res.data.docn}`)
      }
      else setText('Error. Please try again')
    }

    else{
      setText('Error. Insufficient Balance, Please Recharge Your Subscription Account')
    }


    setLoading(false);
  }

  if (loading){ return ( <> <WebbLoader /> </> ) }

  return (
  <>
    {/* info */}
    <WebbDividerSmall />
    <p className="text-lead text-bold m-0">{info.name}</p>
    <p className="text-color-tone">{id}</p>
    <WebbDividerSmall />

    <WebbDividerSmall/>
    <WebbModuleInfo data={{info: 'Please enter Document Details'}}/>
    <WebbDividerSmall />

    {/* data */}
    <form onSubmit={handleSubmit}>

      <div className="">

        <div className="mb-3 ">
          <div className="form-group">
            <label className="form-label small">Document Name <FormNeeded/></label>
            <input type="text" className="form-control rounded-none"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.name}
              onChange={({ target }) => {handleChange("name", target.value);}}
              placeholder="Document Name">
            </input>
          </div>
        </div>


        <div className="form-group mb-3">
          <label className="form-label small">Description <FormNeeded/></label>
          <textarea 
              className="form-control rounded-none" 
              style={{fontSize:'0.9rem'}}
              rows="3"
              value={data.memo}
              onChange={({ target }) => {handleChange("memo", target.value);}}
              placeholder="Description">  
          </textarea>
        </div> 

        <div className="mb-3">
          <div className="form-group">
            <label className="form-label small">File / Reference Number (Internal)</label>
            <input type="text" className="form-control rounded-none"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.nmbr}
              onChange={({ target }) => {handleChange("nmbr", target.value);}}
              placeholder="">
            </input>
          </div>
        </div>

      </div>


      <div className="mb-3">
        <p className="small text-danger">{text}<span className="text-white">{'.'}</span></p>
      </div>


      <div className="">
        <div className="d-grid">
          <button className={`btn height-md btn-primary back-color-main rounded-none`}
            disabled={!form}
          ><small>Save & Continue</small>
          </button>
        </div>
      </div>

    </form>

    <WebbDividerMedium />
    <WebbDividerMedium />
    
  </>
  )
}