// cancel button
import { Link } from 'react-router-dom';
import { UserForm } from "../../services/srvc-utilities";
import { GetAuthUser } from "../../services/srvc-auth-user";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

export default function IconActionCard(props) {

  // const asset = GetAuthUser();
  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const data = props.data;

  return (
  <>
    <Link 
      disabled={data.actv}
      style={{ pointerEvents: data.actv ? '' : 'none' }}
      to={`/${asset.form.substring(0,2)}/${data.link}`}>
      <div className="rounded-wd back-color-wite hitone p-3 mb-3">
        <div className={`d-flex ${data.actv?'':'text-muted'}`}>
          <div className="p-1 text-center rounded-wd back-color-lite" style={{height:'2.7rem', width:'2.7rem'}}>
            <i className={`m-0 text-color-tone ${data.icon} text-icon-md`}>
            </i>
          </div>

          <div className="ps-2 pe-3">
            <p className="text-normal text-bold text-color-main m-0 p-0">{data.name}</p>
            <p className={`text-dark m-0 p-0 ${data.actv?'':'text-muted'}`}>{data.text}</p>
          </div>

          <div className="ms-auto py-2 mt-1">
            <i className={`bi bi-chevron-right m-0 p-0 text-color-tone`}></i>
          </div>
        </div>
      </div>
    </Link>

  </>
  )
}