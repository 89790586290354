import React, { useState } from 'react'
import HeaderSectionLite from '../webb/head-section-lite'
import { useEffect } from 'react'
import { GetUserFeatures, ResetUserFeatures, SetUserFeatures, uploadUserSignature } from '../../services/srvc-smart-realm'
import { UserForm, toBase64 } from '../../services/srvc-utilities'
import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-user'
import WebbSpinText from '../webb/webb-spintext'
import WebbDividerMedium from '../webb/webb-divider-md'

const ContractFeature = () => {

const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();

const [loading,setLoading] = useState(true)
const [data,setData] = useState()
const [mail,setMail] = useState()
const [auth,setAuth] = useState()
const [auto,setAuto] = useState()
const [alrt,setAlrt] = useState()
const [sign,setSign] = useState()

const [refresh,setRefresh] = useState(false)
const [file,setFile] = useState()

useEffect(()=>{
    const fetchdata= async()=>{
        var res = await GetUserFeatures({data:{user:asset.user}})
        // console.log(res.data)
        if(res.data==false)
        {
            setData(res.data)
        }
        else{
            setData(res.data) 
            setAlrt(res.data.feat.alrt)
            setAuth(res.data.feat.auth)
            setAuto(res.data.feat.auto)
            setMail(res.data.feat.mail)
            setSign(res.data.sign)
        }
       
        setLoading(false) 
    }
    fetchdata()
    
},[refresh])

const handleChange=async(item)=>{
    // setLoading(true)
    //console.log(item)
    var res = await ResetUserFeatures({data:{user:asset.user, feat:item}})
    //console.log(res)
    // setLoading(false)
}

const setUserFeatures = async()=>{
    setLoading(true)
    var res = await SetUserFeatures({data:{user:asset.user, feat:{alrt:true, mail:true, auto:false, auth:false} }})
    setRefresh(!refresh)
    setLoading(false)
}

const uploadSignature =async()=>{
    setLoading(true)

    var str1=await toBase64(file);
    // var str2=str1.split(';base64,').pop(1)
    var res = await uploadUserSignature({data:{user:asset.user, file:str1}})
    setRefresh(!refresh)
    setLoading(false)

}


if (loading){ return ( <> <WebbSpinText /> </> ) }

return (
    <>
    <HeaderSectionLite data={{name: 'E-sign Setup'}}/>
   
    <div className={!data?'d-none': `fw-light`}>
        
        <WebbDividerMedium />
        <div className='d-flex'>
            <div className='me-auto'>
                <p>Send Final Signed Copy To Signatory</p>
            </div>
            <div className="text-end mt-1 sm">
                <div class="form-check form-switch " >
                <input style={{cursor:"pointer"}} onChange={()=>{setMail(!mail);handleChange({'mail':!mail})} } checked={mail} class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                </div>
            </div>
        </div>

        <div className='d-flex'>
            <div className='me-auto'>
                <p>Email Alerts For Signature/eKYC Invitation</p>
            </div>
            <div className="text-end mt-1">
                <div class="form-check form-switch" >
                <input style={{cursor:"pointer"}} onChange={()=>{setAlrt(!alrt);handleChange({'alrt':!alrt})} } checked={alrt} class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                </div>

            </div>
        </div>

        <div className='d-flex'>
            <div className='me-auto'>
                <p>2-Factor Authentication before signature</p>
            </div>
            <div className="text-end mt-1">
                <div class="form-check form-switch" >
                <input style={{cursor:"pointer"}} onChange={()=>{setAuth(!auth);handleChange({'auth':!auth})} }  checked={auth} class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                </div>
            </div>
        </div>

        <div className='d-flex'>
            <div className='me-auto'>
                <p>Automatic Esignature on behalf of Admin(only applicable for email based signature)</p>
            </div>
            <div className="text-end mt-1">
                <div class="form-check form-switch" >
                <input style={{cursor:"pointer"}} onChange={()=>{setAuto(!auto) ;handleChange({'auto':!auto})} } checked={auto} class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                </div>
            </div>
        </div>


    </div>

    <div className={data?'d-none': `fw-light`}>
        <p className='text-dark'>Sorry Your default esign setup is not yet registered.</p>
        <button onClick={()=>setUserFeatures()} className='btn btn-light text-small rounded-none'>Register Default Setup</button>
    </div>

    <WebbDividerMedium />

    <HeaderSectionLite data={{name: 'Your Signature(PNG)'}}/>
    <div className={data==false?"d-none":''}>

    <div className={''}>
        <p className='text text-primary'>Upload Signature</p>
        <div className='d-flex'>
            <div className='me-auto'>
                <input onChange={(e)=>setFile(e.target.files[0])} className='form-control rounded-none' type='file' accept='image/png' />
            </div>
            <div className='me-auto border'>
                <button onClick={()=>uploadSignature()} className='btn btn-light rounded-none'>Upload</button>
            </div>
        </div>

    </div>

    
    <div className={sign==''?'d-none':'mt-5'}>
        <button onClick={()=> window.open(data.sign)} className='btn btn-light text-small rounded-none'>Download Signature</button>

    </div>


    </div>

    </>
  )
}

export default ContractFeature