// transfers
import { useState } from "react";
import { useHistory } from 'react-router-dom';

import WebbLoader from "./webb-loader";
import WebbSpinText from "./webb-spintext";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { TransfersCancel} from "../../services/srvc-transfers-realm";
import { TransfersDecline, TransfersConfirm } from "../../services/srvc-transfers-realm";

export default function TransferActions(props) {

  const data = props.data;
  const asset = UserForm() === 'in' ? GetLocalUser() : GetLocalBusiness();

  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handlePay = async() => {
    setLoading(true);
    const response = await TransfersConfirm ( JSON.stringify({
      data: { trid: data.enid },
      user: asset.usid,
      webb: process.env.REACT_APP_WEBB_SITE_NMBR
    }));
    
    setLoading(false);
    if (response) {
      history.push(`/${asset.form.substring(0,2)}/transfers/submit`);
    }
  }

  const handleDecline = async() => {
    setLoading(true);
    const response = await TransfersDecline ( JSON.stringify({
      data: { trid: data.enid },
      user: asset.usid,
      webb: process.env.REACT_APP_WEBB_SITE_NMBR
    }));
    console.log (response)
    if (response) {
      history.push(`/${asset.form.substring(0,2)}/transfers/submit`);
    }
  }

  const handleCancel = async() => {
    setLoading(true);
    const response = await TransfersCancel ( JSON.stringify({
      data: { trid: data.enid },
      user: asset.usid,
      webb: process.env.REACT_APP_WEBB_SITE_NMBR
    }));
    console.log (response)
    if (response) {
      history.push(`/${asset.form.substring(0,2)}/transfers/submit`);
    }
  }

  if (loading){ return ( <> <WebbSpinText /> </> ) }


  return (
  <>
    <div className={`${data.form===3 && data.stat === 0 ? '' :'d-none'}`}>

      <div className={`d-flex ${data.crdb==='D' ? '' : 'd-none' }`}>
        <div className="me-auto">
          <button 
            className="btn btn-sm btn-outline-danger rounded-none small py-2"
            disabled={loading}
            type="button"
            onClick={()=> handleDecline()} 
            style={{width:"6rem"}}
            ><small>Reject</small>
          </button>
        </div>
        
        <div className="">
          <button 
            className="btn btn-sm btn-success rounded-none small py-2"
            disabled={loading}
            type="button"
            onClick={()=>handlePay()} 
            style={{width:"6rem"}}
            ><small>Pay</small>
          </button>
        </div>
      </div>


      <div className={`d-flex ${data.crdb==='C' ? '' : 'd-none' }`}>
        <div className="me-auto">
          <button 
            className="btn btn-sm btn-outline-danger rounded-none small py-2"
            disabled={loading}
            type="button"
            onClick={()=>handleCancel()} 
            style={{width:"6rem"}}
            ><small>Cancel</small>
          </button>
        </div>
        
        <div className="">

        </div>
      </div>


    </div>
  </>
  )
}