//main
import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import AccountsNewModule from "../content/accounts/accounts-new";

export default function AccountsNew() {

  return (
  <>
    <ContentFormatXX 
      head='Link Account'
      link={`/${UserForm()}/transfers`}
      form= 'small'
      data= {
        <>
          <AccountsNewModule />
        </>
      } 
    />
  </>
  )
}