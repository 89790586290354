import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-user';
import { StampList } from '../../services/srvc-estm-realm';
import { GetDocumentFiles, MergeDocumentStamp, SmartGetDocumentList } from '../../services/srvc-smart-realm';
import { UserForm } from '../../services/srvc-utilities';
import FormNeeded from '../webb/form-needed';
import WebbLoader from '../webb/webb-loader';

const SmartAddStampModule = () => {

    const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
    const {id} = useParams()
    const history = useHistory()

    const [loading,setLoading]  = useState(true)
    const [stamp,setStamp] = useState([])
    const [documents,setDocuments] = useState([])
    const [docfile,setDocfile] = useState()
    const [stampfile,setStampfile] = useState()

    const [form, setForm] = useState(false);

    const [input, setInput] = useState({    
        docs: '',
        estm: '',
      })


    useEffect(()=>{
        setLoading(true)
    
        const fetchdata =async()=>{

            const datx = {
                data: { smart: id },
                user: asset.user,
              }
              const docs =(await SmartGetDocumentList(JSON.stringify(datx))).data
              if(docs.length>0)
              {
                  var newArray = docs.filter(function (el) {
                      return el.stat == "6" &&
                             el.actv == true 
                    });
                  setDocuments(newArray)
              }

              var res = (await StampList(JSON.stringify({
                data:{user:asset.user, pageNo: 1, perPage: 25 }
            }))).data
    
            // console.log(res)
            if(res.list.length>0)
            {
                var newArray = res.list.filter(function (el) {
                    return el.stat == "6" &&
                           el.actv == true 
                    // return el.actv == true 
                  });
                  
                //   console.log(newArray)
                setStamp(newArray)
            }

            setLoading(false)
    
        }
    
        fetchdata()
        
    
    },[id])

    // useEffect(()=>{

    //     const fetchdata = async()=>{
    //         const datx = {
    //             data: { document: input.docs },
    //             user: asset.user,
    //             webb: process.env.REACT_APP_WEBB_SITE_NMBR
    //           }
    //         const files =(await GetDocumentFiles(JSON.stringify(datx))).data
    //         // console.log(files)
    //         console.log(files[0].link)
    //         setDocfile(files[0].link)
    //     }

    //     fetchdata()


    // },[input.docs])

    useEffect( () => {
        setForm(false)
        if (input.estm !=='') 
          setForm(true);
    },[input]);


    const handleChange = async(key, val) => {
        setForm(false);
        setInput({ ...input, [key]: val });
      }


    
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    //console.log(input.docs)
    // console.log(input.estm)

    var finalstamp = stamp.filter(function (el) {
        return el.srvc == input.estm 
      })
    //   console.log(finalstamp)

    var payload = {
        data:{
            document: input.docs,
            smart: id,
            file: "",
            srvc: input.estm,
            estm: finalstamp[0].link
            
        },
        user:asset.user
    }
    

    //console.log(payload)
    var res = await MergeDocumentStamp(JSON.stringify(payload))
    history.push(`/${asset.form.substring(0,2)}/smart/view/${id}`)

  }

    if(loading){ return ( <> <WebbLoader /> </> ) }


  return (
    <div className='mt-3 p-2'>
        {/* document selection */}

        <form onSubmit={handleSubmit}>

        <label className='form-label small mt-3'>Select Document <FormNeeded/> </label>
        <select className='form-select rounded-none mt-1' 
                style={{fontSize:'0.9rem', height:'2.7rem'}} 
                // onClick={(e)=>setSelectedDoc(e.target.value)}
                value={input.docs}
                onChange={({ target }) => handleChange("docs", target.value)}>
            <option>choose</option>
            {
                documents && documents.length>0 && documents.map((item,i)=>{
                    return(
                        <option key={i} value={item.enid}>{item.meta.name}</option>
                    )
                })
            }
            
        </select>


        <label className='form-label small mt-4'>Select Stamp Paper <FormNeeded/> </label>
        <select className='form-select rounded-none mt-1' 
                style={{fontSize:'0.9rem', height:'2.7rem'}} 
                value={input.estm}
                onChange={({ target }) => handleChange("estm", target.value) }
                >
            <option>choose</option>
            

            {
                stamp && stamp.length>0 && stamp.map((item,i)=>{
                    return(
                        
                        <option className='' key={i} value={item.srvc}>
                         {item.nmbr} - {item.prps}   
                          
                        </option>
                        
                    )
                })
            }
            
        </select>

        <div className="">
        <div className="d-grid mt-5">
            <button className={`btn height-md btn-primary back-color-main rounded-none`}
                disabled={!form}
            ><small>Merge Stamp</small>
            </button>
            </div>
        </div>

        </form>

    </div>

  )
}

export default SmartAddStampModule