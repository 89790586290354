// accounts list
import { useEffect, useState } from "react";

import WebbLoader from "../webb/webb-loader";
import AccountListCard from "../webb/acnt-listcard";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { GetUserAccountsList } from "../../services/srvc-accounts-realm";

export default function AccountsListModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  
  const [loading,setLoading]=useState(false);
  const [data,setData]=useState([]);

  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        setLoading(true);
        const datx = {
          body: {
            usid: asset.usid,
            form: asset.form  
          },
          user: {usid: asset.usid, form: asset.form}
        }
        setData( (await GetUserAccountsList(JSON.stringify(datx))).data );
        
        // links
        setData(data => data.map(item => item = { ...item, link: `/${asset.form}/accounts/view/${item.enid}` } ));

        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset.usid]);
  
  if (loading){ return ( <> <WebbLoader /> </> ) }
  if (!data || data.length === 0) { 
    return ( <> <p className="text-muted">No Records</p> </> ) 
  }

  return (
    <>
      
      <div className="">
        {data && data.length > 0 && data.map((item, i) => (
          <AccountListCard data={item} key={i}/>
        ))}
      </div>
    </>
    )
}