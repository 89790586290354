// user
import { useEffect, useState } from "react";

import WebbDivider from "../webb/webb-divider";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import WebbLoader from "../webb/webb-loader";
import WebbSpinText from "../webb/webb-spintext";
import HeaderSectionDark from "../webb/head-section-dark";

import UserBasicInfo from "../webb/usrx-basic-info";
import UserAccountInfo from "../webb/usrx-account-info";
import UserContactInfo from "../webb/usrx-contact-info";
import UserLocationInfo from "../webb/usrx-location-info";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { AccountDetails } from "../../services/srvc-users-realm";
import { AccountBalance, AccountDetails as BaseAccountDetails } from "../../services/srvc-accounts-realm";

export default function UserProfile () {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  
  const [loading, setLoading]=useState(true);
  const [info, setInfo] = useState({})
 

  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        setLoading(true);
        const result = await AccountDetails({
          data: {user: asset.user}, 
          user: asset.user,
        })
        
        setInfo(result.data)

        setLoading(false);
      }
      fetchData()
    } else {}
  },[]);

  if (loading){ return ( <> <WebbSpinText /> </> ) }

  return (
    <>
      <HeaderSectionDark data={{name: 'Account Details'}}/>
      <div className={`border rounded-wd p-3`}>
        <p className="text-color-tone m-0">Name</p>
        <p className="text-normal m-0">{info && info.name}</p>

        <WebbDividerSmall/>
        <p className="text-color-tone m-0">Account</p>
        <p className="text-normal m-0">{info && info.form === 'indx' ? 'Personal' : 'Business'}</p>

        <WebbDividerSmall/>
        <p className="text-color-tone m-0">Status</p>
        <p className="text-normal m-0">{info && info.active ? 'Active' : '******'}</p>
      </div>
      <WebbDividerMedium />

      <HeaderSectionDark data={{name: 'Contact Details'}}/>
      {/* <UserContactInfo data={info} /> */}
      <div className={`border rounded-wd p-3`}>
        <p className="text-color-tone m-0">Mail</p>
        <p className="text-normal m-0">{info && info.mail}</p>

        <WebbDividerSmall />
        <p className="text-color-tone m-0">Mobile</p>
        <p className="text-normal m-0">{info && info.mobile === "" ? '******' : info.mobile}</p>
      </div>
      
      <WebbDividerMedium />

      {/* <HeaderSectionDark data={{name: 'Location Details'}}/> */}
      {/* <UserLocationInfo data={info} /> */}
      <WebbDividerMedium />

      <WebbDividerMedium />
      {/* <HeaderSectionDark data={{name: 'Account Details'}}/> */}
      {/* <UserAccountInfo data={info} /> */}
      <div className="border-top border-light">
        <WebbDividerSmall />
        <p className="m-0 text-color-tone">{asset.user}</p>
        <p className="m-0 text-color-tone">{info && (new Date(info.created)).toLocaleString()}</p>
      </div>
      <WebbDividerMedium />
      

      <WebbDividerMedium/>
      <WebbDividerMedium/>
    </>
  )
}