//main
import { useState } from "react";

import ContentFormatXA from "../content/webc/content-format-xa";


import WebbDivider from "../content/webb/webb-divider";
import WebbDividerSmall from "../content/webb/webb-divider-sm";
import WebbDividerMedium from "../content/webb/webb-divider-md";
import HeaderSectionLite from "../content/webb/head-section-lite";
import HeaderSectionDark from "../content/webb/head-section-dark";
import WebbDateTime from "../content/webb/webb-datetime";

import SmartSearchModule from "../content/smart/smart-search";

import ContractListModule from "../content/smart/smarts-list";
import StampListModule from "../content/stamps/stamp-list";

import SmartActionsModule from "../content/smart/smart-actions";
import StampsActionsModule from "../content/stamps/stamp-actions";
import { useEffect } from "react";
import { AccountBalance } from "../services/srvc-accounts-realm";
import { UserForm } from "../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../services/srvc-auth-user";
import { useHistory } from "react-router-dom";

const listTabs = [
  {name: 'Contracts', code: 'contracts'},
  {name: 'E-Stamp', code: 'estm'}
]

export default function Smart() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();

  const [sort, setSort] = useState('contracts');
  const [search, setSearch] = useState();


  const [loading, setLoading] = useState(true)

  const history = useHistory()


  // useEffect( () => {
  //   setLoading(true)
  //   if (asset){
  //     const fetchData = async() => {
  //       const result = (await AccountBalance({ 
  //         data: { user: asset.user, type: "base-account" },
  //         user: asset.user
  //       })).data;
  //       // console.log(result)
  //       if(result==false)
  //       {
  //         history.push(`/${asset.form.substring(0,2)}/accounts/subscription`)
  //         return
  //       }
  //       if( parseFloat(result.balance.number)/1000000 < 100)
  //       {
  //         history.push(`/${asset.form.substring(0,2)}/accounts/subscription`)
  //         return
  //       }
  //       // setAcnt(result)
  //       setLoading(false)
  //     }
  //     fetchData()
  //   } else {}
  // },[]);


  const handleSearch = async(item) => {
    // console.log('srch: ', sort)
    setSearch(item);
  }

  return (
  <>
    <ContentFormatXA 

    sidebar=
    {{
      head: <> <strong className="text-tone">Vault</strong> </>,
      data: 
        <> 

          <HeaderSectionLite data={{name: 'Search Documents'}}/>
          <SmartSearchModule search={handleSearch}/>
          
        </>,
      foot: <> <WebbDateTime /> </>,
    }}
    main=   
      {{      
        head: 'Contracts & Documents',
        data: 
          <> 
            {/* navs */}
            <div className="d-flex justify-content-start">
              {listTabs && listTabs.length > 0 && listTabs.map((item, i) => ( 
                
                <span className={`p-2 px-3 me-1 rounded-wd text-mini text-center cursor text-uppercase
                  ${sort === item.code ? 'back-color-next text-color-wite': 'back-color-lite text-color-tone'}`}
                  key={i} style={{fontSize:'0.7rem'}} 
                  onClick={() => setSort(item.code)}
                  >{item.name}</span>

              ))}
            </div>
            
            {/* content */}
            <WebbDividerMedium />
            <HeaderSectionDark data={{name: 'Documents List'}}/> 
            
            <div className={sort === 'contracts' ? '': 'd-none'}>
              <ContractListModule data={search || {} } />
            </div>

            <div className={sort === 'estm' ? '': 'd-none'}>
              <StampListModule data={search || {} } />
            </div>

            <WebbDividerMedium />
            <WebbDividerMedium />
          </>,
        foot: <> </>,
      }}
    actionbar=
      {{      
        head: 'Activity',
        data: 
        <div >
          <div >
            <SmartActionsModule/>
            <StampsActionsModule />
            <WebbDividerMedium/> 
          </div>
        </div>,
        foot: <> </>,
      }}    
    
    />
  </>
  )
}