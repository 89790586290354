//main
import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import ContactsViewModule from "../content/contacts/contacts-view";
import TeamViewModule from "../content/contacts/team-view";

export default function TeamsView() {

  return (
  <>
    <ContentFormatXX 
      head='Teams Details'
      link={`/${UserForm()}/contacts`}
      form= 'medium'
      data= {
        <>
          <TeamViewModule />
        </>
      } 
    />
  </>
  )
}