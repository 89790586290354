import ContentFormatXX from '../content/webc/content-format-xx';

import ANewMobileFirebaseModule from '../content/auth/anew-mobile-firebase';

export default function ANewMobileFirebase() {
    
  return (
    <ContentFormatXX 
    head={'Create Account'}
    link={'/'}
    form={'mini'}
    data={
      <>
        <ANewMobileFirebaseModule />
      </>
      
    }  
  /> 
  )
}