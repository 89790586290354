// user
import { useEffect, useState } from "react";

import WebbDivider from "../webb/webb-divider";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import WebbLoader from "../webb/webb-loader";
import WebbSpinText from "../webb/webb-spintext";
import HeaderSectionDark from "../webb/head-section-dark";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

// import { GetUserPrivacy } from "../../services/srvc-user-realm";
// import { SetUserAlerts, SetUserSearch } from "../../services/srvc-user-realm";

const lsta = require('../../data/list-user-alerts.json').data
const lsts = require('../../data/list-user-search.json').data


export default function UserPrivacy () {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  
  const [loading, setLoading]=useState(false);
  
  const [alrt, setAlerts] = useState({});
  const [srch, setSearch] = useState({});

  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        setLoading(true);
        
        // var datx = ((await GetUserPrivacy(JSON.stringify({
        //   data: {usid: asset.usid, form: asset.form === 'us' ? 'indx' : 'bsnx'}, 
        //   user: asset.usid,
        //   webb: process.env.REACT_APP_WEBB_SITE_NMBR
        // }))).data)

        // setAlerts(datx.coms)
        // setSearch(datx.srch)

        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset.usid]);



  const handleChange = async(sort, item) => {
    
    if (sort === 'alrt') {
      
      // if (item !== 'emid') alert('Coming Soon')
      // else { 
      //   setAlerts({ ...alrt, [item]: !alrt[item] });
      //   const res = await SetUserAlerts(JSON.stringify({
      //     data: {
      //       alrt: { ...alrt, [item]: !alrt[item] },
      //       usid: asset.usid
      //     },
      //     user: asset.usid
      //   }))
        
      // }
      // console.log({ ...alrt, [item]: !alrt[item]})
    }
    
    if (sort === 'srch') {
      // if (item !== '') alert('Premium Feature. Coming Soon')
      // else {
      //   setSearch({ ...srch, [item]: !srch[item] });
      //   const res = await SetUserAlerts(JSON.stringify({
      //     data: {
      //       srch: { ...srch, [item]: !srch[item] },
      //       usid: asset.usid
      //     },
      //     user: asset.usid,
      //     webb: process.env.REACT_APP_WEBB_SITE_NMBR
      //   }))
        
      // }
      // console.log({ ...srch, [item]: !srch[item]})
    }
    
  }

  const handleSubmit = async (sort, item, stat) => {
    // e.preventDefault();
    // console.log (sort, item, stat)

  }


  if (loading){ return ( <> <WebbSpinText /> </> ) }

  return (
    <>
      <HeaderSectionDark data={{name: 'Alerts and Notifications'}}/>
      <div className="border">
        {lsta && lsta.length > 0 && lsta.map((item, i) => (
          <div className="d-flex p-3 border-bottom" key={i}>
            <div className="me-auto">
              <p className="m-0 text-color-main">{item.name}</p>
              <p className="small text-tone m-0">{item.text}</p>
            </div>
            <div className="text-end">
              <div 
                className={`btn rounded-circle m-0 p-2 ${alrt[item.code] ? 'back-color-next' : 'back-color-lite'}`}
                style={{width:'2.4rem', height:'2.4rem'}}
                onClick={() => {handleChange("alrt", item.code ); handleSubmit("alrt", item.code, !alrt[item.code] )}}  
                ><i className={alrt[item.code] ? 'bi-check text-white' : 'bi-x text-tint'}></i>
              </div>
            </div>
          </div>
        ))}
      </div>


      <WebbDividerMedium />

      {/* <HeaderSectionDark data={{name: 'Search and Discover'}}/> */}
      <div className="border d-none">
        {lsts && lsts.length > 0 && lsts.map((item, i) => (
          <div className="d-flex p-3 border-bottom" key={i}>
            <div className="me-auto">
              <p className="m-0 text-color-main">{item.name}</p>
              <p className="small text-tone m-0">{item.text}</p>
            </div>
            <div className="text-end">
            <div 
                className={`btn rounded-circle m-0 p-2 ${srch[item.code] ? 'back-color-next' : 'bg-lite'}`}
                style={{width:'2.4rem', height:'2.4rem'}}
                onClick={() => {handleChange("srch", item.code ); handleSubmit("srch", item.code, !srch[item.code] )}}  
                ><i className={srch[item.code] ? 'bi-check text-white' : 'bi-x text-tint'}></i>
              </div>
            </div>
          </div>
        ))}
      </div>
      
      <WebbDividerMedium />
      <WebbDividerMedium />
    </>
  )
}