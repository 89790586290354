//estm
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import WebbDividerMedium from '../webb/webb-divider-md'
import WebbDividerSmall from '../webb/webb-divider-sm'
import WebbSpinText from '../webb/webb-spintext'

import { SmartStampDetails } from '../../services/srvc-estm-realm'


export default function StampViewModule() {

  const {id} = useParams()
  const [data,setData]= useState()

  const [loading,setLoading] = useState(true)

  useEffect(()=>{

    const fetchdata = async()=>{
      var res = (await SmartStampDetails (JSON.stringify({
        data:{smart:id}
      }))).data
    
            // console.log(res)
      setData(res)
      setLoading(false)  
    }
    fetchdata()
        
  },[])


  const ViewStampFile = async(item) => {

    if(item=="") {
        alert("Stamped File has not generated. Please try later")
        return
    } else {
        window.open(item)
    }  
  }


  if (loading){ return ( <> <WebbSpinText /> </> ) }

  return (
  <>
    {/* info */}
    {/* <WebbDividerSmall />
    <p className="text-lead text-bold m-0">{`E-STAMP`}</p>
    <p className="text-color-tone">{id}</p>
    <WebbDividerSmall /> */}

    {/* data */}
    <WebbDividerSmall />
    <div className='p-3 back-color-wite rounded-wd'>
      <p className='m-0'>Stamp Number</p>
      <p className='text-lead text-bold text-color-main'>{data.nmbr == "" ? "******" : data.nmbr}</p>
    
      <div className={data.nmbr === "" ? "" : ''}>
        <span onClick={() => ViewStampFile(data.link)} 
        style={{cursor:"pointer"}} 
        className={`m-0 ${data.stat=="6"? "p-2 px-3 rounded-wd back-color-lite hitone text-color-next": "" }`}>
        {data.link ? "Download File": "In Process"}
        </span>
      </div>

    </div>

    <WebbDividerSmall />
    <div className='p-3 back-color-wite rounded-wd'>
      <div className=''>
        <p className='m-0'>First Party</p>
        <p className='text-normal text-bold'>{data.frst.name}</p>
      </div>
      <div className=''>
        <p className='m-0'>Second Party</p>
        <p className='text-normal text-bold m-0'>{data.scnd.name}</p>
      </div>
    </div>

    <WebbDividerSmall />
    <div className='d-flex p-3 back-color-wite rounded-wd'>
      <div className='me-auto'>
        <p className=''>State</p>
        <p className=''>Consideration Amount</p>
        <p className='m-0'>Stamp Paper Value</p>
      </div>
      <div className='text-end'>
        <p className=''>{data.lost}</p>
        <p className=''>{data.amtc}</p>
        <p className='m-0'>{data.amts}</p>
      </div>
    </div>

    <WebbDividerSmall />
    <div className='d-flex p-3 back-color-wite rounded-wd'>
      <div className='me-auto'>
        <p className=''>Name</p>
        <p className=''>Request Number</p>
        <p className=''>Request Date</p>
        <p className='m-0'>Stamp Generated</p>
      </div>
      <div className='text-end'>
        <p className=''>{data.name}</p>
        <p className=''>{data.srvc || '******'}</p>
        <p className=''>{new Date(data.crts).toLocaleString()}</p>
        <p className='m-0'>{data.stat ==6 ? new Date(data.mots).toLocaleString() : '******'}</p>
      </div>
    </div>    

   </>
  )
}