import * as Realm from 'realm-web';

import axios from "axios";
import CryptoJS from 'crypto-js';


export var localMessageState = []

const  app = new  Realm.App({ id:"contrato-room-khdno"});

var url = "https://ap-south-1.aws.data.mongodb-api.com/app/contrato-room-khdno/endpoint"

var baseApi = "https://srvx.contrato.cc/xx"

export const AddMessageToRoom = async (item) => {
  
    const basx = baseApi + '/message.add';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      //console.log (error);
      return {data: false}
    }
  }


export const ReadRoomMessage = async (item) => {
  
    const basx = baseApi + '/message.read';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      //console.log (error);
      return {data: false}
    }
}

export const GetRoomDetails = async (item) => {
  
    const basx = baseApi + '/room.details';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      //console.log (error);
      return {data: false}
    }
}

export const GetRoomLive = async (item) => {

    const  user = await  app.logIn(Realm.Credentials.apiKey("xJ9dDm6P0V7o8Xb3wq32WCl95vjYiA8CO9RezoVX0rfQ0WndMSh1WJEk4cTxh0S6"));        
    const  mndb =  app.currentUser.mongoClient("mongodb-atlas");
    const  dbse =  mndb.db("trxnmodb9u24cohskemabswz").collection("mesgsuz24hdmuav"); 
    
    const filter = {
          "$match": {
            "$and": [
              { "room": item}
            ]
          }
        }
      
  
    // console.log (item)
    var data = {} // new document

    const pipeline = [ { $match: { "room": item } } ];
    for await (const docx of dbse.watch(pipeline))  {
          switch (docx.operationType) {
            case "insert": {
              const { documentKey, fullDocument } = docx;
              data = fullDocument; 
              var datx
            //   console.log(`new: ${documentKey.toString()}`, fullDocument);
              if(data.room == item)
              {
                // console.log("inside")
                var decrypted = CryptoJS.AES.decrypt(fullDocument.text, "contratotextencr")
                const str = decrypted.toString(CryptoJS.enc.Utf8);
                data.text = str
                 datx = {
                  text: str,
                  sndr: data.sndr,
                  recv: data.recv,
                  room: data.room,
                  crts: data.crts,
                  item: data.item
                } 
                return datx
              }
              break;
            }
           
          }
        }
  
    // for await (const docx of dbse.watch({
    //   filter:filter
    // })) {
    //   switch (docx.operationType) {
    //     case "insert": {
    //       const { documentKey, fullDocument } = docx;
    //       data = fullDocument; 
    //       var datx
    //       console.log(`new: ${documentKey.toString()}`, fullDocument);
    //       if(data.room == item)
    //       {
    //         // console.log("inside")
    //         var decrypted = CryptoJS.AES.decrypt(fullDocument.text, "contratotextencr")
    //         const str = decrypted.toString(CryptoJS.enc.Utf8);
    //         data.text = str
    //          datx = {
    //           text: str,
    //           sndr: data.sndr,
    //           recv: data.recv,
    //           room: data.room,
    //           crts: data.crts,
    //           item: data.item
    //         } 
    //         return datx
    //       }
    //       break;
    //     }
       
    //   }
    // }
  }

export const GetLocalMessages = async () => {

      return localMessageState
}

export const GetMyRooms = async (item) => {

  const basx = url + '/room/lists';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = item

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    //console.log (error);
    return {data: false}
  }
  
}

export const RoomCreateForContact = async (item) => {

  const basx = url + '/room/create';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = item

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    //console.log (error);
    return {data: false}
  }
  
}


export const GetRoomNotification = async (item) => {

  const  user = await  app.logIn(Realm.Credentials.apiKey("xJ9dDm6P0V7o8Xb3wq32WCl95vjYiA8CO9RezoVX0rfQ0WndMSh1WJEk4cTxh0S6"));        
  const  mndb =  app.currentUser.mongoClient("mongodb-atlas");
  const  dbse =  mndb.db("trxnmodb9u24cohskemabswz").collection("roomsuz24hdmuav"); 

  for await (const docx of dbse.watch())  {
        switch (docx.operationType) {
          case "update": {
            const { documentKey, fullDocument } = docx;
            // console.log(`new: ${documentKey.toString()}`, fullDocument);
            return fullDocument
            break;
          }
         
        }
      }

  
}

