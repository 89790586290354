import React, { useState } from "react";
import { useHistory } from 'react-router-dom'
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbModuleInfo from "../webb/webb-module-info";
import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import { CheckPasscode, CreatePasscode } from "../../services/srvc-code-realm";
import { SetAuthUser } from "../../services/srvc-auth-user";
import { useEffect } from "react";
import { signInAnonymously } from "firebase/auth";
import { auth } from '../../services/firebase'


export default function AuthMailOTPModule () {

  const history=useHistory()

  const [username, setUsername] = useState("");
  const [done, setDone] = useState(false);
  const [text, setText] = useState("");
  const [otp, setOtp] = useState('')
  const [trxn,setTrxn] = useState('')
  const [err, setErr] = useState('')

  const [loading, setLoading]=useState(false);

  const handlePasscode = async () => {
    
    setLoading(true)
    // localStorage.clear();
      // alert(`please check your mail at ${username}`);
      // localStorage.setItem('authmail',username)
      setText (`OTP has been sent to your email`)

      var res = await CreatePasscode({email:username})
      // console.log(res)
      setTrxn(res.trxn)

      setDone(true)
    
    
    setLoading(false)
  
  }

  const resendPasscode = async() => {

    var res = await CreatePasscode({email:username})
      // console.log(res)
      setTrxn(res.trxn)

      setDone(true)
      setText (`OTP has been Re-Sent to your email`)
    
    
    setLoading(false)

  }

  const CheckPassCode = async()=>{
    setLoading(true)

    const res = await CheckPasscode({email:username,trxn:trxn,otp:otp})
    // console.log(res)
    if(res.status==true)
    {
       await signInAnonymously(auth)
          .then((data) => {
            // Signed in..
            // console.log(data)
            SetAuthUser({user: username})
            history.push('/auth/next')
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
           // console.log(errorMessage)
            // ...
          });
      
    }
    if(res.status==false)
    {
      setErr(res.memo)
      
    }
    setLoading(false)

  }



  const handleSubmit = async e => {
    e.preventDefault();    

  };

  if (loading) 
  return (<> <WebbLoader /> </>)

  return (
  <>
    {/* info */}  
    <WebbDividerSmall />
    <WebbModuleInfo data={{text: 'Get Authentication Link'}} />

    {/* main */}    
    <form onSubmit={handleSubmit}>
      
      <div className={``}>
        <p className={done?'  text-primary':'d-none'}>{text}</p>
      
        <div className="mb-3">
          <label className="form-label small">Email Address <FormNeeded/></label>
          <input type="text" 
            
            className="form-control height-md rounded-wd" 
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={username}
            onChange={({ target }) => {setUsername(target.value); setText('');}}
            placeholder="user@mail.com">
          </input>
        </div>

        <div className="mb-3">
        <label className="form-label small">Enter OTP <FormNeeded/></label>
        <input type="text" 
            className="form-control height-md rounded-wd" 
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={otp}
            disabled={!done}
            onChange={({ target }) => {setOtp(target.value); setErr('')}}
            placeholder="123456">
        </input>
        </div>
        
        {/* action */}
        <div className={` ${done?'d-none':''} mb-3 d-grid`}>
          <button
            disabled={username===''}
            className="btn btn-primary back-color-main height-md rounded-wd mt-4"
            onClick={()=>handlePasscode()}
            ><small>Get OTP</small>
          </button>
          
        </div>
        <p className="text-danger mt-3 small">{err}</p>
        <div className={` ${done?'':'d-none'}  mb-3 d-grid`}>
        <button
            disabled={otp===''}
            className="btn btn-primary back-color-main height-md rounded-wd w-100 mt-2"
            onClick={()=>CheckPassCode()}
            ><small>Login</small>
        </button>
        <button onClick={()=>resendPasscode()} className="btn btn-link w-50 text-start mt-3" style={{textDecoration:'none'}} ><p className="small"> Resend OTP </p></button>
        </div>
      
      </div>

    </form>  

    


    <div className={done ? '' : 'd-none'}>        
      <div className="text-center p-1" 
        onClick={() => {setDone(false); setUsername('')}}
        style={{cursor:'pointer'}}
        >
        <p className="text-color-tone mt-4">Try Again / Use Different Email</p>
      </div>

    </div>

  </>
  )
}