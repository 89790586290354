//main
import { useEffect, useState } from "react";

import ContentFormatXA from "../content/webc/content-format-xa";

import WebbDivider from "../content/webb/webb-divider";
import WebbDividerSmall from "../content/webb/webb-divider-sm";
import WebbDividerMedium from "../content/webb/webb-divider-md";
import HeaderSectionLite from "../content/webb/head-section-lite";

import InvoicesSearchModule from "../content/invoices/invoices-search";
import InvoicesListModule from "../content/invoices/invoices-list";

import NewInvoiceModule from "../content/invoices/invoices-create";
import NewOrderModule from "../content/orders/orders-create";
import NewInvoiceRulesModule from "../content/orders/rules-create";

import InvoicesDownloadModule from "../content/invoices/invoices-download";
import { UserForm } from "../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../services/srvc-auth-user";
import { AccountBalance } from "../services/srvc-accounts-realm";
import { useHistory } from "react-router-dom";


const listTabs = [
  {name: 'Recievables', code: 'inbound'},
  {name: 'Payables', code: 'outbound'}
]


export default function Invoices() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const history = useHistory()

  const [sort, setSort] = useState('inbound')
  const [search, setSearch] = useState({})
   const [balance, setBalance] =useState()
  const [loading,setLoading] = useState(true)

  
  // useEffect( () => {
  //   setLoading(true)
  //   if (asset){
  //     const fetchData = async() => {
  //       const result = (await AccountBalance({ 
  //         data: { user: asset.user, type: "base-account" },
  //         user: asset.user
  //       })).data;
  //       // console.log(result)
  //       if(result==false)
  //       {
  //         history.push(`/${asset.form.substring(0,2)}/accounts/subscription`)
  //         return
  //       }
  //       if( parseFloat(result.balance.number)/1000000 < 100)
  //       {
  //         history.push(`/${asset.form.substring(0,2)}/accounts/subscription`)
  //         return
  //       }
  //       // setAcnt(result)
  //       setLoading(false)
  //     }
  //     fetchData()
  //   } else {}
  // },[]);




  const handleSearch = async(sort) => {
    // console.log('srch: ', sort)
    setSearch(sort);
  }

  
  return (
  <>
    <ContentFormatXA 

    sidebar=
    {{
      head: <> <strong>Invoices</strong> </>,
      data: 
        <> 
          <HeaderSectionLite data={{name: 'Search Invoices'}}/>
          <InvoicesSearchModule  search={handleSearch}/>

          
        </>,
      foot: <> Today: </>
    }}
    main=   
      {{      
        head: <> Invoices List </>,
        data: 
          <> 
            {/* navs */}
            <div className="d-flex justify-content-start">
              {listTabs && listTabs.length > 0 && listTabs.map((item, i) => ( 
                
                <span className={`text-mini text-uppercase p-2 px-3 me-1 rounded-wd text-center cursor
                  ${sort === item.code ? 'back-color-next text-color-wite': 'back-color-lite text-color-tone'}`}
                  key={i} style={{fontSize:'0.7rem'}} 
                  onClick={() => setSort(item.code)}
                  >{item.name}</span>

              ))}
            </div>
            
            <WebbDividerMedium />

            <InvoicesListModule  data={search || {} } />
          
          </>,
        foot: <> </>,
      }}
    actionbar=
      {{      
        head: <> Activity </>,
        data: 
          <div >
            <div>
            <NewInvoiceModule />
            <NewOrderModule />
            <InvoicesDownloadModule />
            <hr></hr>
            <NewInvoiceRulesModule />
            </div>
          </div>,
        foot: <> </>,
      }}    
    
    />
  </>
  )
}