//main
import BusinessAccountCreateModule from "../content/accounts/accounts-bznx-create";
import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";

export default function BusinessAccountCreateView() {

  return (
  <>
    <ContentFormatXX 
      head='Create Business Account'
      link={`/${UserForm()}/settings`}
      form= 'small'
      data= {
        <>
          <BusinessAccountCreateModule />
        </>
      } 
    />
  </>
  )
}