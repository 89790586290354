// create new contact
import { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import WebbModuleInfo from "../webb/webb-module-info";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { BusinessTeamDetails, BusinessTeamUpdate, ContactInfoUpdate, GetContactDetail } from "../../services/srvc-contacts-realm";

const list = require('../../data/data-states-list.json').data;

export default function TeamEditInfoModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const history = useHistory();
  const { id } = useParams();

  const [loading, setLoading]=useState(false)
  const [form, setForm]=useState(false);
  const [text, setText] = useState("");
  const [newName,setNewName] = useState('')
  
  const [data, setData] = useState({
   info:{
    name: '',
    mmid: '',
    mail: '',
   }, 
    loct: '',
    lost: '',
    locn: 'India',
  });


  useEffect( () => {
    if (asset){
      setLoading(true)
      const fetchData = async() => {
        var datx = {
          data: { item: id },
          user: asset.user,
          webb: process.env.REACT_APP_WEBB_SITE_NMBR
      }
      // console.log(datx)
      var res = (await BusinessTeamDetails(datx)).data
      // console.log(res)
        setData(res)
        setLoading(false);
        setNewName(res.info.name)
      }
      fetchData()
    } else {}
  },[asset.user]);

  // useEffect for form validation
  useEffect( () => {
    setForm(false);
    if (data.info.name!=='' && data.info.mmid!=='' && data.info.mail!=='' ) 
      setForm(true);
  },[data]);

  const handleChange = async(key, val) => {
    setForm(false);
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
 
    if (form) {
      setLoading (true);
      
      var datx = {
        data: {item:id, name:newName },
        user: asset.user,
        webb: process.env.REACT_APP_WEBB_SITE_NMBR
      }
      //console.log (datx)
      // api call
      const res = await BusinessTeamUpdate(datx)
     // console.log (res)
      if (res!= false) {
        history.push(`/${asset.form.substring(0,2)}/team/view/${id}`);
      }
      else {
        // update failed
      }
      setLoading(false)
    }
    else {
      setText ('Please Enter all details')
    }
  }

  if (loading) { return ( <> <WebbLoader /> </> ) }

  return (
  <>
    
    {/* info */}
    <WebbDividerSmall/>
    <WebbModuleInfo data={{info: 'Edit Contact Details and Save'}}/>
      
    {/* input */}
    <form onSubmit={handleSubmit}>
      <div className="">

        <div className="form-group mb-3">
          <label className="form-label small">Full Name <FormNeeded/></label>
          <input type="text" 
            className="form-control height-md bg-wite"
            style ={{fontSize:'0.9rem', height:'2.7rem'}}
            value={newName}
            onChange={( e ) => setNewName(e.target.value)}
            placeholder="Enter Full Name">
          </input>
        </div>

        <div className="form-group mb-3">
          <label className="form-label small">Mobile Number <FormNeeded/></label>
          <input type="text" 
            className="form-control height-md bg-wite"
            style ={{fontSize:'0.9rem', height:'2.7rem'}}
            disabled
            value={data.info.mmid}
            onChange={({ target }) => {handleChange("mmid", target.value); setText('');}}
            placeholder="9876540123">
          </input>
        </div>

        <div className="form-group mb-3">
          <label className="form-label small">Email Address <FormNeeded/></label>
          <input type="text" 
            className="form-control height-md bg-wite"
            style ={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.info.mail}
            disabled
            onChange={({ target }) => {handleChange("emid", target.value); setText('');}}
            placeholder="user@mail.com">
          </input>
        </div>

        {/* <div className="form-group mb-3">
          <label className="form-label small">City <FormNeeded/></label>
          <input type="text" 
            className="form-control height-md bg-wite"
            style ={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.loct}
            onChange={({ target }) => {handleChange("loct", target.value); setText('');}}
            placeholder="City">
          </input>
        </div>

        <div className="form-group mb-3">
          <label className="form-label small">State <FormNeeded/></label>
          <select 
            className="form-select height-md bg-wite"
            style ={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.lost}
            onChange={({ target }) => handleChange("lost", target.value)}
          >
            <option value=''>Select State</option> 
            {list.map((item,i) => (
              <option key={i} value={item.name}>{item.name}</option>
            ))}
          </select>
        </div>

        <div className="form-group mb-3">
          <label className="form-label small">Country <FormNeeded/></label>
          <input type="text" 
            className="form-control height-md bg-wite"
            disabled
            style ={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.locn}
            onChange={({ target }) => {handleChange("locn", target.value); setText('');}}
            placeholder="Country">
          </input>
        </div> */}


      </div>

      {/* text */}
      <div className="mb-3">
        <p className="small text-danger">{text}<span className="text-white">{'.'}</span></p>
      </div>

      {/* actn */}
      <WebbDividerMedium />
      <div className="">
        <div className="d-grid">
          <button className={`btn height-md btn-primary back-color-main`}
            disabled={!form}
          ><small>Save & Continue</small>
          </button>
        </div>
      </div>


    </form>

    <WebbDividerMedium/>
    <WebbDividerMedium/>
  </>
  )
}