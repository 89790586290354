// transfers
import {Link} from "react-router-dom";
// import gears from '../../media/process.gif';

import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";

import { UserForm } from "../../services/srvc-utilities";

export default function InvoicesSubmitModule() {

  return (
  <>
    <WebbDividerSmall/>

    <div className="text-center bg-wite rounded py-3 mb-3">
      {/* <img src={gears} width="150" alt="..."></img> */}
      <p className="lead fw-bold m-0 p-0 text-success">Invoice Action Submitted</p>
    </div>

    <div className="row row-cols-2 g-3">

      <Link to={`/${UserForm()}/invoices/create`} >
        <div className="col flex-fill bg-wite rounded p-3 small text-center hilite">
          New Invoice
        </div>
      </Link>

      <Link to={`/${UserForm()}/home`}>
        <div className="col flex-fill bg-wite rounded p-3 small text-center hilite">
          Go Home
        </div>
      </Link>

    </div>

    <div className="py-3 border-bottom"></div>
    <WebbDividerMedium/>
    <div className="">
      <p className="small">Invoice Status will be updated automatically and details are saved in your transactions history.</p>
      <p className="small">If Payment Status is Pending, it will be updated automatically.</p>
      <p className="small">If Payment Failed or Cancelled, the status will be updated automatically. You can re-initiate the payment again</p>

    </div>

    <WebbDividerMedium/>
    <WebbDividerMedium/>
  </>
  )
}