// action
import ActionCardSmall from "../webb/ctas-actioncard-sm";
import ActionCardMedium from "../webb/ctas-actioncard-md";

import { UserForm } from "../../services/srvc-utilities";

export default function NewContactModule() {
 
  const data = {
    name: 'Add Team Member',
    text: 'Add Single Member',
    link: `/${UserForm()}/contacts/new/team`,
    actv: true
  }
 
  return (
  <>
    <ActionCardMedium data={data}/>
  </>
  )
}