// documents
import { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { toBase64 } from "../../services/srvc-utilities";
import { GetSmartFeat, SetSmartFeat, SmartGetInfo } from "../../services/srvc-smart-realm";
import { CollectKYC } from "../../services/srvc-form-realm";

const list = require('../../data/list-document-feat.json').data

export default function SmartsEditFeatModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const { id } = useParams();
  const history = useHistory();

  const [loading,setLoading]=useState(true);

  const [form, setForm] = useState(false);
  
  const [info, setInfo] = useState({});
  const [data, setData] = useState([]);
  const [file, setFile] = useState();

  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        
        // basic info

        const datx = {
          data: { smart: id },
          user: asset.user,
        }

        var res = (await SmartGetInfo(JSON.stringify(datx))).data
       setInfo(res)

        const feat = ( (await GetSmartFeat(JSON.stringify({
          data: { document: id },
          user: asset.user,
          webb: process.env.REACT_APP_WEBB_SITE_NMBR
        }))).data );
        
        setData(Array.from(list, item => {return {
          ...item, stat: feat[item.code]
        }} ))

        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset.usid]);

  const handleSign = async(file) => {
    var str1 = await toBase64(file);
    // console.log(str1)
    setFile(str1)
  }

  const SetFeature = async(z, v) => {
    // console.log({[data[z].code]: v})

    var payload = {
      data: { document: id, feature: {[data[z].code]: v} },
      user: asset.user,
      webb: process.env.REACT_APP_WEBB_SITE_NMBR
    }

    // console.log(payload)

    const res = await SetSmartFeat(JSON.stringify(payload))
    // console.log(res)
  }

  const handleChange = (name, vals, indx) => {
    // edit array-object value
    setData(data => data.map((item, i) => (indx === i ? { ...item, [name]: vals } : item)));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    

    var payload={
      data:{
          document: id,
          user: asset.user,   
      },
      user: asset.user
    }

    var res = await CollectKYC(JSON.stringify(payload))

    // console.log(res)

    history.push(`/${asset.form.substring(0,2)}/smart/esin/send/${id}`)
  }

  if (loading){ return ( <> <WebbLoader /> </> ) }

  return (
  <>
    {/* info */}
    <WebbDividerSmall />
    <p className="text-lead text-bold m-0">{info.name}</p>
    <p className="text-color-tone">{id}</p>
    <WebbDividerSmall />
    
    <p className="">Please Set Document Features</p>
    <WebbDividerSmall />

    {/* data */}
    <form onSubmit={handleSubmit}>

      <div className="">

      {data && data.length > 0 && data.map ((item, i) => (item.actv ?
        <div className={`p-3 back-color-wite cursor hitone ${i<list.length-1 ? 'border-bottom' : ''}`} key={i}>
          <div className="">
            <div className="d-flex" onClick={() => {handleChange("stat", !data[i].stat, i); SetFeature(i, !data[i].stat )}}>
              <div className="me-auto">
                <p className="m-0 fw-bold">{item.name}</p>
                <p className="m-0">{item.memo}</p>
              </div>
              <div className="text-end py-1 mt-1">  
                <i className={data[i].stat 
                  ? 'lead bi-check-circle-fill text-color-success' 
                  : 'lead bi-x-circle-fill text-color-tint'}>
                </i>
              </div> 
            </div>
          </div>
        </div>
      :''))}

      </div>

      {/* esin file */}
      <div className={`form-group mb-3 ${data[5].stat ? 'd-none' : 'd-none'}`}>
        <label className="form-label small">Upload Signature <FormNeeded /></label>
          <div className="p-1 bg-white rounded border"  style={{fontSize:'0.9rem'}}>
            <div className="input-group" >
              <input 
                type="file" 
                className="form-control border-none" 
                style={{fontSize:'0.9rem'}}
                onChange= { (e) => {handleSign(e.target.files[0]); }} 
                placeholder="..." >  
              </input>
            </div>
          </div>

      </div>


    </form>

    <WebbDividerMedium />
    {/* form */}
    <form onSubmit={handleSubmit}>

      <div className="">
        <div className="d-grid">
          <button className={`btn height-md btn-primary back-color-main rounded-none`}
          ><small>Save & Continue</small>
          </button>
        </div>
      </div>

    </form>


    <WebbDividerMedium />
    <WebbDividerMedium />
    
  </>
  )
}