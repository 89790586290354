import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { AccountBalance, BaseAccountBalance } from '../../services/srvc-accounts-realm';
import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-user';
import { UserForm } from '../../services/srvc-utilities';
import NextLogout from '../webb/next-logout';
import WebbDividerMedium from '../webb/webb-divider-md';
import WebbLoader from '../webb/webb-loader';

const SubscriptionCheckModule = () => {

    const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
    const history = useHistory();
        
    const [loading, setLoading] = useState(true);
    const [account,setAccount] = useState(false)
    const [data, setData] = useState({account:{number:'',name:'', ifsc:''}, balance:{number:''}})
    
    useEffect( () => {
        setLoading(true)
        if (asset){
          const fetchData = async() => {
    
            const result = (await BaseAccountBalance({ 
              data: { user: asset.user, type: "base-account" },
              user: asset.user
            })).data;
            // console.log(result)
            if(result==false)
            {
                setAccount(false)
    
            }

            else{
                setAccount(true)
                setData(result)
                
                if(parseFloat(result.balance.number)/1000000 >= 100)
                {
                    history.push(`/${asset.form.substring(0,2)}/home`)
                }
                // 
            }
            setLoading(false)
          }
          fetchData()
        } else {}
      },[asset.user]);

      if (loading){ return ( <> <WebbLoader /> </> ) }

    //   console.log(account)

  return (
    <>

    <div className={account ?'':'d-none'}>
        <WebbDividerMedium />
        <div>
            <p className='text-normal'> You have less credit balance in your subscription account. Please recharge your subscription account to access the dashboard and other services.</p>
        </div>
        <WebbDividerMedium />
        <h6 className=''>Subscription Account Details</h6>
        <div className='border d-flex p-3 bg-white mt-1 rounded'>
            <div className='me-auto'>
                <p className='text-sm'>Account Holder Name :</p>
                <p className='text-sm'>Account Number :</p>
                <p className='text-sm'>Account IFSC Code :</p>
                <p className='text-sm'>Current Credit Balance :</p>

            </div>
            
            <div className='text-end'>
                <p className='text-sm'>{data.account.name}</p>
                <p className='text-sm'>{data.account.number}</p>
                <p className='text-sm'>{data.account.ifsc}</p>
                <p className='text-sm'>{parseFloat(data.balance.number)/1000000}.00</p>
            </div>
        </div> 

           
    </div>

    <div className={!account?'':'d-none'}>
        <h5 className='text-danger mt-4'>We are Sorry For Your Inconvenience.</h5>
        <p className='text-small text-dark mt-4'>Your subscription account has not been set yet. </p>
        <p className='text-small text-dark'>We request you to contact our support teams at sales@contrato.cc</p>
        <p className='text-small text-dark mt-1'>Thanks for Using Contrato, We are happy to help you out best possible way in buiding trust with business.</p>

    </div>
    <WebbDividerMedium />
    <button onClick={()=>history.push(`/${asset.form.substring(0,2)}/home`)} className='btn btn-link m-0 p-0 text-decoration-none'>Skip For Now</button>
    {/* <NextLogout /> */}


    </>
  )
}

export default SubscriptionCheckModule