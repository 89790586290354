// action
import ActionCardSmall from "../webb/ctas-actioncard-sm";

import { UserForm } from "../../services/srvc-utilities";

export default function NewInvoiceRulesModule() {

  const data = {
    name: 'Authorization',
    link: `/${UserForm()}/invoices/rules`,
    actv: false
  }

  return (
  <>
    <ActionCardSmall data={data}/>
  </>
  )
}