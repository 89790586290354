//main
import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import TransfersSubmitModule from "../content/transfers/transfers-submit";

export default function TransfersSubmit() {

  return (
  <>
    <ContentFormatXX 
      head='Transaction Submitted'
      link={`/${UserForm()}/transfers`}
      form= 'small'
      data= {
        <>
          <TransfersSubmitModule />
        </>
      } 
    />
  </>
  )
}