// action
import ActionCardSmall from "../webb/ctas-actioncard-sm";
import ActionCardMedium from "../webb/ctas-actioncard-md";

import { UserForm } from "../../services/srvc-utilities";

export default function NewContactBulkModule() {
 
  const data = {
    name: 'Add Contacts (Bulk)',
    text: 'Upload Excel File',
    link: `/${UserForm()}/contacts/new/bulk`,
    actv: true
  }

  return (
  <>
    <ActionCardMedium data={data}/>
  </>
  )
}