//avatar
import { Link } from "react-router-dom";
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import avtx from '../../media/user.png';

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

export default function UserAvatar() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  
  return (
    <>
      <div className="btn-group d-none d-md-block">
        
        <Link className="pt-4" to="#" 
          role="button" id="dropdownMenuLink" 
          data-bs-toggle="dropdown" aria-expanded="false">
          <Jazzicon diameter={37} seed={jsNumberForAddress(asset.user ??= Date.now().toString())} /> 
        </Link>

        <ul className="dropdown-menu p-0 rounded-wd shadow-lg" aria-labelledby="dropdownMenuLink">
          <div className="back-color-next p-3 rounded-wd text-white"
            style={{width:'222px', borderBottomLeftRadius:'0', borderBottomRightRadius:'0'}}>
            <p className="text-bold m-0 p-0 text-sm">
              {asset ? asset.name : '******'}
            </p>
            <p className="small m-0 p-0">
             {asset ? `${UserForm() === 'in' ? 'Personal' : 'Business'}` : '******'} Account
            </p>
          </div>
          <li><Link className="dropdown-item small py-2" to={`/${asset.form.substring(0,2)}/settings`}>Settings</Link></li>
          <li><Link className="dropdown-item small py-2" to={`/auth/next`}>Switch Account</Link></li>
          <li><Link className="dropdown-item small py-2" to={"/auth/x"}>Logout</Link></li>
        </ul>
      </div> 


      <div className="d-md-none">
        <Link to={`/${asset.form.substring(0,2)}/settings`}>
          <Jazzicon diameter={27} seed={jsNumberForAddress(asset.user ??= Date.now().toString())} /> 
        </Link>
      </div>

    </>
  )
}