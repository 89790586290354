// next intro

import WebbDividerSmall from "./webb-divider-sm"

const stat =[
  {
    stat:'new', 
    name:'Welcome Onboard', shrt:'New Account', 
    desc:'Please use the Setup Account Link below to setup your account.', 
    icon:'bi-gift', colr:'text-success'
  },
  {
    stat:'incomplete', 
    name:'Profile Incomplete', shrt:'Incomplete Account Information', 
    desc:'Please use the Update Account Information Link below to complete your account setup.', 
    icon:'bi-exclamation-circle', colr:'text-danger'
  },
  {
    stat:'review', 
    name:'Account In Review', shrt:'We are reviewing your account information', 
    desc:'Once your account review is completed, we will notify you of the same.\n\n\t Our Team will reach you soon via mail for further discussion', 
    icon:'bi-shield-fill-check', colr:'text-color-main'
  },
  {
    stat:'hold', 
    name:'Account On Hold', shrt:'We are reviewing your account information', 
    desc:'Once your account review is completed, we will notify you of the same.', 
    icon:'bi-shield-fill-exclamation', colr:'text-warning'
  },  
]

// const media = 'https://img.freepik.com/free-photo/businessman-draw-growth-graph-progress-business-analyzing-financial_34200-419.jpg?w=900'

const media = "https://img.freepik.com/free-vector/business-people-signing-online-contract-with-electronic-sign_74855-4379.jpg"

export default function NextIntro(props) {
 
  const next = stat.find (item=> item.stat === props.stat)

  return (
  <>
    <div className="back-color-wite">
      
      <img src={media} className='img-fluid' alt='...'></img>
      <div className="d-flex p-3">
        <div className="">
          <i className={`${next.icon} ${next.colr}`}  
            style={{fontSize:"2.4em", }}>
          </i>
        </div>
        <div className="ps-3">
          <p className="lead m-0 p-0 pt-2" style={{lineHeight:'1'}}>{next.name}</p>
          <small className="text-muted">{next.shrt}</small>
        </div>
        
      </div>    
      
    </div>
    
    <WebbDividerSmall/>

    <div className="">
      <p className="">{next.desc}</p>
      <br/>
      <p>If you want to reach us directly please drop a mail on sales@contrato.cc and we will respond back within 24 hours</p>
    </div>

  </>
  )
}