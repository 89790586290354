// contact detail view
import { Link } from 'react-router-dom';

import { crnc } from '../../services/srvc-utilities';

const code = require('../../data/code-transfers.json').data;

export default function ContactTransfers(props) {

  const data = props.data;
  
  if (data.length===0) 
  return (
  <> 
    <p className='text-tone m-0'>No Recent Transfers</p> 
    <Link to={'/'} className='small'>All Transfers</Link> 
  </>
  )

  return (
  <>
    <div className="border bg-white rounded">
    {data && data.length >0 && data.map ((item, i) => (
      <div key={i}>
      <div className="d-flex p-3 " >

        <div className="">
          <p className="m-0 p-0 fw-bold">{item.resn}, {item.cmnt}</p>
          <p className="m-0 p-0 small">
            {/* account number, type */}
          </p>
          <p className="m-0 p-0 small">
            <span>{item.crts.substr(0,10)}</span>
            <span>{', '}</span>
            <span>{item.crts.substr(11,5)}</span>
          </p>
        </div>
        
        <div className="ms-auto text-end">
          {/* amount */}
          <p className="m-0 p-0 fw-bold">
            <span className="pe-1">{item.crdb==='C' ? '+' : '-'}</span>
            <span>{crnc(item.amnt)}</span>
          </p>
          
          {/* status */}
          <p className="m-0 p-0 small">
            <span>{code.find(x => parseInt(x.code) === item.stat).text}</span>
            <span className="pe-1"></span>
            <span className="small">
              <i className={`bi-circle-fill text-${code.find(x => parseInt(x.code) === item.stat).colr}`}></i>
            </span>
          </p>
        </div>

      </div>
      <div className={i < data.length-1 ? 'border-bottom' : ''}></div>
      </div>
    ))}
    </div>

  </>
  )
}