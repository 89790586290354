//main
import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import TransfersViewModule from "../content/transfers/transfers-view";

export default function TransfersView() {

  return (
  <>
    <ContentFormatXX 
      head='Transfer Details'
      link={`/${UserForm()}/transfers`}
      form= 'small'
      data= {
        <>
          <TransfersViewModule />
        </>
      } 
    />
  </>
  )
}