// transfers
import { useEffect, useState } from "react";
import { Link, useParams, useHistory } from 'react-router-dom';

import IconSelectCard from "../webb/ctas-iconselectcard";
import WebbLoader from "../webb/webb-loader";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import HeaderSectionLite from "../webb/head-section-lite";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { SmartGetInfo, SmartSetEsinLocation } from "../../services/srvc-smart-realm";

const list = require('../../data/list-esin-location.json').data.filter(item => item.actv);


export default function SmartsEsinLocationModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const { id } = useParams();
  const history = useHistory()
  
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState(false);
  
  const [info, setInfo] = useState({});
  const [data, setData] = useState({code:''});

  useEffect( () => {
    if (asset){
      const fetchData = async() => {

        // basic info
       const datx = {
          data: { smart: id },
          user: asset.user,
        }

        var res = (await SmartGetInfo(JSON.stringify(datx))).data
        setInfo(res)

        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset.user]);

  // form validation
  useEffect( () => {
    setForm(false)
    if (data.code !== '' ) setForm(true);
  },);


  const handleSelect = async(sort) => {
    handleChange('code', sort);
    console.log('item: ', sort);
  }


  const handleChange = async(key, val) => {
    setForm(false);
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // api call
    var payload ={
      data: {
        document: id,
        location: data.code,
        page_no: []
      },
      user: asset.user,
      webb: process.env.REACT_APP_WEBB_SITE_NMBR
    } 
    // console.log(payload)
    const res = await SmartSetEsinLocation(JSON.stringify(payload))
    
    if (res.data) history.push(`/${asset.form.substring(0,2)}/smart/edit/feat/${id}`)
    else alert('Error, Please try again !')
    setLoading(false);
  }

  if (loading){ return ( <> <WebbLoader /> </> ) }

  return (
  <>
    {/* info */}
    <WebbDividerSmall />
    <p className="text-lead text-bold m-0">{info.name}</p>
    <p className="text-color-tone">{id}</p>

    <WebbDividerSmall />
    <p className="text-tone">Please select an option</p>

    {/* data */}
    <WebbDividerSmall />
    <div className="">
      {list && list.length > 0 && list.map ((item, i) => (
        <div className={`p-3 back-color-wite hitone ${i<list.length-1 ? 'border-bottom' : ''}`} key={i}>
            <div className="d-flex cursor" onClick={() => {handleChange('code', item.code)}}>
              <div className="me-auto">
                <p className="m-0 fw-bold">{item.name}</p>
                <p className="m-0">{item.text}</p>
              </div>
              <div className="text-end py-1 mt-1">  
                <i className={item.code === data.code 
                  ? 'lead bi-check-circle-fill text-color-success' 
                  : 'lead bi-x-circle-fill text-color-tint'}>
                </i>
              </div> 
          </div>
        </div>
      ))}
    </div>


    <div className="row row-cols-3 d-none">
    {list && list.map((item, i) => (
      <div className="col text-center mb-3" key={i}>
        
        <div 
          className={`p-2 ${item.code === data.code ? 'back-color-next text-white' : ''}`}
          style = {{cursor:'pointer'}}
          onClick={()=>handleChange('code', item.code)}
        >
          <p className="fw-bold m-0">{item.name}</p>
          <p className="small m-0">{item.text}</p>
        </div>
        
      </div>
    ))}
    </div>

    <WebbDividerMedium />
    {/* form */}
    <form onSubmit={handleSubmit}>

      <div className="">
        <div className="d-grid">
          <button className={`btn height-md btn-primary back-color-main rounded-none`}
            disabled={!form}
          ><small>Save & Continue</small>
          </button>
        </div>
      </div>

    </form>


    <WebbDividerMedium />
    <WebbDividerMedium />

  </>
  )
}