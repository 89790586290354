// user
import { useEffect, useState } from "react";

import WebbDivider from "../webb/webb-divider";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import WebbLoader from "../webb/webb-loader";
import WebbSpinText from "../webb/webb-spintext";
import HeaderSectionDark from "../webb/head-section-dark";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";
import { BusinessAccountBalance, BusinessAccountDetails, getMainAccountLists} from "../../services/srvc-accounts-realm";
import { useHistory } from "react-router-dom";

// import { GetUserTransfers, SetUserTransfers } from "../../services/srvc-user-realm";

const list = require('../../data/list-user-transfers.json').data

export default function UserAccounts () {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const history = useHistory()
  
  const [loading, setLoading]=useState(false);

  const [trxn, setTransfer] = useState({});
  const [data, setData] = useState({});

  const [baseAc, setBaseAc] = useState({account:{nmbr:'', brch:''}})
  const [balance, setBalance] = useState('-')
  const [mainAc, setMainAc] = useState()

  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        setLoading(true);
        

        const base = await BusinessAccountDetails({
          data: {user: asset.user, type:"transfer-account"}, 
          user: asset.user,
        })
        //console.log(base)
        if(base.data!=false){setBaseAc(base.data)}


        const bal = await BusinessAccountBalance({
          data: {user: asset.user, type:"transfer-account"}, 
          user: asset.user,
        })
        // console.log(bal)
        if(bal.data!=false){ setBalance(parseFloat(bal.data.balance.number)/1000000)}
       

        setLoading(false);
      }
      fetchData()
    } else {}
  },[]);

  useEffect(()=>{
    setLoading(true)
    const fetchdata = async()=>{
      var datx= {
        data:{
          user:asset.user,
          type:'main-account'
        },
        webb:'',
        user:asset.user,

      }
      var data= await getMainAccountLists(datx)
       //console.log(data)
      if(data.status=="success")
      {
        setMainAc(data.data)
      }
      
    }
    fetchdata()
    setLoading(false)

  },[asset.user])


  const handleChange = async(sort, item) => {
    
    if (sort === 'trxn') {
      
      if (item !== 'bulk' ) alert('Premium Feature. Coming Soon')
      else {
        setTransfer({ ...trxn, [item]: !trxn[item] });
        // const res = await SetUserTransfers(JSON.stringify({
        //   data: {
        //     trxn: { ...trxn, [item]: !trxn[item] },
        //     usid: asset.usid,
        //     form: asset.form === 'us' ? 'indx' : 'bsnx'
        //   },
        //   user: asset.usid,
        //   webb: process.env.REACT_APP_WEBB_SITE_NMBR
        // }))
        
      }
      console.log({ ...trxn, [item]: !trxn[item]})
    }
    
    if (sort === 'data') {
      
      if (item !== 'maar' ) alert('Premium Feature. Coming Soon')
      else {
        setData({ ...data, [item]: !data[item] });
        // const res = await SetUserTransfers(JSON.stringify({
        //   data: {
        //     trxn: { ...trxn, [item]: !trxn[item] },
        //     usid: asset.usid,
        //     form: asset.form === 'us' ? 'indx' : 'bsnx'
        //   },
        //   user: asset.usid,
        //   webb: process.env.REACT_APP_WEBB_SITE_NMBR
        // }))
        
      }
      console.log({ ...data, [item]: !data[item]})
    }
    
  }

  const handleSubmit = async (sort, item, stat) => {
    // e.preventDefault();
    // console.log (sort, item, stat)

  }

 
  if (loading){ return ( <> <WebbSpinText /> </> ) }

  return (
    <>
      {/* <HeaderSectionDark data={{name: 'Account Transfers'}}/>
      <div className="">
        {list && list.length > 0 && list.map((item, i) => (item.code in trxn ?
          <div className="d-flex border rounded p-3 mb-3" key={i}>
            <div className="me-auto">
              <p className="m-0 text-color-main">{item.name}</p>
              <p className="small text-tone m-0">{item.text}</p>
            </div>
            <div className="text-end">
              <div 
                className={`btn rounded-circle m-0 p-2 ${trxn[item.code] ? 'back-color-next' : 'bg-lite'}`}
                style={{width:'2.4rem', height:'2.4rem'}}
                onClick={() => {handleChange("trxn", item.code ); handleSubmit("trxn", item.code, !trxn[item.code] )}}  
                ><i className={trxn[item.code] ? 'bi-check text-white' : 'bi-x text-tint'}></i>
              </div>
            </div>
          </div>
        :''))}
      </div>

      <WebbDividerMedium />
      <HeaderSectionDark data={{name: 'Transfer Reports'}}/>
      <div className="">
        {list && list.length > 0 && list.map((item, i) => ( item.code in data ?
          <div className="d-flex border rounded p-3 mb-3" key={i}>
            <div className="me-auto">
              <p className="m-0 text-color-main">{item.name}</p>
              <p className="small text-tone m-0">{item.text}</p>
            </div>
            <div className="text-end">
              <div 
                className={`btn rounded-circle m-0 p-2 ${data[item.code] ? 'back-color-next' : 'bg-lite'}`}
                style={{width:'2.4rem', height:'2.4rem'}}
                onClick={() => {handleChange("data", item.code ); handleSubmit("data", item.code, !data[item.code] )}}  
                ><i className={data[item.code] ? 'bi-check text-white' : 'bi-x text-tint'}></i>
              </div>
            </div>
          </div>
        :''))}
      </div> */}

      <WebbDividerMedium />

      <HeaderSectionDark data={{name: 'Contrato Business Account '}}/>
      {/* <UserContactInfo data={info} /> */}
      <div className={`border rounded-wd p-3`}>
        <p className="text-color-tone m-0">Account Number</p>
        <p className="text-normal m-0">{baseAc && baseAc.account.nmbr===""? '******':  baseAc.account.nmbr}</p>

        <WebbDividerSmall />
        <p className="text-color-tone m-0">IFSC Code</p>
        <p className="text-normal m-0">{baseAc && baseAc.account.brch === "" ? '******' : baseAc.account.brch}</p>

        <WebbDividerSmall />
        <p className="text-color-tone m-0">Current Balance</p>
        <p className="text-normal m-0">{balance}</p>
      
      </div>

      <div className={baseAc && baseAc.account.nmbr===""?'':'d-none'}>
        <button className="btn btn-link text-decoration-none" onClick={()=>history.push(`/${asset.form.substring(0,2)}/accounts/create/business`)} >Create Account</button>
      </div>

      
      <WebbDividerMedium />

      <HeaderSectionDark data={{name: 'Main Account Linked'}}/>
      {
        mainAc && mainAc.length>0 && mainAc.map(item=>{
          return(
            <div className={`border rounded-wd p-3`}>
              <p className="text-color-tone m-0">Bank Name</p>
              <p className="text-normal m-0">{ item.acnt.srvc}</p>

              <WebbDividerSmall />
              <p className="text-color-tone m-0">Account Number</p>
              <p className="text-normal m-0">{ item.acnt.nmbr}</p>

              <WebbDividerSmall />
              <p className="text-color-tone m-0">IFSC Code</p>
              <p className="text-normal m-0">{item.acnt.brch}</p>

            </div>
          )
        })
        
      }

      <WebbDividerMedium />
      <WebbDividerMedium />
    </>
  )
}