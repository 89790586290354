// documents
import { Link, useParams } from "react-router-dom";

import WebbDividerSmall from "./webb-divider-sm";
import WebbDividerMedium from "./webb-divider-md";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

const code = require('../../data/code-esin.json').data;

export default function SmartMembers(props) {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();

  // console.log(asset)
  const data = props.data
  var admin= props.admin.filter(item=>{return item.role=="admin"})
  const { id } = useParams();

  if (data.length === 0) 
  return (
  <> 
    <span> No Contacts. Please add</span>
    {/* <Link to={`/${asset.form}/documents/new/xxxx/${id}`}>add / upload a file</Link> */}
    <WebbDividerMedium/>
  </>
  )

  return (
  <>
    <div className={`rounded-wd back-color-wite `}>
    {data && data.length > 0 && data.map((item, i) => (
      <div key={i}>
      
      <div className="d-flex p-3" >
        <div className="">
          <p className="text-bold m-0 p-0"><span>{i+1}.</span></p>
        </div>

        <div className="ps-2">
          <p className="text-bold m-0 p-0 text-sm">
            <span>{item.name}</span>
            <span className="ps-2"></span>
            <span className={`rounded back-color-tint text-color-tone text-mini p-1 px-2 ${item.id === asset.user ? '' : 'd-none'}`}>
              <small>{'YOU'}</small>
            </span>
          </p>
          <p className="small m-0 p-0 text-sm">{item.mail}</p>
        </div>

        <div className={`ms-auto text-end ${admin[0].usid === item.id ? '' : 'd-none'}`}>
          
          <div 
            className={`btn btn-sm px-3 rounded-wd border-none bg-${code.find(x=> x.code == item.status).colr}`}
            style={{cursor:'auto'}}
          >
            <p className="small m-0 text-white text-uppercase">
              <small> {item.id == admin[0].usid? "Admin":""}</small>
             
            </p>
          </div>
          
        </div>
      </div>
      <div className={`border-bottom ${i < data.length-1 ? '' :'d-none'}`}></div>
      </div>
      ))}
    </div>

    <WebbDividerSmall/>

  </>
  )
}