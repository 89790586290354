//main
import { useState } from "react";

import ContentFormatXA from "../content/webc/content-format-xa";

import WebbDivider from "../content/webb/webb-divider";
import WebbDividerSmall from "../content/webb/webb-divider-sm";
import WebbDividerMedium from "../content/webb/webb-divider-md";
import HeaderSectionDark from "../content/webb/head-section-dark";
import HeaderSectionLite from "../content/webb/head-section-lite";
import WebbDateTime from "../content/webb/webb-datetime";

import AccountsBalanceModule from "../content/accounts/accounts-balance";
import TransferSearchModule from "../content/transfers/transfers-search";
import TransferListModule from "../content/transfers/transfers-list";

import NewAccountsModule from "../content/accounts/accounts-create";
import NewTransferModule from "../content/transfers/transfers-create";

import TransfersFileModule from "../content/transfers/transfers-file";
import { useEffect } from "react";
import { AccountBalance } from "../services/srvc-accounts-realm";
import { useHistory } from "react-router-dom";
import { UserForm } from "../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../services/srvc-auth-user";
import ServiceTransferListModule from "../content/transfers/service-transfer-list";

const listTabs = [
  {name: 'Completed', code: 'done'},
  {name: 'In Process', code: 'next'},
  {name: 'Scheduled', code: 'wait'}
]


export default function Transfers() {
  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const history= useHistory()

  const [sort, setSort] = useState('done')
  const [search, setSearch] = useState({})
  const [loading, setLoading] = useState(true)
  const [checked,setChecked] = useState(true)

  // useEffect( () => {
  //   setLoading(true)
  //   if (asset){
  //     const fetchData = async() => {
  //       const result = (await AccountBalance({ 
  //         data: { user: asset.user, type: "base-account" },
  //         user: asset.user
  //       })).data;
  //       // console.log(result)
  //       if(result==false)
  //       {
  //         history.push(`/${asset.form.substring(0,2)}/accounts/subscription`)
  //         return
  //       }
  //       if( parseFloat(result.balance.number)/1000000 < 100)
  //       {
  //         history.push(`/${asset.form.substring(0,2)}/accounts/subscription`)
  //         return
  //       }
  //       // setAcnt(result)
  //       setLoading(false)
  //     }
  //     fetchData()
  //   } else {}
  // },[]);



  const handleSearch = async(item) => {
    //console.log('srch: ', sort)
   // console.log(item)
    setSearch(item);
  }

  return (
  <>
    <ContentFormatXA 

    sidebar=
    {{
      head: <> <strong className="text-tone">Transfers</strong> </>,
      data: 
        <> 
          <AccountsBalanceModule />
          
          <WebbDivider />
          <WebbDividerSmall/>
          {/* {type && type.length > 0 && type.map((item, i) => ( 
                
                <span className={`text-mini text-uppercase p-2 px-3 me-1 rounded-wd text-center cursor
                  ${sort === item.code ? 'back-color-next text-color-wite': 'back-color-lite text-color-tone'}`}
                  key={i} style={{fontSize:'0.7rem'}} 
                  onClick={() => setSort(item.code)}
                  >{item.name}</span>

              ))} */}

          <div className="d-flex">
              <div className="me-auto">
                <HeaderSectionLite data={{name: 'Search Transfers'}}/>
              </div>
              <div className="text-end mt-1">
                <div class="form-check form-switch" >
                <input style={{cursor:"pointer"}} onChange={()=>setChecked(!checked)} checked={checked} class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                </div>
              </div>
          </div>   
          
          <TransferSearchModule  search={handleSearch}/>
         

          

          
        </>,
      foot: <> <WebbDateTime/> </>,
    }}
    main=   
      {{      
        head: <>{checked?'Business Transaction':'Services Transaction'}  </>,
        data: 
          <> 
            {/* navs */}
            <div className="d-flex justify-content-start">
              {listTabs && listTabs.length > 0 && listTabs.map((item, i) => ( 
                
                <span className={`text-mini text-uppercase p-2 px-3 me-1 rounded-wd text-center cursor
                  ${sort === item.code ? 'back-color-next text-color-wite': 'back-color-lite text-color-tone'}`}
                  key={i} style={{fontSize:'0.7rem'}} 
                  onClick={() => setSort(item.code)}
                  >{item.name}</span>

              ))}
         

            </div>
                        
            <WebbDividerMedium />

            <HeaderSectionDark data={{name: 'Transactions List'}}/> 
            {
              checked?
              <>
               <TransferListModule type={'1'} data={search || {} } />
              </>
              :
              <>
              <ServiceTransferListModule type={'0'} data={search || {}} />
              </>
            }
           


            <WebbDividerMedium />
            <WebbDividerMedium />
            <WebbDividerMedium />
            <WebbDividerMedium />
            <WebbDividerMedium />
            <WebbDividerMedium />

          </>,
        foot: <> </>,
      }}
    actionbar=
      {{      
        head: <> Activity </>,
        data: 
          <>
            {/* <NewAccountsModule /> */}
            {/* <NewTransferModule /> */}
            <TransfersFileModule/>
            
            
            <WebbDividerMedium />
            
            {/* <HeaderSectionLite data={{name: 'Scheduled Transfers'}}/> */}
            {/* <TransferListScheduleModule /> */}

          </>,
        foot: <> </>,
      }}    
    
    />
  </>
  )
}