// transfers
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";

import TransferOverview from "../webb/trxn-overview";
import TransferParties from "../webb/trxn-parties";
import TransferCharges from "../webb/trxn-charges";
import TransferActions from "../webb/trxn-actions";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { GetTransactionDetail } from "../../services/srvc-transfers-realm";

export default function TransfersViewModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect( () => {
    if (asset){
      const fetchData = async() => {

        const datx = {
          data: { trid: id, usid: asset.usid, form: asset.form },
          user: asset.usid,
          webb: process.env.REACT_APP_WEBB_SITE_NMBR
        }

        setData( (await GetTransactionDetail(JSON.stringify(datx))).data );
        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset.usid]);

  if (loading){ return ( <> <WebbLoader /> </> ) }

  return (
  <>
    <WebbDividerSmall />

    <TransferOverview data={data} />
    <WebbDividerSmall />

    <TransferParties data={data} />
    <WebbDividerSmall />

    <TransferCharges data = {data} />
    <WebbDividerSmall />

    <TransferActions data = {data} />
    <WebbDividerMedium />
    <WebbDividerMedium />
  </>
  )
}