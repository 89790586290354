// members

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

export default function InvoiceMembers(props) {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();

  const data = props.data
  // console.log (data)
  const crdx = data.cred;
  const debx = data.debt;

  return (
  <>
    <div className={`rounded-wd back-color-wite`}>

      <div className="d-flex p-3">
        <div className="me-auto">
          <p className="fw-bold m-0 p-0">
            {debx && debx.name || '***'}
            <span className="ps-2"></span>
            <span className={`p-1 px-2 rounded back-color-tint text-color-tone text-mini ${debx.item === asset.user ? '' : 'd-none'}`}>
              <small>{'YOU'}</small>
            </span>
          </p>
          <p className="small m-0 p-0"> {data && debx.mail || '***'}</p>
        </div>
        <div className="text-end">
          <p className="m-0 p-0 small d-none">
            <i className="bi-circle-fill text-primary small"></i>
          </p>
          <div className={`bg-primary text-white rounded-wd p-1 px-3 small`}>
            <small>{'BUYER'}</small>
          </div>
        </div>
      </div>
      <div className="border-bottom"></div>
      <div className="d-flex p-3">
        <div className="me-auto">
          <p className="fw-bold m-0 p-0">
            {crdx && crdx.name || '***'}
            <span className="ps-2"></span>
            <span className={`p-1 px-2 rounded back-color-tint text-color-tone text-mini ${crdx.item === asset.user ? '' : 'd-none'}`}>
              <small>{'YOU'}</small>
            </span>
          </p>
          <p className="small m-0 p-0">{data && crdx.mail || '***'}</p>
        </div>
        <div className="text-end">
          <p className="m-0 p-0 small d-none">
            <i className="bi-circle-fill text-info small"></i>
          </p>
          <div className={`bg-info text-white rounded-wd p-1 px-3 text-small`}>
            <small>{'SELLER'}</small>
          </div>
        </div>
      </div>

    </div>
  </>
  )
}