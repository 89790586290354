// onboard
import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";

import { useAuth } from "../../context/authcontext";
import { GetAuthUser, GetNewUser } from "../../services/srvc-auth-user";
import { CreatePasscode, CheckPasscode } from "../../services/srvc-code-realm";
// import { SetUserComs } from "../../services/srvc-user-realm";

const webb = process.env.REACT_APP_WEBB_SITE_NAME

export default function OnboardUserComsModule() {

  const asset = GetAuthUser();
  const usrx = GetNewUser();
  const { user } = useAuth();

  const history = useHistory();
  
  const [loading,setLoading]=useState(false);
  // const [mmidcode,setMmidcode]=useState('');
  const [mailcode,setMailcode]=useState(false);
  const [mailPasscode,setMailPasscode]=useState('');
  const [form,setForm]=useState(false);
  const [text, setText] = useState("");

  const [data, setData] = useState({
    mmid: (user.phoneNumber).substr(1,user.phoneNumber.length-1),
    mmvr: true,
    emid: '',
    emvr: false,
    trxn:''
  });

  // useEffect for form validation
  useEffect( () => {
    setForm(false);
    if (data.mmvr && data.emvr) 
      setForm(true);
  },[data.mmvr, data.emvr]);

  const GetMailPasscode = async() => {
    setText('Sending Passcode...');
    setMailcode(true);

    var mail = /^\w+([\.+-]?\w+)+@\w+([\.:]?\w+)+(\.[a-zA-Z0-9]{2,3})+$/
    if (mail.test(data.emid) ){
      const datx = {
        data: {
          user: data.emid,
          form: 'emid',
          memo: 'Email Verification',
          webb: webb
        },
        user: usrx.usid,
        webb: process.env.REACT_APP_WEBB_SITE_NMBR
      }
      
      const res  = (await CreatePasscode (JSON.stringify(datx))).data
      console.log (res);
      handleChange('trxn', res.trxn)
      setText('Passcode sent to your email')
      
    }
    else {
      setText('Invalid Email')
      setMailcode(false);
    }
  }

  const CheckMailPasscode = async() => {
    
    const datx = {
      data: {
        user: data.emid,
        form: 'emid',
        code: mailPasscode,
        trxn: data.trxn
      },
      user: usrx.usid,
      webb: process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const response  = (await CheckPasscode (JSON.stringify(datx))).data
    //console.log (response);
    if (response) {
      handleChange('emvr', true);
      setText('Passcode Verified')
    }
    else {
      handleChange('emvr', false);
      setText('Incorrect Passcode')
    }
  }

  const handleChange = async(key, val) => {
    setForm(false);
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form) 
    {
      setLoading(true);

      const datx = {
        data: {
          coms: [
            {form:'emid', nmbr: data.emid, stat:data.emvr},
            {form:'mmid', nmbr: data.mmid, stat:data.mmvr}
          ],
          usid: usrx.usid,
          form: 'indx'
        },
        user: usrx.usid,
        webb: process.env.REACT_APP_WEBB_SITE_NMBR
      }
      
      // const res = await SetUserComs( JSON.stringify(datx) );
      
      // if (res.data) {
      //   history.push(`/onboard/location`);
      // }
      setLoading(false);
    }
    else {
      setText('Enter Email')
    }
  }

  if (loading){ return ( <> <WebbLoader />  </> ) }

  return (
  <>
    {/* info */}
    <div className="mb-3"></div>
    <p className="text-tone">Please Update Contact Details</p>

    {/* form */}
    <form onSubmit={handleSubmit}>
      {/* mobile */}
      <div className="d-none">
        <div className="mb-3">
          <div className="form-group mb-3">
            <label className="form-label small">Mobile Number <FormNeeded/></label>
            <input type="text" 
              className="form-control height-md"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              disabled={data.mmvr}
              value={data.mmid}
              onChange={({ target }) => {handleChange("mmid", target.value); setText('');}}
              placeholder="9876540123">
            </input>
          </div>
          <p className="small">
            {<i className={`bi-circle-fill small text-${!data.mmvr? 'lite' : 'success'}`}></i>}
            {' '}{data.mmvr? 'Verified' : 'Not Verified'}
          </p>
        </div>

      </div>
      
      {/* email */}
      <div className="">

        <div className="mb-3">
          <div className="form-group mb-3">
            <label className="form-label small">Email <FormNeeded/></label>
            <input type="email" 
              className="form-control height-md"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              disabled={data.emvr}
              value={data.emid}
              onChange={({ target }) => {
                handleChange("emid", target.value); setText('');
                setText('');
                setMailcode(false);
                setMailPasscode('');
              }}
              placeholder="user@mail.com">
            </input>
          </div>

          <div className={`form-group mb-3 ${mailcode?'':'d-none'}`}>
            <label className="form-label small">Passcode (6 digit) <FormNeeded/></label>
            <input type="text" 
              className="form-control height-md"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={mailPasscode}
              onChange={({ target }) => {
                setMailPasscode(target.value); setText('');
                setForm(false)
              }}
              placeholder="123456">
            </input>
          </div>
          <p className="small">
            {<i className={`bi-circle-fill small text-${!data.emvr? 'lite' : 'success'}`}></i>}
            {' '}{data.emvr? 'Verified' : 'Not Verified'}
          </p>
        </div>

      </div>
      
      {/* text */}
      <div className="mb-3">
        <p className="small text-danger">{text}<span className="text-white">{'.'}</span></p>
      </div>
      
      {/* actn */}
      <WebbDividerMedium />
      <div className="">
        
        <div className={mailcode ? 'd-none':'d-grid w-50'}>
          <button className={`btn height-md rounded-pill ${ data.emid!=='' ? 'btn-outline-primary' : 'btn-outline-primary'}`}
            disabled={data.emid===''}
            type="button"
            onClick={()=> GetMailPasscode()}
          ><small>Get Passcode</small>
          </button>
        </div>

        <div className={!form && mailcode ? 'd-grid':'d-none'}>
        <button className={`btn height-md ${ mailcode && mailPasscode.length===6 ? 'btn-outline-primary' : 'btn-outline-primary'}`}
            disabled={mailPasscode.length!==6}
            type="button"
            onClick={()=>CheckMailPasscode()}
          ><small>Check Passcode</small>
          </button>
        </div>

        <div className={form?'d-grid':'d-none'}>
          <button className={`btn height-md ${ form ? 'btn-primary back-color-main' : 'btn-primary back-color-main'}`}
            disabled={!form}
          ><small>Save & Continue</small>
          </button>
        </div>

      </div>

    </form>

    <WebbDividerMedium />
    <WebbDividerMedium />
  </>
  )
}