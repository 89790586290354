// onboard
import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";

import FileSaveFirebase from "../../services/srvc-filesave-fbse";
import { GetNewUser } from "../../services/srvc-auth-user";
// import { SetUserDocs } from "../../services/srvc-user-realm";


export default function OnboardUserDocsModule() {

  const usrx = GetNewUser();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [btnCheck, setButtonCheck] = useState(false);
  const [form, setForm] = useState(false);
  const [text, setText] = useState("");

  const [sinz, SetSinzDocx] = useState({});
  const [data, setData]=useState({
    docs: '',
    name: '',
    form: 'panc',
    nmbr: '',
    stat: false,
    mime: '',
    file: '',
    link: '',
    sinz: '',
    size: 0,
    szid: 0
  })

  // useEffect for form validation
  useEffect( () => {
    setForm(false);
    if (data.nmbr !=='' && data.nmbr.length ===10) 
      setForm(true);
  },[data.nmbr]);

  const SetFileLink = async(data) => {
    console.log ('link: ',data);
    handleChange("link", data);
  }

  const SetFile = async(data) => {
    console.log ('file: ',data.directURL);
    handleChange("file", data);
  }

  const SetSinzFile = async(file) => {
    // console.log ('sinz: ', file);
    // SetSinzDocx(file);
    // const res = await sinzExtractPANCard(JSON.stringify({body:{file:file.directURL}}));
    // console.log(res.response.result)
    // handleChange("nmbr", res.response.result.number);
  }

  const checkDocs = async() => {
    // PAN Card API Call
    
    setText('PAN Card Verified')
    handleChange("stat", true);
    
  }

  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form) {
      setLoading(true);
      //console.log (data)
      const datx = {
        data: {
          docs: [{form: data.form, nmbr: data.nmbr, link: data.link, file: data.file}],
          usid: usrx.usid,
          form: 'indx',
        },
        user: usrx.usid,
        webb: process.env.REACT_APP_WEBB_SITE_NMBR
      }
      
      // const res = await SetUserDocs( JSON.stringify(datx) );
      
      // if (res.data) {
      //   history.push(`/onboard/contact`);
      // }
      setLoading(false);
    }
    else {
      setText('Please upload required documents')
    } 
  }

  if (loading){ return ( <> <WebbLoader />  </> ) }

  return (
  <>
    {/* info */}
    <WebbDividerSmall />
    <p className="text-tone">KYC: Please Upload PAN Card</p>

    {/* form */}
    <form onSubmit={handleSubmit}>

      {/* file */}
      <div className="">
       
        <FileSaveFirebase 
          name = {usrx.usid} 
          file = {SetFile}
          link = {SetFileLink}
        />

      </div>

      {/* input */}
      <div className="">

        <div className="mb-3">
          <div className="form-group">
            <input type="text" 
              className="form-control height-md"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.nmbr}
              onChange={({ target }) => {handleChange("nmbr", target.value)}}
              placeholder="ABCDX1234Z">
            </input>
          </div>
        </div>

      </div>

      {/* text */}
      <div className="mb-3">
        <p className="small text-danger">{text}<span className="text-white">{'.'}</span></p>
      </div>

      {/* actn */}
      <WebbDividerMedium />
      <div className="">
        <div className="d-grid">
          <button 
            className={`btn height-md btn-primary back-color-main`}
            disabled={!form}
          ><small>Save & Continue</small>
          </button>
        </div>
      </div>

    </form>

    <WebbDividerMedium />
    <WebbDividerMedium />
  </>
  )
}