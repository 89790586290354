// documents
import { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import HeaderSectionLite from "../webb/head-section-lite";

import DocumentBasicInfo from "../webb/docx-basicinfo";

import { toBase64, UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

// import { ftos } from "../../services/srvc-utilities";
import { GetDocumentInfo, SetDocumentDataRecord, SetDocumentFiles } from "../../services/srvc-smart-realm";
import FileSaveFirebase from "../../services/srvc-filesave-fbsx";


export default function DocumentsNewDataRecordModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const { id } = useParams();
  const history = useHistory();

  const [loading, setLoading]=useState(true);
  const [form, setForm] = useState(false);

  const [info, setInfo] = useState({});
  const [data, setData] = useState({});
  const [base64, setBase64]=useState()
  const [link, setLink]=useState()

  const [kycu,setKycu] = useState(false)
  const [kycb,setKycb] = useState(false)

  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        
        // basic info
        var res = (await GetDocumentInfo(JSON.stringify({
          data: { document: id },
          user: asset.user,
          webb: process.env.REACT_APP_WEBB_SITE_NMBR
        }))).data

        // console.log(res)
        setInfo( res );

        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset.usid]);


  // form validation
  useEffect( () => {
    setForm(false)
    if (base64 && base64 !=='') setForm(true);
  },[base64]);

  const SetFileLink = async(link) => {
    // console.log (link);
    setLink(link)
    // handleChange("link", link);
  }

  const SetFileData = async(data) => {
    var str1=await toBase64(data);
    var str2=str1.split('base64')
    var b64=str2[1].split(",")
    //  console.log(b64[1])
    setBase64(b64[1])
    //handleChange("file", base64);
  }

  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); 

    console.log("submit")

    const res = await SetDocumentDataRecord (JSON.stringify({
      data: { document: id, link: "", file: base64, mode: 'file', sort: 'init', kycu:kycu, kycb:kycb },
      user: asset.user,
      webb: process.env.REACT_APP_WEBB_SITE_NMBR
    }))
    // console.log (res)
    setLoading(false);
    history.push(`/${asset.form.substring(0,2)}/smart/view/${info.smart_id}`)
  
  }

  if (loading){ return ( <> <WebbLoader /> </> ) }

  return (
  <>
    {/* info */}
    <WebbDividerSmall />
    <p className="text-lead text-bold">{info.name}</p>
    <p className="text-color-tone">{id}</p>
    <WebbDividerSmall />

    {/* action */}
    <div className="mb-3">
      <label className="form-label small">File Attachment (PDF) <span className="text-danger">*</span></label>
      <FileSaveFirebase name={id} link = {SetFileLink} file={SetFileData}/>    
    </div>

    {/* <WebbDividerMedium /> */}

    <div className="bg-white p-3 border d-none">
      <div className="d-flex">
        <label className="me-auto">Individual eKYC</label>
        <input className="text-end" value={kycu} onChange={()=>setKycu(!kycu)} type="checkbox" />
      </div>

      <div className="d-flex">
        <label  className="me-auto">Business eKYC</label>
        <input className="text-end"  value={kycb} onChange={()=>setKycb(!kycb)} type="checkbox" />
      </div>
     

    </div>
    <WebbDividerMedium />

    {/* form */}
    <form onSubmit={handleSubmit}>

      <div className="">
        <div className="d-grid">
          <button className={`btn height-md btn-primary back-color-main rounded-none`}
            disabled={!form}
          ><small>Save & Continue</small>
          </button>
        </div>
      </div>

    </form>
  
    <WebbDividerMedium />
    <WebbDividerMedium />
  </>
  )
}