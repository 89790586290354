// onboard
import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";


import NextDivider from "../webb/next-divider";
import NextLogout from "../webb/next-logout";

import avtx from '../../media/intro.png'

export default function OnboardSuccessModule() {

  return (
  <>

    <img src={avtx} className="img-fluid" alt='...'></img>
    <div className="pt-1 bg-success"></div>

    <div className="text-center bg-success p-3 text-white">
      <p className="lead m-0 p-0">Congratulations !</p>
      <p className="m-0 p-0">Your Profile is Updated</p>  
    </div>

    <WebbDividerMedium />
    <div className="">
      <p>
        We are processing your account information and 
        will notify you once your account is ready.
      </p>
    </div>
    <WebbDividerSmall />
    <NextDivider/>
    <NextLogout />
  </>
  )
}