// transfers
import { numc } from "../../services/srvc-utilities";

export default function TransferCharges(props) {
  const data = props.data;

  return (
  <>
    <div className={`bg-white border`}>

      <div className="d-flex p-3">
        <div className="me-auto">
          <p className="">Gateway Charges</p>
          <p className="d-none">Convenience Charges</p>
          <p className="d-none">Discounts</p>
          <p className="d-none">Tax</p>
          <p className="m-0 p-0">Total Charges</p>
        </div>
        <div className="text-end">
          <p className="">{numc(data.fees, 'ww')}</p>
          <p className="d-none">{numc(0, 'ww')}</p>
          <p className="d-none">{numc(0, 'ww')}</p>
          <p className="d-none">{numc(0, 'ww')}</p>
          <p className="m-0 p-0">{numc(0, 'ww')}</p>
        </div>
      </div>

      <div className="border-bottom"></div>
      
      <div className="d-flex p-3">
        <div className="me-auto">
          <p className="fw-bold m-0 p-0">Total</p>
        </div>
        <div className="text-end">
          <p className="fw-bold m-0 p-0">
            {numc(data.amnt, 'ww')}
          </p>
        </div>
      </div>

      </div>

  </>
  )
}