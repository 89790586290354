import { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import WebbDividerMedium from "../webb/webb-divider-md";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbSpinText from "../webb/webb-spintext";
import ListNoData from "../webb/list-nodata";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { GetDocumentTracks } from "../../services/srvc-track-realm";

export default function DocumentsTracksModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const { id } = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        setLoading(true);
        const result = await GetDocumentTracks({ data: { document: id }, user: asset.usid });
        // console.log(result)
        setData(result.data)
        
        setLoading(false);
      }
      fetchData()
    } else {}
  },[]);
  
  if (loading){ return ( <> <WebbDividerMedium /><WebbSpinText /> </> ) }
  if (data.length===0) {return (<> <WebbDividerMedium /><ListNoData /> </>)}

  return (
  <>
    <WebbDividerMedium />
    <div className="">
    {data && data.length > 0 && data.map((item, i) => (
      <div className={`p-3 bg-white border-bottom`} key={i}>
        <p className="small text-color-tone m-0">
          {(new Date(item.created)).toLocaleString()}
        </p>
        <p className="m-0">{item.memo}</p>
      </div>

    ))}
    </div>
    <WebbDividerSmall/>
    <p className="small text-color-tone m-0 d-none">Date-Time is in UTC</p>
    <WebbDividerMedium />
    <WebbDividerMedium />
    <WebbDividerMedium />
  </>
  )
}