//main
import { useEffect, useState } from "react";
import ContentFormatXX from "../content/webc/content-format-xx";
import { UserPackages, UserTemplate } from "../services/srvc-template-realm";
import { useHistory } from "react-router-dom";
import { UserForm } from "../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../services/srvc-auth-user";

import MyPackagesView from "../content/marketplaces/my-packages";


export default function MyPackages() {

const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
const history = useHistory();

const [loading, setLoading] = useState(true)
const [data, setData] = useState([])

useEffect(()=>{

    const fetchdata = async()=>{
        var res = (await UserPackages({data:{user:asset.user}, user: asset.user  })).data
        console.log(res)
        if(res!= false){setData(res)}
        else setData([])
        setLoading(false)
    }
    fetchdata()

},[])

  return (
  <>
    <ContentFormatXX 
      head='My Templates'
      link={`/in/marketplace`}
      form= 'medium'
      data= {
        <>
            <MyPackagesView data={data} />
        </>
      } 
    />
  </>
  )
}