// network
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbLoader from "../webb/webb-loader";
import ListNoData from "../webb/list-nodata";
import NavsButtonBack from "../webb/navs-button-back";
import NavsButtonNext from "../webb/navs-button-next";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { GetUserContactsList } from "../../services/srvc-contacts-realm";


export default function ContactsListModule(props) {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();

  const search = props.data;
  // console.log (search)

  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const [data, setData]=useState([]);

  const [index, setIndex] = useState(1)
  const [items, setItems] = useState(5)

  const [curr, setCurrentIndex] = useState(1)
  const [next, setNextIndex] = useState()
  const [last, setLastIndex] = useState()

  const [count, setCount] = useState()
  const [total, setTotal] = useState()

  const [text, setText] = useState('')
  
  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        const result = await GetUserContactsList({ 
          data: {
            user: asset.user,
            index: index, items: items,
            filters: search
            },
          user: asset.user
          })
          // console.log (result.data)
          
          if (result.data) {
            const conx = result.data.list
            setData(conx.sort((a,c) => a.name - c.name))
            setTotal(Math.ceil(result.data.count/ items))
            setText(`${(index-1)*items+1} - ${index*items < result.data.count ? index*items : result.data.count} of ${result.data.count}`)
          } 

        setLoading(false);
      }
      fetchData()
    } else {}
  },[search, index, items]);


  const NextIndex = async() =>{
    if(total == index) {}
    else {
      setNextIndex(curr+1)
      setIndex(curr+1)
      setCurrentIndex(curr+1)
    }
  }

  const LastIndex = async()=>{
    if(index == 1) {}
    else{
      setLastIndex(curr-1)
      setIndex(curr-1)
      setCurrentIndex(curr-1)
    }
  }

  const handleClick = (item) => {
    history.push(`/${asset.form.substring(0,2)}/contacts/view/${item}`)
  }


  // if (loading){ return ( <> <WebbLoader /> </> ) }
  if (!loading && (!data || data.length === 0)) { return ( <> <ListNoData /> </> ) }

  return (
  <>
    
    {/* data */}
    <div className="back-color-wite border rounded-wd shadow-sm">
      {data && data.length > 0 && data.map((item, i) => (
      <div style={{cursor:'pointer'}} className={i < data.length-1 ? 'border-bottom' : ''} key={i}>
        <div className="p-3 hilite" onClick={() => handleClick(item.item)}>
          <div className="d-flex">

            <div className="text-start py-1 d-none">
              <div className="btn btn-sm btn-warning rounded-circle" style={{'width':'2.1rem', 'height':'2.1rem'}}>
                <i className="bi bi-bell text-color-wite text-normal"></i>
              </div>            
            </div>

            <div className="">
              <p className="text-normal text-bold m-0 text-primary">
                {/* <span className={item.actv ? 'text-color-success' : 'text-color-warning'}><i className="bi bi-circle-fill text-small"></i></span> */}
                <span className="">{item.name}</span>
              </p>
              <p className="m-0 text-sm">{item.mail}</p>
              {/* <p className="m-0 text-small text-color-tone">ID: {item.enid}</p> */}
            </div>

            <div className="ms-auto py-2">
              <i className="bi bi-chevron-right text-lead text-color-tint"></i>            
            </div>

          </div>

        </div>
      </div>

      ))}
    </div>


    {/* navs */}
    <WebbDividerSmall />
    <div className={data.length < items ? '' : ''}>
      <div className="d-flex justify-content-between">

        <div className="" onClick={()=>LastIndex()}>
          <NavsButtonBack />
        </div>

        <div className="">
          <p className="my-2 text-color-tone">{text}</p>
        </div>

        <div className="" onClick={()=>NextIndex()}>
          <NavsButtonNext />
        </div>

      </div>
    </div>


  </>
  )
}