// new business account created here
// business onboard - search by pan card

import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";

import { GetLocalUser, SetNewBusiness } from "../../services/srvc-auth-user";
import { NewBusinessAccount } from "../../services/srvc-users-realm";


export default function OnboardBusinessNewModule() {
  
  const asset = GetLocalUser()
  console.log (asset)

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(false);
  const [text, setText] = useState(false);

  const [data, setData] = useState({
    name: '',
    bios: '',
    emid: asset.emid
  });

  // useEffect for form validation
  useEffect( () => {
    setForm(false);
    if (data.panc!=='' && data.panc.length===10) 
      setForm(true);
  },[data]);

  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form) 
    {
      setLoading(true);

      // need PAN verifiction here

      const datx = {
        body: {panc: data.panc, gstn: '', form: 'bz'},
        user: {usid: asset.usid, form: asset.form}
      }
      //console.log(asset);
      console.log( JSON.stringify(datx) );
      
      // var res = await GetUserAccountNumber(JSON.stringify(datx))
      
      // res.data 
      //   ? SetNewBusiness( {usid:res.data} )
      //   : SetNewBusiness( {usid:''} )
      
      setLoading(false);
      history.push(`/business/name`);
      //resetForm();
    }
    else {
      setText('Please enter details')
    }
  }

  if (loading){ return ( <> <WebbLoader />  </> ) }

  return (
  <>
    {/* info */}
    <WebbDividerSmall />
    <p className="text-tone">Please Update Business KYC</p>

    {/* form */}
    <form onSubmit={handleSubmit}>
      <div className="">

        <div className="mb-3">
          <div className="form-group">
            <label className="form-label small">Business PAN Card <FormNeeded/></label>
            <input type="text" 
              className="form-control height-md "
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.panc}
              onChange={({ target }) => {handleChange("panc", target.value); setText('');}}
              placeholder="Enter Business PAN">
            </input>
          </div>
        </div>

      </div>
      
      {/* text */}
      <div className="mb-3">
        <p className="small text-danger">{text}<span className="text-white">{'.'}</span></p>
      </div>

      {/* actn */}
      <WebbDividerMedium />
      <div className="">
        <div className="d-grid">
          <button className={`btn height-md ${ form ? 'btn-primary back-color-main' : 'btn-primary back-color-main'}`}
            disabled={!form}
          ><small>Submit</small>
          </button>
        </div>
      </div>


    </form>

    <WebbDividerMedium />
    <div className="">
      <div className="text-muted">
        <span className='small'>If you dont have PAN Card - </span>
        <button className={`btn btn-link text-decoration-none p-0`}
            disabled
        >
        <span className='small'>Click Here</span>
        </button>
      </div>
    </div>


  </>
  )
}