//main
import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import SmartsNewInfoModule from "../content/smart/smarts-new-info";

export default function SmartsNewInfo() {


  return (
  <>
    <ContentFormatXX 
      head='New Document'
      link={`/${UserForm()}/smart`}
      form= 'small'
      data= {
        <>
          <SmartsNewInfoModule />
        </>
      } 
    />
  </>
  )
}