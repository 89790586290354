// contracts
import { useParams } from "react-router-dom";

import WebbDividerSmall from "../webb/webb-divider-sm";

import IconActionCard from "../webb/ctas-iconactioncard";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

const list = (require("../../data/optn-documents.json")).data.filter(item => item.actv);

export default function DocumentsNewXXXXModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const { smid,id } = useParams();

  var data = (list.filter(item => item.user.includes(asset.form)));
  data = Array.from(data, item => {return {...item, link: item.link+'/'+smid+'/'+id }} )
  
  const sort = [...new Set(data.map(item=>item.sort))];

  return (
  <>
    <WebbDividerSmall />
    {/* info */}
    <div className="">
      <p className="text-tone">Please select an option</p>
    </div>
    <WebbDividerSmall />

    {/* options */}
    
    <div className="">
    {sort && sort.map((srtx, x) => (
      <div className="row mb-3 pb-3" key={x}>
        <p className="fw-bold text-color-main mb-2">{srtx}</p>
        
        <div className="">
          {data && data.map((item, i) => ( item.sort === srtx ?
            
            <IconActionCard  data={item} key={i} />
          
          :''))}
        </div>
      </div>
    ))}
    </div>
  </>
  )
}