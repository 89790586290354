import ContentFormatXX from '../content/webc/content-format-xx';

import AuthMailFirebaseModule from '../content/auth/auth-mail-firebase';
import AuthMailOTPModule from '../content/auth/auth-mail-otp';

export default function AuthMailOTP() {
  
  return (
    <ContentFormatXX 
      head={'Account Access'}
      link={'/'}
      form={'mini'}
      data={
        <>
          <AuthMailOTPModule />
        </>
        
      }  
    /> 
  )
}