// next logout
import { useHistory } from "react-router-dom";



export default function NextBusiness() {

  const history = useHistory();

  return (
  <>
    <div className="d-flex p-3 py-0 back-color-wite rounded-wd hitone"
      style={{cursor:'pointer'}}
      // onClick={() => history.push('/business/name')}
    >
      <div className="py-2">
        <i className="bi-briefcase text-color-main text-icon-sm"></i>
      </div>
      <div className="ps-2 py-2">
        <p className="m-0 mt-1">New Business Account</p>
      </div>
      <div className="ms-auto text-end py-2 mt-1">
        <i className="bi-chevron-right text-color-tone m-0" ></i>
      </div>
    </div>


  </>
  )
}