//main
import { useState } from "react";

import ContentFormatXA from "../content/webc/content-format-xa";

import WebbDivider from "../content/webb/webb-divider";
import WebbDividerSmall from "../content/webb/webb-divider-sm";
import WebbDividerMedium from "../content/webb/webb-divider-md";
import HeaderSectionLite from "../content/webb/head-section-lite";
import TextLipsum from "../content/webb/text-lipsum";
import WebbDateTime from "../content/webb/webb-datetime";

import ContactsSearchModule from "../content/contacts/contacts-search";
import ContactsTeamListModule from "../content/contacts/team-list";

import NewContactTeamModule from "../content/contacts/team-create";
import NewContactBulkModule from "../content/contacts/contacts-create-bulk";

export default function ContactsTeam() {

  const [srch, setSearch] = useState({})

  const handleSearch = async(sort) => {
    // console.log('srch: ', sort)
    setSearch(sort);
  }

  return (
  <>
    <ContentFormatXA 

    sidebar=
    {{
      head: <> <strong className="text-tone">Team</strong> </>,
      data: 
        <> 
        <HeaderSectionLite data={{name: 'Search Team'}}/>
        <ContactsSearchModule search={handleSearch}/>
          
        </>,
      foot: <> <WebbDateTime /> </>,
    }}
    main=   
      {{      
        head: <> Team Members </>,
        data: 
          <> 
          <ContactsTeamListModule data={srch || {} } />
          
          </>,
        foot: <> <WebbDateTime /> </>,
      }}
    actionbar=
      {{      
        head: <> Activity </>,
        data: 
          <>
            <NewContactTeamModule />

            
          </>,
        foot: <> </>
      }}    
    
    />
  </>
  )
}