//main
import ContentFormatXA from "../content/webc/content-format-xa";


import HeaderSectionLite from "../content/webb/head-section-lite";


import { useEffect, useState } from "react";
import TemplateListsModule from "../content/marketplaces/template-lists";
import { useHistory } from "react-router-dom";
import PackageListsModule from "../content/marketplaces/package-lists";
import WebbDateTime from "../content/webb/webb-datetime";
import HeadingModule from "../content/webb/head-module";
import UserProfile from "../content/user/user-profile";
import UserListModule from "../content/user/user-list";
import OptionListModule from "../content/marketplaces/option-lists";

const list = [
  {name: 'Packages', item: <PackageListsModule />},
  {name: 'Templates', item: <TemplateListsModule />},
  ]


export default function MarketPlaceView() {

// const [type, setType] = useState('Package')
const [sort, setSort] = useState('Packages');
const history = useHistory()
 

  const handleSort = async(item) => {
    // console.log('item: ', item)
    setSort(item);
  }

  return (
  <>
    <ContentFormatXA 

    sidebar=
    {{
      head: <> <strong>MarketPlace</strong> </>,
      data: 
        <> 
        <HeaderSectionLite data={{name: 'Choose Services'}}/>
        <OptionListModule item={handleSort}/>        
        </>,
      foot: <> Today: </>,
    }}
    main =
    {{      
      head: <><HeadingModule data={sort}/></>,
      data: list.find(x => x.name === sort).item,
      foot: <> </>,
    }}
    actionbar=
      {{      
        head: <> Activity </>,
        data: 
          <>
          {/* <NewAccountsModule /> */}
          <div style={{cursor:'pointer'}} onClick={()=>history.push('/in/templates')} className="p-2 bg-white border rounded">
            <p  className="text-primary fw-bold mt-1" >My Templates</p>
            <p className="text-secondary small text-small" style={{marginTop:"-17px"}}>Your Subscribed Templates</p>
          </div>

          <div style={{cursor:'pointer'}} onClick={()=>history.push('/in/packages')} className="p-2 bg-white border rounded mt-3">
            <p  className="text-primary fw-bold mt-1" >My Packages</p>
            <p className="text-secondary small text-small" style={{marginTop:"-17px"}}>Your Subscribed Packages</p>
          </div>

          </>,
        foot: <> </>,
      }}    
    
    />
  </>

  // <>
  //   <ContentFormatXA 

  //   sidebar=
  //   {{
  //     head: <> <strong className="text-color-tone">Settings</strong> </>,
  //     data: 
  //       <> 
  //         {/* <UserInfoModule />
  //         <WebbDivider />
  //         <WebbDividerSmall />
  //         <UserListModule item={handleSort}/> */}
          
  //       </>,
  //     foot: <> <WebbDateTime /> </>,
  //   }}
  //   main=   
  //     {{      
  //       head: <><HeadingModule data={sort}/></>,
  //       data: list.find(x => x.name === sort).item,
  //       foot: <> </>,
  //     }}
  //   actionbar=
  //     {{      
  //       head: <> </>,
  //       data: <> </>,
  //       foot: <> </>
  //     }}    
    
  //   />
  // </>

  )
}