//main
import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import TransfersStatementModule from "../content/transfers/transfers-statement";

export default function TransfersStatement() {

  return (
  <>
    <ContentFormatXX 
      head='Download Statement'
      link={`/${UserForm()}/transfers`}
      form= 'small'
      data= {
        <>
          <TransfersStatementModule />
        </>
      } 
    />
  </>
  )
}