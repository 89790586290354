//main
import ContentFormatXX from "../content/webc/content-format-xx";

import WebbProgressBar from "../content/webb/webb-progressbar";
import WebbDividerSmall from "../content/webb/webb-divider-sm";

import OnboardUserLocationModule from "../content/onboard/user-location";

export default function OnboardUserLocation() {

  return (
  <>
    <ContentFormatXX 
      head='New Account'
      link={`/account/next`}
      form= 'small'
      data= {
        <>
          <WebbDividerSmall/>
          <WebbProgressBar stat={69} />
          <OnboardUserLocationModule />
        </>
      } 
    />
  </>
  )
}