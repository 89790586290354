// new order options
import AvatarActionCard from "../webb/ctas-avtractioncard";
import BrandDisclaimer from "../webb/text-branddisclaimer";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbModuleInfo from "../webb/webb-module-info";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

const list = (require("../../data/optn-accounts.json")).data;

export default function AccountsNewModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  
  const data = (list.filter(item => item.user.includes(asset.form) && item.actv));
  const sort = [...new Set(data.map(item=>item.sort))];

  return ( <> </> )

  return (
  <>
    {/* info */}
    <WebbDividerSmall/>
    <WebbModuleInfo data={{info: 'Please select an option'}}/>
    <WebbDividerSmall />

    {/* options */}
    <div className="">
    {sort && sort.map((srtx, x) => (
      <div className="row mb-3 pb-3" key={x}>
        <p className="fw-bold text-color-main mb-2">{srtx}</p>
        
        <div className="">
          {data && data.map((item, i) => ( item.sort === srtx ?
            
            <AvatarActionCard data={item} key={i} />
          
          :''))}
        </div>
      </div>
    ))}
    </div>
    <BrandDisclaimer />
  </>
  )
}