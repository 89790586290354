// fine - system report downloads
import { numc } from './srvc-utilities';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const XLSXFileDownload = (fileData, fileName) => {

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const file = Array.from(fileData, x=> { return {
    created: (new Date(x.created)).toLocaleString(), username: x.user.name, memo: x.meta.memo, 
    amount: numc(x.amount.number).toString(),
    mode: x.mode, status: x.status, format: x.meta.format, reference: x.item 
  }})


  // rename headers
  const headers = { 
    'created':'Date', 'username':'Name', 'memo':'Comments', 'amount':'Amount', 
    'mode':'Cr/Db', 'status':'Status', 'format':'Type', 'reference':'Reference ID'
  };
  file.unshift(headers);

  const ws = XLSX.utils.json_to_sheet(
    file, 
    {
      header:["created","username","memo","amount","mode","status","format","reference"],
      skipHeader:true,
      origin: 'A1'
    }
  );

  const wb = { Sheets: { 'statement': ws }, SheetNames: ['statement'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], {type: fileType});
  
  FileSaver.saveAs(data, fileName + fileExtension);
  
}