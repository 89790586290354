// user onboard - contact info - mobile, email

import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";

import { GetLocalUser, GetNewBusiness } from "../../services/srvc-auth-user";
import { SendPasscode, CheckPasscode } from "../../services/srvc-utilities-realm";
// import { SetUserComs } from "../../services/srvc-user-realm";

const webb = process.env.REACT_APP_WEBB_SITE_NAME

export default function OnboardBusinessComsModule() {
  
  const asset = GetLocalUser();
  const bsnx = GetNewBusiness();
  const history = useHistory();
  
  const [loading,setLoading]=useState(false);
  
  const [mobilecode,setMobilecode]=useState(false);
  const [mobilePasscode,setMobilePasscode]=useState('');
  const [mobilecheck,setMobileCheck]=useState('Enter OTP');
  const [mailcode,setMailcode]=useState(false);
  const [mailPasscode,setMailPasscode]=useState('');
  const [mailcheck,setMailCheck]=useState('Enter OTP');
  const [check,setCheck]=useState(false);

  const [form,setForm]=useState(false);
  const [text, setText] = useState("");
  
  const [data, setData] = useState({
    mmid: '',
    mmvr: false,
    emid: '',
    emvr: false,
  });

  // useEffect for form validation
  useEffect( () => {
    setForm(false);
    if (data.mmvr && data.emvr) 
      setForm(true);
  },[data.mmvr, data.emvr]);


  const sendMailPasscode = async() => {
    setText('Sending Passcode...');
    setMailcode(true);

    var mail = /^\w+([\.-]?\w+)+@\w+([\.:]?\w+)+(\.[a-zA-Z0-9]{2,3})+$/
    if (mail.test(data.emid) ){
      const datx = {
        body: {
          user: data.emid,
          form: 'emid',
          resn: 'Email Verification',
          webb: webb
        },
        user: { usid: asset.usid, form: asset.form }
      }
      
      const response  = (await SendPasscode (JSON.stringify(datx))).data
      console.log (response);
      setText('Passcode sent')
      setMailCheck('Enter OTP')
    }
    else {
      setText('Invalid Email')
      setMailcode(false);
    }
  }

  const sendMobilePasscode = async() => {
    setText('Sending Passcode...');
    setMobilecode(true);
    setMobileCheck('Enter OTP')
  }


  const checkUserPasscode = async() => {
    
    const datx = {
      body: {
        user: data.emid,
        form: 'emid',
        code: mailPasscode
      },
      user: { usid: asset.usid, form: asset.form }
    }
    var mail  = (await CheckPasscode (JSON.stringify(datx))).data
    var mobs = mobilePasscode==='123456' ? true : false
    setData( {...data, emvr: mail, mmvr:mobs} )
    setMobileCheck(mobs ? 'Correct Passcode' : 'Incorrect Passcode')
    setMailCheck(mail ? 'Correct Passcode' : 'Incorrect Passcode')
  }

  const handleChange = async(key, val) => {
    setForm(false);
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form) 
    {
      setLoading(true);

      const datx = {
        body: {
          coms: [
            {form:'emid', nmbr: data.emid, stat:data.emvr},
            {form:'mmid', nmbr: '91'+data.mmid, stat:data.mmvr}
          ],
          usid: bsnx.usid,
          form: 'bz'
        },
        user: { usid: asset.usid, form: asset.form }
      }
      
      console.log( JSON.stringify(datx) );  
      // const response = await SetUserComs( JSON.stringify(datx) );
    
      // if (response.data) {
      //   console.log (response);
      //   history.push(`/business/location`);
      // }

      setLoading(false);
    }
    else {
      setText('Enter Email')
    }
  }

  if (loading){ return ( <> <WebbLoader />  </> ) }
  
  return (
  <>
    {/* info */}
    <WebbDividerSmall/>
    <p className="text-tone">Please Update Contact Details</p>

    {/* form */}
    <form onSubmit={handleSubmit}>
      <div className="">

        <div className="mb-3">
          <div className="form-group mb-1">
            <label className="form-label small">Business Mobile <FormNeeded/></label>
            <input type="text" 
              className="form-control height-md "
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              disabled={mobilecode}
              value={data.mmid}
              onChange={({ target }) => {
                handleChange("mmid", target.value); setText('');
                setText('');
                setMobilecode(false);
                setMobilePasscode('');
              }}
              placeholder="9876540123">
            </input>
          </div>
          <p className="small">
            {<i className={`bi-circle-fill small text-${data.mmvr? 'success' : 'lite'}`}></i>}
            {' '}{data.mmvr? 'Verified' : 'Not Verified'}
          </p>
        </div>

        <div className="mb-3">
          <div className="form-group mb-1">
            <label className="form-label small">Business Email <FormNeeded/></label>
            <input type="email" 
              className="form-control height-md "
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              disabled={mailcode}
              value={data.emid}
              onChange={({ target }) => {
                handleChange("emid", target.value); setText('');
                setText('');
                setMailcode(false);
                setMailPasscode('');
              }}
              placeholder="user@mail.com">
            </input>
          </div>
          <p className="small">
            {<i className={`bi-circle-fill small text-${!data.emvr? 'lite' : 'success'}`}></i>}
            {' '}{data.emvr? 'Verified' : 'Not Verified'}
          </p>
        </div>

        <div className="pt-3 mb-3 border-bottom"></div>

        <div className={`form-group mb-3 ${mobilecode?'':'d-none'}`}>
          <label className="form-label small">Enter Mobile Passcode (6 digit) <FormNeeded/></label>
          <input type="text" 
            className="form-control height-md "              
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={mobilePasscode}
            onChange={({ target }) => {
              setMobilePasscode(target.value); setText('');
              setMobileCheck('');
              setForm(false);
              handleChange('mmvr',false);
            }}
            placeholder="123456">
          </input>
          <div className={check?'': 'd-none'}>
            <small className={data.mmvr ? 'text-success' : 'text-danger'}>
              {mobilecheck}
            </small>  
          </div>
        </div>

        <div className={`form-group mb-3 ${mailcode?'':'d-none'}`}>
          <label className="form-label small">Enter Mail Passcode (6 digit) <FormNeeded/></label>
          <input type="text" 
            className="form-control height-md "              
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={mailPasscode}
            onChange={({ target }) => {
              setMailPasscode(target.value); setText('');
              setMailCheck('');
              setForm(false);
              handleChange('emvr',false);
            }}
            placeholder="123456">
          </input>
          <div className={check?'': 'd-none'}>
            <small className={data.emvr ? 'text-success' : 'text-danger'}>
              {mailcheck}
            </small>  
          </div>
          
        </div>

      </div>
      

      {/* text */}
      <div className="mb-3">
        <p className="small text-danger">{text}<span className="text-white">{'.'}</span></p>
      </div>

      {/* actn */}
      <WebbDividerMedium />
      <div className="">
        
        <div className={mailcode && mobilecode ? 'd-none':'d-grid'}>
          <button className={`btn height-md ${ data.emid!=='' ? 'btn-outline-primary' : 'btn-outline-primary'}`}
            disabled={data.mmid==='' || data.emid===''}
            type="button"
            onClick={() => {sendMailPasscode(); sendMobilePasscode();} }
          ><small>Get Passcode</small>
          </button>
        </div>

        <div className={!form && mailcode && mobilecode ? 'd-grid':'d-none'}>
        <button className={`btn height-md ${ mailcode && mailPasscode.length===6 ? 'btn-outline-primary' : 'btn-outline-primary'}`}
            disabled={mailPasscode.length!==6 || mobilePasscode.length!==6}
            type="button"
            onClick={()=>{ checkUserPasscode(); setCheck(true); }}
          ><small>Verify Passcode</small>
          </button>
        </div>

        <div className={form?'d-grid':'d-none'}>
          <button className={`btn height-md ${ form ? 'btn-primary back-color-main' : 'btn-primary back-color-main'}`}
            disabled={!form}
          ><small>Save & Continue</small>
          </button>
        </div>

      </div>

    </form>

  </>
  )
}