//main
import { useParams } from 'react-router-dom';

import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import ContactsEditInfoModule from "../content/contacts/contacts-edit-info";

export default function ContactsEditInfo() {

  const { id } = useParams();
  
  return (
  <>
    <ContentFormatXX 
      head='Contact Details'
      link={`/${UserForm()}/contacts/view/${id}`}
      form= 'small'
      data= {
        <>
          <ContactsEditInfoModule />
        </>
      } 
    />
  </>
  )
}