// onboard
import { useEffect, useState, useRef } from "react";
import { useHistory } from 'react-router-dom';

import firebase from "../../services/firebase"
import { getStorage, ref, uploadString, getDownloadURL } from "firebase/storage";

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";

import { GetNewUser } from "../../services/srvc-auth-user";
// import { SetUserAvatar } from "../../services/srvc-user-realm";

const storage = getStorage();

export default function OnboardUserAvatarModule() {

  const usrx = GetNewUser();

  const userVideo = useRef(null);
  const userStreamRef = useRef(null);
  const canvasRef = useRef(null);

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(false);
  const [text, setText] = useState("");

  const [data, setData]=useState({
    file:'',
    avtr:''
  })

  useEffect(() => {
    startCapture();
  }, []);

  
  const startCapture = async () => {
    let userStream = userStreamRef.current;
    try {
      userStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        // audio: true
      });
      userVideo.current.srcObject = userStream;
    } catch (err) {
      console.log(err);
    }
  };

  const endCapture = async () => {
    let stream = userVideo.current.srcObject
    //const tracks = stream.getTracks();
    await stream.getTracks().forEach((track) => {
      console.log (track)
      track.stop();
    });
    userVideo.current.srcObject = null;
    
  };

  const takePicture = async () => {

    var context = canvasRef.current.getContext('2d');
    canvasRef.current.width = userVideo.current.videoWidth;
    canvasRef.current.height = userVideo.current.videoHeight;
    context.drawImage(userVideo.current, 0, 0, userVideo.current.videoWidth,userVideo.current.videoHeight);
    handleChange("file", (canvasRef.current.toDataURL('image/png')) );
    setForm(true);
    setText (<>For New Selfie, Click Reset</>)
    
  }

  const handleUpload = async () => {
    
    setText(<>Save Photo...</>)
    
    const filename = usrx.usid + '.png'  //(new Date()).getTime().toString()
    console.log (filename)
    const storageRef = ref(storage, 'useruuz24hdmuav' + '/'+filename);

    const filesave = await uploadString(storageRef, data.file, 'data_url');
    return (await getDownloadURL (filesave.ref))
    
  }

  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async () => {
    // e.preventDefault();
    await endCapture();
    setLoading(true)
    const link = await handleUpload();
    console.log ('link: ', link)
    const datx = {
      body: { avtr: link, usid: usrx.usid, form: 'us' },
      user: { usid: usrx.usid, form: 'us' }
    }

    console.log (JSON.stringify(datx), data)
    // const res = await SetUserAvatar(JSON.stringify(datx));
    // if (res) {
    //   console.log (res)
      
    //   window.location.href='/onboard/docs'
      
    //   // history.push('/onboard/creds')
    // }
    setLoading(false)
  }

  if (loading){ return ( <> <WebbLoader /> </> ) }

  return (
  <>
    {/* info */}
    <div className="mb-3"></div>
    <p className="text-muted">KYC: Selfie Capture</p>

    
      {/* file */}
      <div className="">  
        <div className={`mb-3 ${form ? 'd-none' : ''}`}>
          <label className="form-label small">Preview <FormNeeded /></label>
          <div className="rounded border bg-dark">
            <video
              muted
              width="100%"
              ref={userVideo}
              autoPlay
              playsInline
            ></video>
          </div>
        </div>

        <div className={`mb-3 ${form ? '' : 'd-none'}`}>
          <label className="form-label small">Preview <FormNeeded /></label>
          <div className="rounded border bg-dark">
            <img 
              className="img-fluid"
              src={data.file}
            ></img>
          </div>
        </div>

      </div>


      {/* text */}
      <div className="d-flex mb-3">

        <div className="me-auto">
          <p className="small text-danger">
            {text}
          </p>
        </div>
        
        <div className="m-0 p-0">
          <p className={`small text-color-main ${form ? '': 'd-none'}`}
            onClick={ () =>{setForm(false); setText(''); }}
          >{'Reset'}
          </p>
        </div>
      </div>

      {/* actn */}
      <WebbDividerMedium />
      <div className="">
        <div className={!form ? 'd-grid':'d-none'}>
        <button className={`btn height-md btn-outline-primary`}
            
            type="button"
            onClick={()=>takePicture()}
          ><small>Take Picture</small>
          </button>
        </div>

        <div className={form?'d-grid':'d-none'}>
          <button className={`btn height-md ${ form ? 'btn-primary back-color-main' : 'btn-light'}`}
            disabled={!form}
            onClick={()=>handleSubmit()}
          ><small>Save & Continue</small>
          </button>
        </div>

      </div>
    
    
    
    <div className="">
      <canvas ref={canvasRef} hidden ></canvas>
    </div>

  </>
  )
}