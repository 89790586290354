// onboard
import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";

import { GetNewUser } from "../../services/srvc-auth-user";
// import { SetUserTerms } from "../../services/srvc-user-realm";

const term = [
  {
    name: 'User Info',
    desc: 'I confirm that the information provided is correct',
    form: 'info',
    stat: false, actv: false
  },
  {
    name: 'Legal',
    desc: 'I accept Terms & Conditions',
    form: 'legl',
    stat: false, actv: false
  },
  {
    name: 'Privacy',
    desc: 'I accept Privacy Policy',
    link: '',
    form: 'prvc',
    stat: false, actv: true
  },
  {
    name: 'Terms',
    desc: 'I accept Terms & Conditions',
    link: '',
    form: 'term',
    stat: false, actv: true
  }
]

export default function OnboardUserTermsModule() {

  const usrx = GetNewUser();
  const history = useHistory()

  const [loading,setLoading]=useState(false);
  const [form,setForm]=useState(false);
  const [text, setText] = useState("");
  
  const [data, setData] = useState(term);

  // useEffect for form validation
  useEffect( () => {
    setForm(false);
    var term = data.reduce( (a, c) => a + (c.stat?1:0) , 0);
    if (term>1) setForm(true);
  },[data]);

  const handleChange = (name, vals, indx) => {
    // edit array-object value
    setData(data => data.map((item, i) => (indx === i ? { ...item, [name]: vals } : item)));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (form) 
    {
      const datx = {
        data: { usid: usrx.usid, form: 'indx' },
        user: usrx.usid,
        webb: process.env.REACT_APP_WEBB_SITE_NMBR
      }
      // console.log( JSON.stringify(datx) );
      // const res = await SetUserTerms( JSON.stringify(datx) );
      
      // if (res.data) {
      //   //console.log (res);
      //   history.push(`/onboard/success`);
      // }
      // resetForm();
      setLoading(false);
    }
    else {
      setText('Please accept terms')
    }
  }

  if (loading){ return ( <> <WebbLoader />  </> ) }


 return (
  <>
    {/* info */}
    <WebbDividerSmall />
    <p className="text-tone">Please Review and Accept Terms</p>
    
    <WebbDividerSmall />
    {/* form */}
    <form onSubmit={handleSubmit}>
      <div className="">

        {data && data.length > 0 && data.map ((item, i) => (item.actv ?
        <div className={`p-3 bg-white rounded mb-3`} key={i}>
          <div className="form-check p-0">
            <div className="d-flex py-2">
              <div className="me-auto">
                <p className="m-0 p-0">{item.desc}</p>
                <a href={item.link} className="m-0 p-0">{'Link'}</a>
              </div>
              <div className="ps-3">
                <div 
                  className={`btn rounded-circle m-0 p-2 ${data[i].stat ? 'back-color-next' : 'bg-lite'}`}
                  style={{width:'2.4rem', height:'2.4rem'}}
                  onClick={() => {handleChange("stat", !data[i].stat, i);}}
                ><i className={data[i].stat ? 'bi-check text-white' : 'bi-check text-tint'}></i>
                </div>
                {/* <input  className="form-check-input" 
                  type="checkbox"
                  style={{fontSize:"1.1em"}}
                  checked={data[i].stat}
                  onChange={({ target }) => handleChange("stat", target.checked, i)}
                  >
                </input> */}
              </div> 
            </div>
          </div>
        </div>
        :''))}

        <small className="text-danger">{text}</small>
      </div>
      
      {/* text */}
      <div className="mb-3">
        <p className="small text-danger">{text}<span className="text-white">{'.'}</span></p>
      </div>

      {/* actn */}
      <WebbDividerMedium />
      <div className="">
        <div className="d-grid">
          <button 
            className={`btn height-md ${ form ? 'btn-primary back-color-main' : 'btn-primary back-color-main'}`}
            disabled={!form}
          ><small>Save & Continue</small>
          </button>
        </div>
      </div>

    </form>

    <WebbDividerMedium />
    <WebbDividerMedium />
  </>
  )
}