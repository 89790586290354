//main
import { useParams } from "react-router-dom";

import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import DocumentsNewFileModule from "../content/documents/documents-new-file";

export default function DocumentsNewFile() {

  const {smid, id } = useParams();
  
  return (
  <>
    <ContentFormatXX 
      head='New Document'
      link={`/${UserForm()}/documents/view/${smid}/${id}`}
      form= 'small'
      data= {
        <>
          <DocumentsNewFileModule />
        </>
      } 
    />
  </>
  )
}