
//main
import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import SmartsNewInfoModule from "../content/smart/smarts-new-info";
import SmartReqStampModule from "../content/stamps/stamp-request";
import { useParams } from "react-router-dom";

export default function StampRequest() {

  const {id} = useParams()

  return (
  <>
    <ContentFormatXX 
      head='New Stamp'
      link={`/${UserForm()}/smart`}
      form= 'small'
      data= {
        <>
          <SmartReqStampModule />
        </>
      } 
    />
  </>
  )
}