//main
import { useParams } from "react-router-dom";

import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";

import SmartAddStampModule from "../content/smart/smart-add-stamp";

export default function SmartAddStamp() {

  const { id } = useParams();
  
  return (
  <>
    <ContentFormatXX 
      head='Stamp Merge'
      link={`/${UserForm()}/smart/view/${id}`}
      form= 'small'
      data= {
        <>
          {/* <SmartsNewFileModule /> */}
          <SmartAddStampModule />
        </>
      } 
    />
  </>
  )
}