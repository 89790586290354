import { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import WebbDividerMedium from "../webb/webb-divider-md";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbSpinText from "../webb/webb-spintext";
import ListNoData from "../webb/list-nodata";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { GetDocumentTracks, GetSmartTracks } from "../../services/srvc-track-realm";
import { SmartGetDocumentList } from "../../services/srvc-smart-realm";

export default function SmartsTracksModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const { id } = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        setLoading(true);
    

        const datx = {
          data: { smart: id },
          user: asset.user,
        }
        const documents =(await SmartGetDocumentList(JSON.stringify(datx))).data
        var idlist =[]
        documents.map(item=>{
          idlist.push({document:item.enid})
        })
        // console.log(idlist)

        var res = (await GetSmartTracks(JSON.stringify({data:{"idlist":idlist, "smart":id}}))).data
        // console.log(res)
        setData(res)

        
        setLoading(false);
      }
      fetchData()
    } else {}
  },[]);
  
  if (loading){ return ( <> <WebbDividerMedium /><WebbSpinText /> </> ) }
  if (data && data.length===0) {return (<> <WebbDividerMedium /><ListNoData /> </>)}

  return (
  <>
    <WebbDividerMedium />
    <div className="rounded-wd back-color-wite ">
    {data && data.length > 0 && data.map((item, i) => (
      <div className={`p-3 border-bottom border-light`} key={i}>
        <p className="small text-color-tone m-0">
          {(new Date(item.created)).toLocaleString()}
        </p>
        <p className='m-0'>{item.memo}</p>
        
      </div>

    ))}
    </div>
    <WebbDividerSmall/>
    <p className="small text-color-tone m-0 d-none">Date-Time is in Local</p>
    <WebbDividerMedium />
    <WebbDividerMedium />
    <WebbDividerMedium />
  </>
  )
}