// new docs
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";

import { toBase64, UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { GetUserContactsList } from "../../services/srvc-contacts-realm";
import { InvoicesCreate } from "../../services/srvc-invoices-realm";
import FileSaveFirebase from "../../services/srvc-filesave-fbsx";
import { AccountBalance, BaseAccountBalance, BusinessAccountBalance } from "../../services/srvc-accounts-realm";

export default function InvoicesNewModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  
  const [form, setForm] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);

  const [text, setText] = useState('');

  const [file, setFile]=useState()
  const [link, setLink]=useState()
  const [list, setList] = useState([]);

  const [balance,setBalance] = useState()
  const [biz,setBiz] = useState(false)

  const [data, setData] = useState ({
    cred: '',
    debt: '',
    nmbr: '24'+(new Date()).getTime().toString(),
    amnt: '',
    memo: '',
    file: '',
    ordr: '',
    stts: (new Date()).toISOString().substring(0,10),
    ents: (new Date()).toISOString().substring(0,10),
  })

  useEffect( () => {
    if (asset){
      const fetchData = async() => {

        // contact list
        const conx = await GetUserContactsList({ 
          data: { user: asset.user, index: 1, items: 10, filters: {} },
          user: asset.user,
        })
        // console.log(conx)
        setList (conx.data.list)
        setLoading(false);
      }
      fetchData()
    } else {}
  },[]);

  // form validation
  useEffect(() => {
    setForm(false);
    if (data.amnt !=='' && !isNaN(data.amnt) && parseFloat(data.amnt) !==0)
      setForm(true)
  });
  
  useEffect(()=>{
    const fetchdata= async()=>{
      const result = await BaseAccountBalance({
        data: {user: asset.user, type:"base-account"}, 
        user: asset.user,
      })
      //console.log(result)
      var balance = parseFloat(result.data.balance.number)/1000000
      // console.log(balance)
      setBalance(balance)
      
      // check if user has the business account created
      const biz = await BusinessAccountBalance({
        data: {user: asset.user, type:"base-account"}, 
        user: asset.user,
      }) 
      if(biz.data==false){setBiz(false)}
      else{setBiz(true)}
      

    }
    fetchdata()
  },[asset.user])

  const SetFileLink = async(link) => {
    // console.log (link);
    setLink(link)
    // handleChange("link", link);
  }

  const SetFileData = async(data) => {
    var str1=await toBase64(data);
    var str2=str1.split('base64')
    var b64=str2[1].split(",")
    //  console.log(b64[1])
    setFile(b64[1])
    //handleChange("file", base64);
  }

  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setSubmit(true);
    setLoading(true);

    if(balance>100)
    {
      if (form) {

        if(biz==false){
          setText('Error. You have not created business account for receiving invoice payment, Please create your contrato business account')
          setLoading(false)
          return
        }

        var debx = list.find(item => item.user === data.debt)
        console.log (debx)
        var datx = {
          cred: { name: asset.name, mail: asset.mail, item: asset.user },
          debt: { name: debx.name, mail: debx.mail, item: debx.user },
          amount: {number: (data.amnt*1000000).toString(), ticker: 'xxxx'},
          discount: {rate: '0', number: '0', ticker: 'xxxx'},
          number: data.nmbr,
          memo: data.memo,
          file: file || '',
          order: data.ordr,
          stts: data.stts+'T00:00:00.000Z',
          ents: data.ents+'T23:59:59.999Z',
          user: { name: asset.name, mail: asset.mail, item: asset.user },
        }
        //console.log(datx);

        var biz = await BusinessAccountBalance({
          data: {user: datx.debt.item, type:"base-account"}, 
          user: asset.user,
        })
        //console.log(biz) 
        if(biz.data==false){
          setText('Error. Contact you have selected, has not created business account for paying invoice payment')
        }
        else{
          const result = await InvoicesCreate ({ data: datx, user: asset.user })
          //console.log (result)
          setLoading(false);
          if (result) {
            setDone(true)
            history.push(`/${asset.form.substring(0,2)}/invoices`)
          }
        }

      }
      else {  
        setText('Please enter invoice information');
        setLoading(false);
      }
    }

    else{
      setText('Error. Insufficient Balance, Please Recharge Your Subscription Account')
      
    }
    setLoading(false)
    
    
  }

  if (loading) return (<> <WebbLoader/> </>)
  if (list.length===0) return (
    <>
      <WebbDividerMedium />
      <div className="p-3 bg-white text-center">
        <WebbDividerMedium />
        <p className="m-0">Please add Contacts via Dashboard</p>
        <WebbDividerMedium />
      </div>
    
    </>
  )


  return (
  <>
    <WebbDividerSmall />
    {/* info */}
    <div className="mb-3">
      <p>Enter Invoice Data and Submit</p>
    </div>
    <WebbDividerSmall />
    
    {/* form */}    
    <form onSubmit={handleSubmit}>
      <div className="">

        <div className="mb-3">
          <div className="form-group">
            <label className="form-label small">Select Customer <FormNeeded/></label>
            <select 
              className="form-select height-md bg-wite rounded-none"
              style ={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.debt}
              onChange={({ target }) => { handleChange("debt", target.value); }}
            >
              <option value=''>Select Contact</option>
              {list.map((item,i) => (
                <option key={i} value={item.user}>{item.name}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="mb-3">
          <label className="form-label small">PO / Order Number (If Applicable)</label>
          <input type="text" 
            className="form-control height-md bg-wite rounded-none" 
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.ordr}
            onChange={({ target }) => {handleChange("ordr", target.value); setText('');}}
            placeholder="123456">
          </input>
        </div>
      
        <div className="mb-3">
          <label className="form-label small">Invoice Number <FormNeeded/></label>
          <input type="text" 
            className="form-control height-md bg-wite rounded-none" 
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.nmbr}
            onChange={({ target }) => {handleChange("nmbr", target.value); setText('');}}
            placeholder="123456">
          </input>
        </div>

        <div className="mb-3">
          <label className="form-label small">Attachment <span className="text-danger">*</span></label>
          <FileSaveFirebase name={data.nmbr} link = {SetFileLink} file={SetFileData}/>    
        </div>

        <div className="mb-3">
          <label className="form-label small">Invoice Amount <FormNeeded/></label>
          <input type="text" 
            className="form-control height-md bg-wite rounded-none" 
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.amnt}
            onChange={({ target }) => {handleChange("amnt", target.value); setText('');}}
            placeholder="123456">
          </input>
        </div>

        <div className="mb-3">
          <label className="form-label small">Invoice Date <FormNeeded/></label>
          <input type="date" 
            className="form-control height-md bg-wite rounded-none" 
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.stts}
            onChange={({ target }) => {handleChange("stts", target.value); setText('');}}
            placeholder="123456">
          </input>
        </div>

        <div className="mb-3">
          <label className="form-label small">Due Date <FormNeeded/></label>
          <input type="date" 
            className="form-control height-md bg-wite rounded-none" 
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.ents}
            onChange={({ target }) => {handleChange("ents", target.value); setText('');}}
            placeholder="123456">
          </input>
        </div>

        <div className="mb-3">
          <label className="form-label small">Invoice Comments</label>
          <textarea 
              className="form-control rounded-none" 
              style={{fontSize:'0.9rem'}}
              rows="3"
              value={data.memo}
              onChange={({ target }) => {handleChange("memo", target.value);}}
              placeholder="Description">  
            </textarea>
        </div>
      
      </div>

      {/* text */}
      <div className="mb-3">
        <p className="small text-danger">{text}<span className="text-white">{'.'}</span></p>
      </div>
      
      {/* actn */}
      <WebbDividerMedium />
      <div className="">
        <div className="d-grid">
          <button className={`btn height-md btn-primary rounded-none`}
            disabled={!form}
          ><small>Submit</small>
          </button>
        </div>
      </div>
      

    </form> 

    <WebbDividerMedium />
    <WebbDividerMedium />
  </>
  )
}