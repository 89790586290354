import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom"; 

import WebbLoader from "../webb/webb-loader";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import WebbModuleInfo from "../webb/webb-module-info";

import NextIntro from "../webb/next-intro";
import NextDivider from "../webb/next-divider";
import NextLogout from "../webb/next-logout";
import NextAccounts from "../webb/next-accounts";
import NextBusiness from "../webb/next-business";

import { AuthGetUserRoles } from '../../services/srvc-auth-realm';
import { GetUserAccounts } from "../../services/srvc-users-realm";
import { GetAuthUser, SetLocalUser, SetLocalBusiness } from '../../services/srvc-auth-user';
import { SetNewUser, SetNewBusiness } from '../../services/srvc-auth-user';


export default function AuthNextModule () {

  const asset = GetAuthUser();
  
  const [onboard, setOnboard] = useState(false);
  const [user, setUser] = useState();
  const [loading,setLoading] = useState(true);

  const history = useHistory();

  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        setLoading(true);

        // check user in service
        // var result = await AuthCheckUser({
        //   data: {mail: asset.user},
        //   user: asset.user
        // });
        // console.log (result.data)

        var result = {data: {user: 'abc'}}
        // user roles
        var user = []
        if (result.data) {
          setOnboard (true);
          user = await GetUserAccounts({
            data: { user: asset.user },
            user: asset.user
          })
          // console.log (user)
        } else {
          setOnboard (false);
        }
        
        setUser(user.data)

        setLoading(false);
      }
      fetchData()
    } else {}
  },[]);


  const newUser = () => {
    SetNewUser({usid:''})
    if (user) {
      SetNewUser({usid:user[0].user})
      console.log(user[0].user)
    }
    history.push(`/onboard/user`)
  }

  const newBusiness = () => {
    console.log (user[0])
    SetLocalUser(user[0]);
    SetNewBusiness({usid:''})
    history.push(`/business/name`)
  }

  const onboardUser = (usxx) => {

    if (usxx.form === 'indx'){
      SetNewUser({usid:usxx.user});
      console.log(usxx.user);
    }

    if (usxx.form === 'bznx'){
      SetLocalUser(user[0]);
      SetNewBusiness({usid:usxx.user});
      console.log(usxx.user);
    }
    
    const base = usxx.form === 'indx' ? `onboard` : `business`
    
    if (!usxx.onbd.obnm)  history.push(`/${base}/name`)
    else {
      if (!usxx.onbd.obcr) history.push(`/${base}/docs`)
      else {
        if (!usxx.onbd.obdc) history.push(`/${base}/docs`)
        else {
          if (!usxx.onbd.obcm) history.push(`/${base}/contact`)
          else {
            if (!usxx.onbd.oblc) history.push(`/${base}/location`)
            else {
              if (!usxx.onbd.obtr) history.push(`/${base}/terms`)
            }
          }
        }
      }
    }

  }

  const nextuseraction = (usxx) => {
    if (usxx.active) {
      usxx.form === 'indx' 
        ? SetLocalUser (usxx)
        : SetLocalBusiness (usxx)
        
        history.push (`/${usxx.form.substring(0,2)}/accounts/subscription`);
    }
    else onboardUser (usxx)
  }

  if (loading) 
  return (<> <WebbLoader /> </>)
  
  // user does not exist or user was added contact or refer
  if (!(user && user[0].self)) {
    return (
      <>
        <WebbDividerSmall />
        <NextIntro stat={'new'} />
        <WebbDividerSmall />
        <div className="d-flex p-3 py-0 rounded-wd back-color-next text-white hidark"
          style={{cursor:'pointer'}}
          onClick={() => { newUser(); }}
        >
          <div className="py-1">
            <i className="lead bi-person m-0 pb-0 mb-0"  style={{fontSize:"1.5rem"}}></i>
          </div>
          <div className="ps-2 py-2">
            <p className="m-0 mt-1">Create Account</p>
          </div>
          <div className="ms-auto text-end py-2 mt-1">
            <i className="bi-chevron-right text-color-lite m-0" ></i>
          </div>
        </div>

        {/* create a new user here and then onboard */}
        <WebbDividerSmall />
        <NextDivider />
        <NextLogout />

      </>
    )
  }

  // user has incomplete onboarding steps
  if (!(user && user[0].onboard)) {
    return (
      <>
        <WebbDividerSmall />
        <NextIntro stat={'incomplete'} />
        <WebbDividerMedium />
        <div className="text-center">
          <button onClick={async ()=> { onboardUser(user[0]) }} 
            className="btn btn-primary back-color-next border-none rounded-none px-3">
            <small>Update Account</small>
          </button>
        </div>
        <WebbDividerSmall />
        <NextDivider />
        <NextLogout />
      </>
    )
  }

  if (!(user && user[0].active)) {
    return (
      <>
        <WebbDividerSmall />
        <NextIntro stat={'review'} />
        <WebbDividerSmall />
        <NextDivider />
        <NextLogout />
      </>
    )
  }


  if ((user && user[0].hold)) {
    return (
      <>
        <NextIntro stat={'hold'} />
        <WebbDividerSmall />
        <NextDivider />
        <NextLogout />
      </>
    )
  }
  
  
  return (
    <>
    {/* info  */}
    <WebbDividerSmall />
    <WebbModuleInfo data={{text:'Please select an account to continue'}} />
    <WebbDividerSmall />

    {/* personal */}
    <NextAccounts 
      data={user.filter(item => item.form==='indx')}
      form={'Personal'}
      user={nextuseraction}
    />
    
    <WebbDividerMedium />
    {/* business */}
    <NextAccounts
      data={user.filter(item => item.form==='bznx')} 
      form={'Business'}
      user={nextuseraction}
    />

    {/* actions */}
    <WebbDividerMedium />
    <NextDivider />
    <div className="" onClick={() => newBusiness()}>
      <NextBusiness />
    </div>
    

    <WebbDividerSmall />
    <NextLogout />
    
    
  </>
  )
  
}