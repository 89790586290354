// onboard
import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";

import { GetNewUser } from "../../services/srvc-auth-user";
import LocationSearchBar from "../../services/srvc-location-gmap";
// import { SetUserLocation } from "../../services/srvc-user-realm";

const list = require('../../data/data-states-list.json').data;

export default function OnboardUserLocationModule() {

  const usrx = GetNewUser();
  const history = useHistory();
  
  const [loading,setLoading]=useState(false);
  const [form,setForm]=useState(false);
  const [text, setText] = useState('');
  
  const [data,setData]=useState([]);
  const [place,setPlace]=useState('');

  const [location, setLocation] = useState({
    premise:'',
    route:'',
    area:'',
    neighborhood: '',
    sublocality_level_2: '',
    sublocality_level_1: '',
    locality: '',
    administrative_area_level_2: '',
    administrative_area_level_1: '',
    country: 'India',
    postal_code: ''
  });

  // useEffect for form validation
  useEffect( () => {
    setForm(false);
    if (
      location.locality!=='' && 
      location.administrative_area_level_1 !=='' &&
      location.country!=='' &&
      location.postal_code!=='' && location.postal_code.length===6
    )
      setForm(true);
  },[location]);

  const resetLocation = async () => {
    location.premise =''
    location.route =''
    location.area =''
    location.neighborhood =''
    location.sublocality_level_2 =''
    location.sublocality_level_1 =''
    location.locality =''
    location.administrative_area_level_2 =''
    location.administrative_area_level_1 =''
    location.country =''
    location.postal_code ='' 
  }

  const SetLocation = async (locs) => {
    console.log (locs);
    
    setData(locs.address_components);
    setPlace(locs.place_id);
    resetLocation();
    var loc={}
    locs.address_components.forEach(item => {
      console.log ({[item.types[0]] : item.long_name});
      loc = {...loc, [item.types[0]] : item.long_name };
    });
    console.log (loc)
    setLocation({...location, ...loc});
  }

  const handleChange = async(key, val) => {
    setLocation({ ...location, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (form) {
      setLoading(true);

      const datx = {
        data: {
          locs: {
            name: 'home', // default
            form: 'home',
            main: true, // main address
            perm: false, // permanent address
            comm: false, // communication address
            lobl: location.premise,  
            losr: location.route,
            loar: location.area,
            loct: location.locality,
            lodt: location.administrative_area_level_2,
            lost: location.administrative_area_level_1,
            locn: location.country,
            loco: location.postal_code,
            locx: '',
            locy: '',
            loid: place,
            actv: true
          },
          usid: usrx.usid,
          form: 'indx',
        },
        user: usrx.usid,
        webb: process.env.REACT_APP_WEBB_SITE_NMBR
      }
      //console.log( JSON.stringify(datx) );
      // const response = await SetUserLocation( JSON.stringify(datx) );
      
      // if (response.data) {
      //   history.push(`/onboard/terms`);
      // }
      setLoading(false);
      //resetForm();
    }  
    else {
      setText('Please enter address information')
    }
  }


  if (loading){ return ( <> <WebbLoader />  </> ) }

  return (
  <>
    {/* info */}
    <WebbDividerSmall />
    <p className="text-tone">Please Update Address</p>

    {/* srch */}
    {/* <div className="mb-3 d-none">
      <LocationSearchBar location = {SetLocation}/>
      <p className="small text-muted">
        Search Address, Update missing fields below and Save
      </p>
      <div className="border-bottom"></div>
    </div> */}

    {/* form */}
    <form onSubmit={handleSubmit}>
      <div className="">

      <div className="mb-3">
          <div className="form-group">
            <label className="form-label small">City <FormNeeded/></label>
            <input type="text" 
              className="form-control height-md "
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={location.locality}
              onChange={({ target }) => {handleChange("locality", target.value); setText('');}}
              placeholder="Enter City">
            </input>
          </div>
        </div>

        <div className="mb-3">
          <div className="form-group">
            <label className="form-label small">State <FormNeeded/></label>
            <select 
              className="form-select height-md"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={location.administrative_area_level_1}
              onChange={({ target }) => handleChange("administrative_area_level_1", target.value)}
            >
              <option value=''>Select State</option>
              {list.map((item,i) => (
                <option key={i} value={item.name}>{item.name}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="mb-3">
          <div className="form-group">
            <label className="form-label text-medium">Country <FormNeeded/></label>
            <select 
              className="form-select height-md "
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              disabled
              value={location.country}
              onChange={({ target }) => handleChange("country", target.selectedIndex)}
            >
              <option>Select Country</option>
              <option value="India">India</option>
            </select>
          </div>
        </div>

        <div className="mb-3">
          <div className="form-group">
            <label className="form-label small">PIN Code <FormNeeded/></label>
            <input type="text" 
              className="form-control height-md "
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={location.postal_code}
              onChange={({ target }) => {handleChange("postal_code", target.value); setText('');}}
              placeholder="Pin Code">
            </input>
          </div>
        </div>        

      </div>
      
      {/* text */}
      <div className="mb-3">
        <p className="small text-danger">{text}<span className="text-white">{'.'}</span></p>
      </div>

      {/* actn */}
      <WebbDividerMedium />
      <div className="">
        <div className="d-grid">
          <button 
            className={`btn height-md ${ form ? 'btn-primary back-color-main' : 'btn-primary back-color-main'}`}
            disabled={!form}
          ><small>Save & Continue</small>
          </button>
        </div>
      </div>

    </form>

    <WebbDividerMedium />
    <WebbDividerMedium />
  </>
  )
}