// to be removed

// firebase file upload
import { useState, useRef } from "react";
import firebase from "./firebase";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import FormNeeded from "../content/webb/form-needed";
import WebbProgressBar from "../content/webb/webb-progressbar";

const storage = getStorage();

export default function FileSaveFirebase (props) {

  const [stat,setStatus] = useState('Select File...');
  const [done,setDone] = useState(0);
  const [link,setLink] = useState(props.avtr || 'https://firebasestorage.googleapis.com/v0/b/finnovate99.appspot.com/o/webb%2Ffilesave.png?alt=media&token=9acb1627-380a-48a6-b9b6-e174806a79ef' );
  const [file, setFile] = useState()
  let inputFile = useRef(null);

  const handleFile = e => {
    const { files } = e.target;
    if (files && files.length) {
      // const filename = files[0].name;
      // console.log("name", filename); //ex: zip, rar, jpg, svg etc.

      setFile(files[0]);
      props.file(files[0]);
      handleUpload(files[0]);
    }
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const handleFileRemove = async (e) => {
    setFile(null)
    inputFile.current.value=null
    props.link('');
    setDone(0)
    setLink('https://firebasestorage.googleapis.com/v0/b/finnovate99.appspot.com/o/webb%2Ffilesave.png?alt=media&token=9acb1627-380a-48a6-b9b6-e174806a79ef')
    setStatus('Select File...')
  }

  const handleUpload = async (docx) => {

    setStatus('Please Wait... ')

    if (docx) {
      // console.log (docx)

      const filename = props.name + '-' + (new Date()).getTime().toString() 
      + '.' + docx.name.split('.')[(docx.name.split('.')).length-1]

      setLink('https://firebasestorage.googleapis.com/v0/b/finnovate99.appspot.com/o/webb%2Ffilesave.png?alt=media&token=9acb1627-380a-48a6-b9b6-e174806a79ef');

      const storageRef = ref(storage, 'docxuzcxmbuw24s'+'/'+filename);
      const uploadTask = uploadBytesResumable(storageRef, docx);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on('state_changed',
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          setDone (Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100));
          setStatus('' + Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100) + '% done');

          switch (snapshot.state) {
            case 'paused':
              // console.log('File Save is paused');
              break;
            case 'running':
              // console.log('File Save is running');
              break;
          }
        }, 
        (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;
            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        }, 
        () => {
            // Upload completed successfully, now we can get the download URL
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('link:', downloadURL);
            setLink(downloadURL)
            // send url to parent
            props.link(downloadURL);
          });
          document.getElementById("file").value = null
        }
      );

    }
 
  }

  return (
  <>

    <div className="d-flex p-3 rounded border back-color-wite mb-3">
      <div className="me-auto">
        <p className="fw-bold m-0 p-0">
          {file? file.name : '***'}
        </p>
        <p className="small m-0 p-0">
        {'Status: '}{stat}
        </p>
        
      </div>

      <div className={done==0 ? 'text-end': 'd-none'} style={{width:'15%'}}>
        <button 
          className="btn rounded-circle bg-lite m-0 p-0  hilite"
          style={{width:'2.4rem', height:'2.4rem'}}
          type="button"
          onClick={() => onButtonClick()}  
        ><i className="bi-plus"></i>
        </button>
      </div>

      <div className={done>0 && done <100 ? 'text-end': 'd-none'} style={{width:'15%'}}>
        <button 
          className="btn rounded-circle bg-lite m-0 p-0  hilite"
          style={{width:'2.4rem', height:'2.4rem'}}
          type="button"
          disabled 
        ><i className="bi-arrow-up"></i>
        </button>
      </div>

      <div className={done==100 ? 'text-end': 'd-none'} style={{width:'15%'}}>
        <button 
          className="btn rounded-circle bg-lite m-0 p-0  hilite"
          style={{width:'2.4rem', height:'2.4rem'}}
          onClick={() => handleFileRemove()}  
          type="button"
        ><i className="bi-x"></i>
        </button>
      </div>
    </div>

    <div className="mb-3">
      <WebbProgressBar stat={done}/>
    </div>

    <div className="mb-3">
      <div className="form-group">
        <div className="media-landscape border rounded mb-3">
          <img
            className="ref"
            src={link}
            alt="file"
          />
        </div>
        
      </div>
    </div>

    <div className="mb-3">
      <input 
        type="file" id="file"
        ref={inputFile}
        onChange= {handleFile} 
        style={{display: "none"}}
      ></input>
    </div>

    <div className="mb-3 d-none">
      <div className="form-group">
        <label className="form-label small">Link <FormNeeded /></label>
        <input type="text" className="form-control height-md bg-lite"
          value={link}
          onChange={({ target }) => {
            setStatus('External Link... ');
            setLink(target.value); 
            props.link(target.value)
          }}
          placeholder="Image Link">
        </input>
      </div>
    </div>

  </>
  )
}