import React, { useEffect, useState } from 'react'
import { UserForm } from '../../services/srvc-utilities';
import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-user';
import { useHistory } from 'react-router-dom';
import { TemplateBuy, TemplateLists } from '../../services/srvc-template-realm';
import WebbLoader from '../webb/webb-loader';
import ListNoData from '../webb/list-nodata'
import templatelogo from '../../media/download.png'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { BaseAccountBalance } from '../../services/srvc-accounts-realm';


const TemplateListsModule = (props) => {

const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
const history = useHistory();

const [data, setData] = useState([])
const [loading, setLoading] = useState(true)
const [balance, setBalance] = useState()
const [text, setText] = useState('')
const [toast, setToast] = useState(false)


useEffect(()=>{

    setLoading(true)
    const fetchdata = async()=>{
        var res = (await TemplateLists({data:{}, user:asset.user})).data
        //console.log(res)
        if(res.data!=false){setData(res.list)}
        else{setData([])}
        setLoading(false)
    }
    fetchdata()

},[])

useEffect(()=>{
    const fetchdata= async()=>{
      const result = await BaseAccountBalance({
        data: {user: asset.user, type:"base-account"}, 
        user: asset.user,
      })
      //console.log(result)
      var balance = parseFloat(result.data.balance.number)/1000000
    //   console.log(balance)
      setBalance(balance)
    }
    fetchdata()
  },[asset.user])


const SubscribeTemplates = async(item)=>{

    setLoading(true)
    if(balance >= parseFloat(item.rate)/1000000)
    {

      var datx = {
        name: item.name,
        memo: item.memo,
        nmbr: item.nmbr,
        user: asset.user,
        username: asset.name,
        mail: asset.mail,
        vars: item.vars,
        template_id: item.item,
        rate: item.rate
    }
    // console.log(datx)
      var res = (await TemplateBuy({data:datx, user:asset.user})).data
      //console.log(res)
      if(res!= false)
      {
          history.push(`/in/templates`)
      }
      else{
        setText('Error Occured. Probably you have already bought this subscription')
        setToast(true)
        setLoading(false)
      }

    }
    else{

      setText('Error: Insufficient Balance To Buy Subscription. Please Recharge your account')
      setToast(true)
      setLoading(false)
    }
}

useEffect(()=>{
  if(toast==true)
  {
      setTimeout(()=>{
          setToast(false)
      },3000)
  }

},[toast])


if (loading){ return ( <> <WebbLoader /> </> ) }
if (!loading && data.length==0){ return ( <> <ListNoData /> </> ) }

  return (
    <div>
      <div className='row p-2'>  
       {
        data && data.length>0 && data.map((item)=>{
            return(
                <>
                <div class="card mx-1" style={{width: "15rem"}}>
                    <img class="card-img-top" src={templatelogo} alt="Card image cap"  style={{width:'105px', height:'105px'}}/>
                    <div class="card-body">
                        <h5 style={{fontSize:'14px', whiteSpace:'nowrap', overflow:'hidden',  textOverflow:'ellipsis'}} class="card-title fw-bold" >{item.name}</h5>
                        <p style={{fontSize:'12px', textOverflow:'ellipsis', overflow:'hidden', display:'-webkit-box', WebkitLineClamp:2, WebkitBoxOrient:'vertical', msTextOverflow:'ellipsis' }} class="card-text">{item.memo}.</p>
                        <p className="m-0 text-small text-color-tone ">Rate: ₹ {parseInt(item.rate)/1000000}/month</p> 
                        <p className="m-0 text-small text-color-tone ">Creator: {item.user.name}</p> 
                        <p className="m-0 text-small text-color-tone ">Total Purchased: {item.subs.length}</p>  
                        <button onClick={()=>SubscribeTemplates(item)}  class="btn btn-outline-primary w-100 rounded-none mt-2">Buy</button>
                    </div>
                </div>
                </>
            )
        })
       }
       </div> 
       <div className={toast?'text-center':'d-none'}>
          <div className="toast-body border p-3 bg-warning small text-small" style={{marginTop:'-54.5vh',  position:"absolute"}}>
                {text}
          </div>         
       </div>
    </div>
  )
}

export default TemplateListsModule