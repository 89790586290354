//main
import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import InvoicesViewModule from "../content/invoices/invoices-view";

export default function InvoicesView() {

  return (
  <>
    <ContentFormatXX 
      head='Invoice Details'
      link={`/${UserForm()}/${UserForm() === 'in' ? 'invoices' : 'invoices'}`}
      form= 'medium'
      data= {
        <>
          <InvoicesViewModule />
        </>
      } 
    />
  </>
  )
}