//main
import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import AccountsLinkBankModule from "../content/accounts/accounts-link-bank";

export default function AccountsLinkBank() {

  return (
  <>
    <ContentFormatXX 
      head='Link Bank Account'
      link={`/${UserForm()}/home`}
      form= 'small'
      data= {
        <>
          <AccountsLinkBankModule />
        </>
      } 
    />
  </>
  )
}