// section heading

export default function HeaderSectionLite(props) {

  const data = props.data;

  return (
  <>
    <p className="text-color-tone text-bold">{data.name}</p>
    <div className="mb-3"></div>
  </>
  )
}