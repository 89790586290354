// action
import ActionCardSmall from "../webb/ctas-actioncard-sm";

import { UserForm } from "../../services/srvc-utilities";

export default function NewOrderModule() {

  const data = {
    name: 'New Purhcase Order',
    link: `/${UserForm()}/orders/new`,
    actv: false
  }

  return (
  <>
    <ActionCardSmall data={data}/>
  </>
  )
}